import React, { useEffect } from "react";
import _ from "lodash";
import { useFetchPerfis } from "../../api/base";
import { AvField } from "availity-reactstrap-validation-safe";

function PerfilSelect({ helpMessage = true, onChange, disabled, required = true, ...props }) {
  const { perfis, get, carregando } = useFetchPerfis();

  useEffect(() => {
    get();
  }, [get]);

  return (
    <AvField
      {...props}
      data-testid="perfil"
      type="select"
      name="perfil"
      label="Permissão"
      helpMessage={helpMessage ? "Selecione a permissão do usuário" : ""}
      required={required}
      onChange={(e) => {
        onChange(parseInt(e.target.value));
      }}
      disabled={carregando || disabled}
    >
      <option>Selecione</option>
      {_.isEmpty(perfis)
        ? null
        : perfis?.map((perfil, index) => {
            return (
              <option key={index} value={perfil.id}>
                {perfil.descricao}
              </option>
            );
          })}
    </AvField>
  );
}

export default PerfilSelect;
