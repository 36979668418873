import React, { useState } from "react";
import "./Pessoa.css";

import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { Link, useParams } from "react-router-dom";
import { SituacaoPessoa, TipoPessoa } from "../../app/BadgePessoa";

import Carregando from "../../app/Carregando";
import DadosPessoa from "./DadosPessoa";
import ListaAtendimentos from "../../app/ListaAtendimentos/ListaAtendimentos";
import classnames from "classnames";
import { useFetchPessoasFisicas } from "../../api/pessoa";

export default function Pessoa() {
  const { id } = useParams();
  const [pessoa, carregandoPessoa] = useFetchPessoasFisicas(id);
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Carregando carregando={carregandoPessoa}>
      <Card className="pessoa">
        <CardHeader>
          <Breadcrumb className="backbread">
            <BreadcrumbItem>
              <Link to="/">Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to="/pessoas">Pessoas</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active></BreadcrumbItem>
          </Breadcrumb>
          <div>
            <h1>{pessoa?.nome}</h1>
            <p>
              <TipoPessoa tipoPessoa={pessoa?.tipoPessoa} />
              <SituacaoPessoa pessoa={pessoa} />
            </p>
          </div>
        </CardHeader>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => {
                toggle("1");
              }}
            >
              Atendimentos
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => {
                toggle("2");
              }}
            >
              Dados da pessoa
            </NavLink>
          </NavItem>
        </Nav>
        <CardBody>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <ListaAtendimentos pessoaId={id} />
            </TabPane>
            <TabPane tabId="2">
              <DadosPessoa pessoa={pessoa} />
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </Carregando>
  );
}
