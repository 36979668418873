import { notificacaoErro } from "../app/notificacao";

async function atualizaNomeUsuarioPut(nome, token) {
  const url = new URL(process.env.REACT_APP_API_URL + "/usuario-ativo");
  const init = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify({ nome }),
  };

  const fet = await fetch(url, init);

  switch (fet.status) {
    case 200:
      return { success: true, status: fet.status, data: await fet.json() };
    case 404:
      return {
        success: false,
        status: fet.status,
        error: notificacaoErro(fet, init, "Ocorreu um erro ao atualizar o nome usuário."),
      };
    default:
      return {
        success: false,
        status: fet.status,
        error: notificacaoErro(fet, init, "Ocorreu um erro inesperado ao atualizar o nome usuário."),
      };
  }
}

const atualizaNomeUsuario = { atualizaNomeUsuarioPut };

export default atualizaNomeUsuario;
