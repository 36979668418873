import { ListGroup, ListGroupItem, ListGroupItemHeading } from 'reactstrap';

import DataFormatada from '../../app/DataFormatada';
import DeParaCampos from "../../app/DeParaCampos";
import { MdLightbulb } from 'react-icons/md';
import React from 'react';
import _ from 'lodash';
import styled from "styled-components";

const BadgeComentario = styled.div`
    background: #263a8a;
    border-radius: 100%;
    color: #FDFDFD;
    font-size: 1.2rem;
    width: 40px;
    height: 40px;
    text-align: center;
`;

export default function Historico({ historicos }) {
    return _.isEmpty(historicos)
        ? <div className="wrap">
            <div className="links">
                <small>nenhum histórico</small>
            </div>
        </div>
        : <div>
            <h4 className='text-center'>{historicos.length} atualizaç{historicos.length > 1 ? "ões" : "ão"}</h4>  
            <ListGroup className="historicoAtendimento">
                {historicos?.map((historico, i) => (
                    <ListGroupItem key={i}>
                        <ListGroupItemHeading tag="div">
                            <div className="clearfix">
                                <div className="float-left mr-2">
                                    <BadgeComentario><MdLightbulb className='mt-2' size={18} /></BadgeComentario>
                                </div>
                                <div className="float-left" style={{ lineHeight: "1" }}>
                                    <span style={{ fontSize: "0.9em" }}>
                                        Atualizado por <strong>
                                            {historico.usuarioAlteracao?.nome || historico.usuarioAlteracao?.email}
                                        </strong>
                                    </span><br />
                                    <small>
                                        <DataFormatada relativo data={historico.dataHoraInclusao} />
                                    </small>
                                </div>
                            </div>
                        </ListGroupItemHeading>
                        <div className="pl-5">
                            {historico.comentario
                                ? <i style={{ fontSize: "0.9em", whiteSpace: "break-spaces" }}>
                                    ❝{historico.comentario}❞
                                </i>
                                : null}
                        </div>
                        <DeParaCampos camposAlterados={historico.camposAlterados} />
                    </ListGroupItem>
                ))}
            </ListGroup>
        </div>
}