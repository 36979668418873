import React from "react";
import { useSelector } from "react-redux";
import { Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { toast } from "react-toastify";
import BotaoAcoes from "../../../app/BotaoAcoes";
import Carregando from "../../../app/Carregando";

import "./Motivos.css";
import CategoriaSelect from "../../../app/CategoriaSelect";
import useWindowDimensions from "../../../Utils/getWindowDimension";
import { AvForm } from "availity-reactstrap-validation-safe";
import DepartamentoSelect from "../../../app/DepartamentoSelect";
import criaMotivo from "../../../api/criaMotivo";

export default function NovoMotivo({ open, setOpen, reload }) {
  const [criaMotivoCarregando, setCriaMotivoCarregando] = React.useState(false);
  const [podeReabrir, setPodeReabrir] = React.useState(true);
  const [formulario, setFormulario] = React.useState({
    descricao: "",
  });

  const { width } = useWindowDimensions();
  const mobile = width > 768 ? false : true;

  const token = useSelector((state) => state.auth.token);

  const handleCloseModal = () => {
    setFormulario({ descricao: "" });
    setOpen(false);
  };

  const changeCategoria = (e) => {
    setFormulario({
      ...formulario,
      categoria: parseInt(e.target.value),
    });
  };
  const changeDepartamento = (e) => {
    setFormulario({
      ...formulario,
      departamento: parseInt(e.target.value),
    });
  };

  const handleValidSubmit = () => {
    if (!formulario.descricao) return toast.warning("Por favor preencha o nome do motivo.");
    setCriaMotivoCarregando(true);
    let body = formulario;
    body.podeReabrir = podeReabrir;
    body.tipo_pessoa = "todos";
    body.possivelSolucao = ".";
    criaMotivo
      .fetchCriaMotivo(body, token)
      .then((res) => {
        if (!res.success) return res.error;
        toast.success("Motivo criado com sucesso");
        setCriaMotivoCarregando(false);
        setOpen(false);
        reload();
      })
      .finally(() => setCriaMotivoCarregando(false));
  };

  const handleInvalidSubmit = (event, values) => {
    toast.warning("Por favor preencha todos os campos");
  };

  return (
    <>
      <Modal isOpen={open} toggle={() => setOpen(!open)} size="lg">
        <ModalHeader toggle={() => setOpen(!open)}>
          <span className="modalHeader">Novo motivo</span>
        </ModalHeader>
        <ModalBody>
          <AvForm onValidSubmit={handleValidSubmit} onInvalidSubmit={handleInvalidSubmit}>
            <Carregando carregando={criaMotivoCarregando}>
              <Row>
                <Col md={12} xs={12}>
                  <FormGroup>
                    <Label for="nome">Nome do motivo</Label>
                    <Input
                      id="nome"
                      data-testid="nome"
                      required
                      name="nome"
                      type="text"
                      value={formulario.descricao}
                      disabled={criaMotivoCarregando}
                      onChange={(e) => setFormulario({ ...formulario, descricao: e.target.value })}
                    />
                  </FormGroup>
                </Col>
                <Col md={4} xs={4}>
                  <FormGroup>
                    <CategoriaSelect onChange={changeCategoria} helpMessage={false} />
                  </FormGroup>
                </Col>
                <Col md={4} xs={4}>
                  <FormGroup>
                    <DepartamentoSelect onChange={changeDepartamento} helpMessage={false} />
                  </FormGroup>
                </Col>
                <Col md={4} xs={4} className="d-flex" style={{ justifyContent: "flex-end", alignItems: "center" }}>
                  <FormGroup switch>
                    <Input
                      type="switch"
                      checked={podeReabrir}
                      onChange={() => {
                        setPodeReabrir(!podeReabrir);
                      }}
                    />
                    <Label check>Pode reabrir ?</Label>
                  </FormGroup>
                </Col>
              </Row>
              <Col md={12} xs={12} className={mobile ? "text-center" : "text-right"} style={{ marginTop: "10px" }}>
                <BotaoAcoes
                  data-testid="button-criar"
                  type="submit"
                  disabled={criaMotivoCarregando}
                  styleglobal={styleGlobal}
                >
                  Criar
                </BotaoAcoes>
              </Col>
            </Carregando>
          </AvForm>
        </ModalBody>
        <ModalFooter>
          <BotaoAcoes
            disabled={criaMotivoCarregando}
            styleglobal={styleGlobal}
            reversecolor={true}
            onClick={handleCloseModal}
          >
            Voltar
          </BotaoAcoes>
        </ModalFooter>
      </Modal>
    </>
  );
}

const styleGlobal = { position: "relative", width: "120px" };
