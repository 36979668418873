import { Card, CardBody, Col, Row } from "reactstrap";
import { useHistory, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { useFetchAtendimentoPorProtocolo, useUpdate } from "../../api/atendimento";

import { CampoTipoPessoa } from "../../app/CampoPessoa";
import Carregando from "../../app/Carregando";
import FormBuscaPessoa from "../../app/FormBuscaPessoa";
import IrParaPessoaEmpresa from "../../app/IrParaPessoaEmpresa";
import BotaoAcoes from "../../app/BotaoAcoes";
import HeaderCardBody from "../../app/HeaderCardBody";
import useWindowDimensions from "../../Utils/getWindowDimension";

export default function AlterarPessoaAtendimento() {
  const { protocolo } = useParams();
  const history = useHistory();
  const { atendimento, get, carregando } = useFetchAtendimentoPorProtocolo();
  const { save, estado } = useUpdate();

  const { width } = useWindowDimensions();
  const mobile = width > 768 ? false : true;

  useEffect(() => {
    get(protocolo);
  }, [get, protocolo]);

  const onSelect = (pessoaId) => {
    let atendimentoAlterado = JSON.parse(JSON.stringify(atendimento));
    atendimentoAlterado.meioAtendimento = atendimentoAlterado.meioAtendimento.id;
    atendimentoAlterado.motivo = atendimentoAlterado.motivo.id;
    atendimentoAlterado.status = atendimentoAlterado.status.id;
    atendimentoAlterado.pessoa = pessoaId;
    atendimentoAlterado.dataHoraInclusao = atendimentoAlterado.dataHoraInclusao.date;
    atendimentoAlterado.responsavel = atendimentoAlterado.responsavel.id;

    save(atendimentoAlterado);
  };

  if (estado.salvo) {
    history.push(`/atendimentos/${atendimento.protocolo}`);
  }

  return (
    <Carregando carregando={carregando || estado.carregando}>
      <Card>
        <HeaderCardBody
          titulo={atendimento?.pessoa ? "Alterar pessoa" : "Definir pessoa"}
          styleCardHeader={{ marginBottom: "20px" }}
          mostraBotao={false}
        />

        <CardBody>
          <Row>
          <Col md={6} xs={12} className={mobile ? "text-center" : ""}>
              <BotaoAcoes
                type="button"
                onClick={() => history.push(`/atendimentos/${atendimento.protocolo}`)}
                styledesktop={styleDesktop}
                stylemobile={styleMobile}
                styleglobal={styleGlobal}
                reversecolor={true}
              >
                Voltar
              </BotaoAcoes>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="clearfix">
                <div className="float-left mt-3">
                  {atendimento?.pessoa ? (
                    <div title="Nome da pessoa">
                      <IrParaPessoaEmpresa className="nomePessoa" pessoa={atendimento?.pessoa} />
                      <small className="text-uppercase d-block">
                        <CampoTipoPessoa texto={atendimento?.pessoa?.tipoPessoa} />
                        {" : : "}
                        {atendimento?.pessoa?.situacao_descricao || "N/D"}
                      </small>
                    </div>
                  ) : (
                    <div>
                      <span className="nomePessoa">- Pessoa anônima -</span>
                    </div>
                  )}
                </div>
              </div>
              <hr />
            </Col>
          </Row>
          <FormBuscaPessoa onSelect={onSelect} />
        </CardBody>
      </Card>
    </Carregando>
  );
}

const styleDesktop = {
  width: "120px",
  marginTop: "-40px",
  marginRight: "10px",
  marginBottom: "20px",
  left: 10,
};
const styleGlobal = {
  boxShadow: "0.5px 1px 2px #888888",
};
const styleMobile = {
  marginBottom: "40px",
};
