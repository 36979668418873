import { AvField } from "availity-reactstrap-validation-safe";
import React, { useEffect } from "react";
import _ from "lodash";
import { useFetchMeioAtendimento } from "../../api/base";

function MeioAtendimentoSelect({ onChange, disabled, ...props }) {
  const { meios, get, carregando } = useFetchMeioAtendimento();

  useEffect(() => {
    get();
  }, [get]);

  return (
    <AvField
      {...props}
      data-testid="meioAtendimento"
      type="select"
      name="meioAtendimento"
      label="Meio de atendimento"
      required
      onChange={onChange}
      disabled={carregando || disabled}
    >
      <option>Selecione</option>
      {_.isEmpty(meios)
        ? null
        : meios?.map((meioAtendimento, index) => {
            return (
              <option key={index} value={meioAtendimento.id}>
                {meioAtendimento.descricao}
              </option>
            );
          })}
    </AvField>
  );
}

export default MeioAtendimentoSelect;
