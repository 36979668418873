import "./IrParaPessoaEmpresa.css";

import { Link } from "react-router-dom";
import classnames from "classnames";
import PropTypes from "prop-types";

export default function IrParaPessoaEmpresa({ pessoa, empresa, className }) {
  if (!pessoa && !empresa) return <>- não informado -</>;

  if (!pessoa?.id && !empresa?.id)
    return <span className={classnames("irParaPessoa", className)}>{pessoa?.nome || empresa?.razaoSocial}</span>;

  return (
    <Link
      className={classnames("irParaPessoa", className)}
      to={pessoa ? `/pessoas/${pessoa?.id}` : `/empresas/${empresa?.id}`}
    >
      {pessoa?.nome || empresa?.razaoSocial}
    </Link>
  );
}

IrParaPessoaEmpresa.propTypes = {
  pessoa: PropTypes.object,
  empresa: PropTypes.object,
  className: PropTypes.string,
};
