import dotenv from "dotenv";
import { toast } from "react-toastify";

dotenv.config();

export function notificacaoErro(fet, init, mensagem) {
  if (!fet) return toast.error(mensagem);

  const url = new URL(process.env.REACT_APP_API_URL_LOGGER + "/logger");

  fet.json().then((data) => {
    const stacktrace =
      "status: " + fet.status + " URL: " + fet.url + " body : " + JSON.stringify(data) + " - " + JSON.stringify(init);

    if (data.mensagem) {
      toast.error(data.mensagem);
      return;
    }
    if (typeof data === "object") {
      let mensagem = "";
      Object.keys(data).forEach((key) => {
        mensagem += `${key}: `;
        Object.keys(data[key]).forEach((error) => {
          mensagem += `${data[key][error]},`;
        });
        mensagem += "\n";
      });
      toast.error(mensagem);
    }

    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        sistema: "front-atendimento",
        msg: mensagem,
        level: "ERROR",
        stacktrace: stacktrace,
        "user-agent": "" + navigator.userAgent,
      }),
    });
  });
}

export function notificacaoSuccess(tipo, mensagem) {
  if (tipo === "success") {
    toast.success(mensagem);
  }
}
