import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useTokenValido } from "../app/token";
import { verificaLogin } from "../Components/Auth/authSlice";
import { notificacaoErro } from "../app/notificacao";

export function useMotivos(filtro, valor) {
  const [dados, setDados] = useState();
  const [carregando, setCarregando] = useState(true);
  const dispatch = useDispatch();
  const logged = useSelector((state) => state.auth);
  const tokenValido = useTokenValido();

  useEffect(() => {
    if (!valor) return;

    const init = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + logged.token,
      },
    };
    let url = new URL(process.env.REACT_APP_API_URL + "/motivos");
    url.searchParams.append(filtro, valor);
    fetch(url, init)
      .then((fet) => {
        setCarregando(false);
        switch (fet.status) {
          case 200:
            return fet.json();
          case 401:
            dispatch(verificaLogin(false));
            return notificacaoErro(fet, init, "Tente novamente mais tarde");
          default:
            return notificacaoErro(fet, init, "Ocorreu um erro inesperado para o motivo");
        }
      })
      .then((json) => {
        setDados(json);
      });
  }, [valor, filtro, setCarregando, setDados, dispatch, logged]);
  if (!tokenValido) {
    dispatch(verificaLogin(false));
  }

  return [dados, carregando];
}
