import { Badge } from 'reactstrap';

export default function StatusAtendimento({status}) {
    if (!status) return <></>
    const statusCores = {
        1: "primary",
        2: "info",
        3: "dark",
        4: "secondary",
        5: "warning"
    }
    return <Badge style={{fontSize: "85%"}} color={statusCores[status.id]}>{status.descricao}</Badge>
}