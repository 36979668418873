import React from "react";
import "./TabelaListaEmpresa.css";

import { Button } from "reactstrap";
import { MdOutlineArrowForward } from "react-icons/md";
import formataCnpj from "../../../Utils/formataCnpj";
import useWindowDimensions from "../../../Utils/getWindowDimension";

function TabelaListaEmpresa({ onSelect, empresas, novoAtendimento }) {
  const { width } = useWindowDimensions();
  const mobile = width > 768 ? false : true;
  return (
    <table className="ui paded table" style={{ marginTop: "20px" }}>
      <thead>
        <tr>
          <th>Código</th>
          <th>Razão Social</th>
          <th>Nome Fantasia</th>
          <th>CNPJ</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {empresas?.map((empresa, index) => (
          <tr key={index}>
            <th scope="row">{empresa.id}</th>
            <td>{empresa.razaoSocial ? empresa.razaoSocial : "-"}</td>
            <td>{empresa.nomeFantasia}</td>
            <td>{empresa.cnpj ? formataCnpj(empresa.cnpj) : "-"}</td>
            <td>
              <Button
                size="sm"
                type="button"
                data-testid={`botao-acao-${index}`}
                style={mobile ? { width: "50%" } : {}}
                className={mobile || novoAtendimento ? "primary-button table-action-button" : ""}
                color={!mobile || !novoAtendimento ? "link" : null}
                onClick={() => onSelect(empresa)}
              >
                {novoAtendimento ? (
                  <>Atender</>
                ) : mobile ? (
                  <>
                    Ir para pessoa <MdOutlineArrowForward style={{ marginLeft: 5 }} />
                  </>
                ) : (
                  <MdOutlineArrowForward />
                )}
              </Button>
            </td>
          </tr>
        ))}
        {(!empresas || empresas?.length === 0) && (
          <tr>
            <td colSpan="7">Nada para exibir</td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

export default TabelaListaEmpresa;
