import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Redirect } from "react-router";
import { useHistory } from "react-router-dom";
import HeaderCardBody from "../../app/HeaderCardBody";
import { useTokenValido } from "../../app/token";
import BotaoAcoes from "../../app/BotaoAcoes";
import { MdKeyboardBackspace } from "react-icons/md";
import "./NovoAtendimento.css";

const styleDesktopButton = { position: "relative" };
const styleGlobalButton = {
  height: "60px",
  fontSize: "16px",
};
function TipoNovoAtendimento() {
  const [escolheTipoPessoaFisica, setEscolheTipoPessoaFisica] = React.useState(false);
  const history = useHistory();

  const irParaBuscaPessoa = () => {
    return history.push("/novo-atendimento/pessoas");
  };

  const irParaBuscaEmpresa = () => {
    return history.push("/novo-atendimento/empresas");
  };

  const irParaNovoAtendimentoPessoaAnonima = () => {
    return history.push("/novo-atendimento/pessoa-anonima");
  };

  if (!useTokenValido()) {
    return <Redirect to="/login" />;
  } else {
    return (
      <Card>
        <HeaderCardBody titulo="Novo atendimento" mostraBotao={false} />
        {escolheTipoPessoaFisica ? (
          <CardEscolheNovoAtendimento
            botaoVoltar={true}
            tituloCard="Pessoa Física"
            tituloBotao1="Procurar pessoa"
            onBotao1={irParaBuscaPessoa}
            tituloBotao2="Pessoa anônima"
            onBotao2={irParaNovoAtendimentoPessoaAnonima}
            reverseStyleBotao2={true}
            onBack={() => setEscolheTipoPessoaFisica(!escolheTipoPessoaFisica)}
          />
        ) : (
          <CardEscolheNovoAtendimento
            botaoVoltar={false}
            tituloCard="Qual tipo de pessoa deseja atender ?"
            tituloBotao1="Pessoa física"
            onBotao1={() => setEscolheTipoPessoaFisica(!escolheTipoPessoaFisica)}
            tituloBotao2="Empresa"
            onBotao2={irParaBuscaEmpresa}
          />
        )}
      </Card>
    );
  }
}

function CardEscolheNovoAtendimento({
  onBack,
  botaoVoltar,
  tituloCard,
  tituloBotao1,
  reverseStyleBotao1,
  onBotao1,
  tituloBotao2,
  reverseStyleBotao2,
  onBotao2,
}) {
  return (
    <CardBody>
      <div className="w-100 mb-4 d-flex">
        {botaoVoltar && <MdKeyboardBackspace className="voltar-icone" onClick={onBack} />}
        <div className="w-100 h-100 text-center">
          <span className="span-card-novo-atendimento">{tituloCard}</span>
        </div>
      </div>
      <Row className="d-flex justify-content-center">
        <Col md={2} />
        <Col md={3} className="text-center">
          <div>
            <BotaoAcoes
              onClick={onBotao1}
              reversecolor={reverseStyleBotao1}
              styledesktop={styleDesktopButton}
              styleglobal={styleGlobalButton}
            >
              {tituloBotao1}
            </BotaoAcoes>
          </div>
        </Col>
        <Col md={2} className="text-center my-auto">
          <span className="span-card-novo-atendimento">ou</span>
        </Col>
        <Col md={3} className="text-center">
          <BotaoAcoes
            onClick={onBotao2}
            reversecolor={reverseStyleBotao2}
            styledesktop={styleDesktopButton}
            styleglobal={styleGlobalButton}
          >
            {tituloBotao2}
          </BotaoAcoes>
        </Col>
        <Col md={2} />
      </Row>
    </CardBody>
  );
}

export default TipoNovoAtendimento;
