import { Card, CardBody } from "reactstrap";
import { useHistory } from "react-router-dom";

import FormBuscaPessoa from "../../app/FormBuscaPessoa";
import { Redirect } from "react-router";
import { useTokenValido } from "../../app/token";
import HeaderCardBody from "../../app/HeaderCardBody";

function ListaPessoas() {
  const history = useHistory();

  const irParaPessoa = (pessoaId) => {
    history.push(`/pessoas/${pessoaId}`);
  };

  if (!useTokenValido()) {
    return <Redirect to="/login" />;
  } else {
    return (
      <Card>
        <HeaderCardBody titulo="Pessoas" mostraBotao={false} />
        <CardBody>
          <FormBuscaPessoa onSelect={irParaPessoa} />
        </CardBody>
      </Card>
    );
  }
}

export default ListaPessoas;
