import React from "react";
import { useSelector } from "react-redux";
import { Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { toast } from "react-toastify";
import BotaoAcoes from "../../../app/BotaoAcoes";
import useWindowDimensions from "../../../Utils/getWindowDimension";
import Carregando from "../../../app/Carregando";

import { AvForm } from "availity-reactstrap-validation-safe";
import CategoriaSelect from "../../../app/CategoriaSelect";
import DepartamentoSelect from "../../../app/DepartamentoSelect";
import "./Motivos.css";
import atualizaMotivo from "../../../api/motivoPut";

export default function EditarMotivo({ open, setOpen, motivo, reload }) {
  const [podeReabrir, setPodeReabrir] = React.useState(motivo.podeReabrir);
  const [formulario, setFormulario] = React.useState({
    descricao: motivo.descricao,
    departamento: motivo.departamento.id,
    categoria: motivo.categoria.id,
  });
  const [editado, setEditado] = React.useState(false);
  const [carregando, setCarregando] = React.useState(false);

  const token = useSelector((state) => state.auth.token);

  const { width } = useWindowDimensions();
  const mobile = width > 768 ? false : true;

  const handleCloseModal = () => {
    setFormulario({ descricao: "" });
    setOpen(false);
  };

  const handleValidSubmit = () => {
    let motivoAlterado = Object.assign(motivo, formulario);
    motivoAlterado.podeReabrir = podeReabrir;
    setCarregando(true);
    atualizaMotivo
      .fetchAtualizaMotivo(motivoAlterado, token, motivo.id)
      .then((res) => {
        if (!res.success) return res.error;
        toast.success("Motivo atualizado com sucesso!");
        setCarregando(false);
        setOpen(false);
        reload();
      })
      .finally(() => {
        setCarregando(false);
      });
  };

  const changeCategoria = (e) => {
    setEditado(true);
    setFormulario({
      ...formulario,
      categoria: parseInt(e.target.value),
    });
  };
  const changeDepartamento = (e) => {
    setEditado(true);
    setFormulario({
      ...formulario,
      departamento: parseInt(e.target.value),
    });
  };

  const handleInvalidSubmit = (event, values) => {
    toast.warning("Por favor preencha todos os campos");
  };

  return (
    <>
      <Modal isOpen={open} toggle={() => setOpen(!open)} size="lg">
        <ModalHeader toggle={() => setOpen(!open)}>
          <span className="modalHeader">
            Editar Motivo
            <span className="secondaryText subTitulo" data-testid="nome-departamento">
              {formulario.descricao}
            </span>
          </span>
        </ModalHeader>
        <ModalBody>
          <AvForm onValidSubmit={handleValidSubmit} onInvalidSubmit={handleInvalidSubmit}>
            <Carregando carregando={carregando}>
              <Row>
                <Col md={12} xs={12}>
                  <FormGroup>
                    <Label for="nome">Nome do motivo</Label>
                    <Input
                      id="nome"
                      data-testid="nome"
                      required
                      name="nome"
                      type="text"
                      value={formulario.descricao}
                      disabled={carregando}
                      onChange={(e) => {
                        setEditado(true);
                        setFormulario({ ...formulario, descricao: e.target.value });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={4} xs={4}>
                  <FormGroup>
                    <CategoriaSelect
                      value={formulario.categoria || ""}
                      onChange={changeCategoria}
                      helpMessage={false}
                    />
                  </FormGroup>
                </Col>
                <Col md={4} xs={4}>
                  <FormGroup>
                    <DepartamentoSelect
                      value={formulario.departamento || ""}
                      onChange={changeDepartamento}
                      helpMessage={false}
                    />
                  </FormGroup>
                </Col>
                <Col md={4} xs={4} className="d-flex" style={{ justifyContent: "flex-end", alignItems: "center" }}>
                  <FormGroup switch>
                    <Input
                      type="switch"
                      checked={podeReabrir}
                      onChange={() => {
                        setEditado(true);
                        setPodeReabrir(!podeReabrir);
                      }}
                    />
                    <Label check>Pode reabrir ?</Label>
                  </FormGroup>
                </Col>
              </Row>
              <Col md={12} xs={12} className={mobile ? "text-center" : "text-right"} style={{ marginTop: "10px" }}>
                <BotaoAcoes
                  data-testid="button-salvar"
                  type="submit"
                  disabled={carregando || !editado}
                  styleglobal={styleGlobal}
                >
                  Salvar
                </BotaoAcoes>
              </Col>
            </Carregando>
          </AvForm>
        </ModalBody>
        <ModalFooter>
          <BotaoAcoes disabled={carregando} styleglobal={styleGlobal} reversecolor={true} onClick={handleCloseModal}>
            Concluir
          </BotaoAcoes>
        </ModalFooter>
      </Modal>
    </>
  );
}

const styleGlobal = { position: "relative", width: "120px" };
