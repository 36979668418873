import React, { useEffect } from "react";
import _ from "lodash";
import { AvField } from "availity-reactstrap-validation-safe";
import { useFetchDepartamentos } from "../../api/base";

function DepartamentoSelect({ helpMessage = true, onChange, disabled, required = true, ...props }) {
  const { departamentos, get, carregandoDepartamentos } = useFetchDepartamentos();

  useEffect(() => {
    get();
  }, [get]);

  return (
    <AvField
      {...props}
      data-testid="departamento"
      type="select"
      name="departamento"
      label="Departamento"
      helpMessage={helpMessage ? "Selecione o departamento do atendimento" : ""}
      required={required}
      onChange={onChange}
      disabled={carregandoDepartamentos || disabled}
    >
      <option>Selecione</option>
      {_.isEmpty(departamentos)
        ? null
        : departamentos?.map((departamento, index) => {
            return (
              <option key={index} value={departamento.id}>
                {departamento.nome}
              </option>
            );
          })}
    </AvField>
  );
}

export default DepartamentoSelect;
