import "react-toastify/dist/ReactToastify.css";

import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Routes from "./features/Routes";
import ReactGA from "react-ga";

export default function App() {
  const TRACKING_ID = "G-8S8KWVZJEP";
  ReactGA.initialize(TRACKING_ID);
  return (
    <Router>
      <ToastContainer />
      <Routes />
    </Router>
  );
}
