import React from "react";
import { NavItem, NavLink } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { useTokenValido } from "../../app/token";

function MenuItemPrivado(props) {
  const tokenValido = useTokenValido();
  let activeRoute = useLocation().pathname;
  activeRoute === props.para ? (activeRoute = true) : (activeRoute = false);
  return tokenValido ? (
    <NavItem onClick={() => props.setMenu(false)}>
      <NavLink to={props.para} className={activeRoute ? "active-menu" : null} tag={Link}>
        {props.children}
      </NavLink>
    </NavItem>
  ) : null;
}

MenuItemPrivado.propTypes = {
  para: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]).isRequired,
};

export default MenuItemPrivado;
