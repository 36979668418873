import './BadgePessoa.css';

import { Badge } from 'reactstrap';
import { CampoTipoPessoa } from '../CampoPessoa'
import PropTypes from 'prop-types';
import _ from "lodash";

export function TipoPessoa({ tipoPessoa }) {
  const tipos = {
    S: "Sócio",
    U: "Sócio usuário",
    B: "Base do sindicato",
    D: "Demais pessoas"
  }
  return <Badge
    color="success"
    title={"Tipo de pessoa: " + _.get(tipos, tipoPessoa, 'Não definido')}
    className="badgePessoa">
    <CampoTipoPessoa texto={tipoPessoa} />
  </Badge>
}

TipoPessoa.propTypes = {
  tipoPessoa: PropTypes.oneOf(['S', 'U', 'B', 'D'])
}

export function SituacaoPessoa({ pessoa }) {
  return <Badge
    color={pessoa.situacao_ativo ? "success" : "secondary"}
    title={"Situação: " + pessoa.situacao_descricao}
    className="badgePessoa">
    {pessoa.situacao_descricao}
  </Badge>
}

SituacaoPessoa.defaultProps = {
  pessoa: {}
}

SituacaoPessoa.propTypes = {
  pessoa: PropTypes.object
}