import React, { useState } from "react";
import "./Empresa.css";

import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { Link, useParams } from "react-router-dom";

import Carregando from "../../app/Carregando";
import ListaAtendimentos from "../../app/ListaAtendimentos/ListaAtendimentos";
import classnames from "classnames";
import { useFetchEntidade } from "../../api/entidade";

const Empresa = () => {
  const { id, tipo } = useParams();
  const [empresa, carregando] = useFetchEntidade(id, tipo);
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Carregando carregando={carregando}>
      <Card className="empresa">
        <CardHeader>
          <Breadcrumb className="backbread">
            <BreadcrumbItem>
              <Link to="/">Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to="/empresas">Empresas</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active></BreadcrumbItem>
          </Breadcrumb>
          <div>
            <h1>{empresa?.razaoSocial}</h1>
          </div>
        </CardHeader>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => {
                toggle("1");
              }}
            >
              Atendimentos
            </NavLink>
          </NavItem>
        </Nav>
        <CardBody>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <ListaAtendimentos empresaId={id} />
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </Carregando>
  );
};

export default Empresa;
