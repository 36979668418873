import { notificacaoErro } from "../app/notificacao";

async function fetchAutenticaUsuario(codSind, email, senha) {
    const url = new URL(process.env.REACT_APP_API_URL + '/autenticar');
    const init = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            sindicato: codSind,
            email: email,
            senha: senha
        })
    }

    const fet = await fetch(url, init);

    switch (fet.status) {
        case 200:
            return { success: true, status: fet.status, data: await fet.json() };
        case 422:
            return { success: false, status: fet.status, error: notificacaoErro(fet, init, 'Usuário não encontrado.') };
        case 404:
            return { success: false, status: fet.status, error: notificacaoErro(fet, init,  'Ocorreu um erro ao fazer o login.') };
        default:
            return { success: false, status: fet.status, error: notificacaoErro(fet, init, 'Ocorreu um erro inesperado ao fazer o login') };
    }

}

const autenticaUsuario = { fetchAutenticaUsuario };

export default autenticaUsuario;
