import "./BaixaArquivo.css";

import { Button } from "reactstrap";
import DataFormatada from "../../app/DataFormatada";
import { MdAttachFile } from "react-icons/md";
import React from "react";
import _ from "lodash";
import baixaArquivo from "../../api/baixaArquivo";
import fileDownload from "js-file-download";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";
  var k = 1024,
    dm = decimals || 2,
    sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

function BaixaArquivo({ atendimento }) {
  const logged = useSelector((state) => state.auth);

  const clickDownload = (anexoId, anexoNome) => {
    baixaArquivo.useFetchBaixaArquivo(logged.token, anexoId).then((res) => {
      if (!res.success) {
        return res.error;
      }
      fileDownload(res.data, anexoNome);
      toast.success("Download " + anexoNome + " realizado.");
    });
  };

  return (
    <div>
      {_.isEmpty(atendimento?.anexos) ? (
        <div className="wrap mb-3">
          <div className="links">
            <small>nenhum anexo</small>
          </div>
        </div>
      ) : (
        <div>
          {atendimento.anexos?.map((anexo, i) => (
            <div key={i}>
              <MdAttachFile />
              <Button color="link" onClick={() => clickDownload(anexo.id, anexo.nome)}>
                {anexo.nome}
              </Button>
              <span style={{ fontSize: "0.9em" }}>
                ({formatBytes(anexo.tamanho)}){" - "}
              </span>
              <small>
                <DataFormatada relativo data={anexo.dataHoraInclusao} />
              </small>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default BaixaArquivo;
