import jwt from 'jsonwebtoken'
import { useSelector } from 'react-redux'

export function useTokenValido() {
    const logged = useSelector(state => state.auth)
    var decodedToken = jwt.decode(logged.token, { complete: true });
    var dateNow = new Date();

    return logged.token && logged.isLogged && decodedToken?.payload.exp >= Math.floor(dateNow.getTime() / 1000)

}