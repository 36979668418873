import "./Routes.css";
import React, { useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { Redirect, Route, Switch } from "react-router-dom";
import Menu from "../Menu";
import { useTokenValido } from "../../app/token";
import Login from "../Login";
import ProximosAtendimentos from "../ProximosAtendimentos";
import TodosAtendimentos from "../TodosAtendimentos";
import AlterarPessoaAtendimento from "../AlterarPessoaAtendimento";
import AtendimentoDetalhe from "../AtendimentoDetalhe";
import TipoNovoAtendimento from "../NovoAtendimento/TipoNovoAtendimento";
import BuscaPessoaNovoAtendimento from "../NovoAtendimento/BuscaPessoaNovoAtendimento";
import Pessoa from "../Pessoa";
import ListaPessoas from "../ListaPessoas";
import Empresa from "../Empresa";
import ListaEmpresas from "../ListaEmpresas";
import useWindowDimensions from "../../Utils/getWindowDimension";
import { useSelector } from "react-redux";
import Header from "../../app/Header";
import Home from "../Home";
import BuscaEmpresaNovoAtendimento from "../NovoAtendimento/BuscaEmpresaNovoAtendimento";
import NovoAtendimento from "../NovoAtendimento/NovoAtendimento";
import Classificacoes from "../Classificacoes";
import AlterarSenha from "../AlterarSenha";
import Perfil from "../Perfil";
import Usuarios from "../Usuarios";

function PrivateRoute({ component: Component, ...attributes }) {
  const tokenValido = useTokenValido();

  return (
    <Route {...attributes} render={(props) => (tokenValido ? <Component {...props} /> : <Redirect to="/login" />)} />
  );
}

export default function Routes() {
  const [menuAtivo, setMenuAtivo] = useState(false);
  const { width } = useWindowDimensions();
  const mobile = width > 768 ? false : true;
  const logged = useSelector((state) => state.auth);
  const tokenValido = useTokenValido();
  const usuarioAtivo = logged.isLogged && tokenValido ? true : false;
  return (
    <Container
      fluid
      className={logged.isLogged && tokenValido ? `content-container` : ""}
      style={usuarioAtivo ? { paddingLeft: 0 } : {}}
    >
      <Row>
        {usuarioAtivo ? (
          <Col
            md="2"
            xs={menuAtivo ? "10" : "1"}
            style={menuAtivo ? { position: "absolute" } : { backgroundColor: "#fff" }}
          >
            <Menu setMenuAtivo={mobile ? setMenuAtivo : null} />
          </Col>
        ) : (
          ""
        )}
        <Col md={usuarioAtivo ? "10" : "12"} xs={usuarioAtivo ? "11" : "12"} style={menuAtivo ? { opacity: 0.3 } : {}}>
          {usuarioAtivo && <Header />}
          <Switch>
            <Route exact path="/">
              {!tokenValido ? <Redirect to="/login" /> : logged.isLogged || tokenValido ? <Home /> : <Login />}
            </Route>
            <PrivateRoute path="/proximos-atendimentos" component={ProximosAtendimentos} />
            <PrivateRoute path="/todos-atendimentos" component={TodosAtendimentos} />
            <PrivateRoute path="/atendimentos/:protocolo/alterar-pessoa" component={AlterarPessoaAtendimento} />
            <PrivateRoute path="/atendimentos/:protocolo" component={AtendimentoDetalhe} />
            <PrivateRoute path="/novo-atendimento/pessoas" component={BuscaPessoaNovoAtendimento} />
            <PrivateRoute path="/novo-atendimento/empresas" component={BuscaEmpresaNovoAtendimento} />
            <PrivateRoute path="/novo-atendimento/:tipo/:id?" component={NovoAtendimento} />
            <PrivateRoute path="/novo-atendimento" component={TipoNovoAtendimento} />
            <PrivateRoute path="/pessoas/:id" component={Pessoa} />
            <PrivateRoute path="/pessoas" component={ListaPessoas} />
            <PrivateRoute path="/empresas/:id" component={Empresa} />
            <PrivateRoute path="/empresas" component={ListaEmpresas} />
            <PrivateRoute path="/categorias" component={Classificacoes} />
            <PrivateRoute path="/motivos" component={Classificacoes} />
            <PrivateRoute path="/departamentos" component={Classificacoes} />
            <PrivateRoute path="/alterar-senha" component={AlterarSenha} />
            <PrivateRoute path="/perfil" component={Perfil} />
            <PrivateRoute path="/usuarios" component={Usuarios} />
            <Route exact path="/login">
              <Login />
            </Route>
          </Switch>
        </Col>
      </Row>
    </Container>
  );
}
