import "./BotaoForm.css";

import { Button } from "reactstrap";
import PropTypes from 'prop-types';
import React from 'react';

function BotaoForm(props) {
    return (
        <Button className={`botaoForm ${props.lg ? "btn-lg" : ""}`} color='primary' {...props}>
            {props.children}
        </Button>

    )
}

BotaoForm.propTypes = {
    texto: PropTypes.string,
    bg: PropTypes.bool,
    carregando: PropTypes.bool
}

export default BotaoForm;