import { AvField, AvGroup } from "availity-reactstrap-validation-safe";
import React from "react";
import { FormGroup } from "reactstrap";
import { useFetchAll } from "../../api/entidade";
import BotaoAcoes from "../BotaoAcoes";

export default function BuscaEmpresaPessoaAnonimaOuNaoCadastrada({
  onBuscar,
  nomeEmpresa,
  tituloBotao = "Buscar",
  mostraBotao = true,
}) {
  const [parametros, carregando] = useFetchAll("/parametros");
  let obrigaEmpresaAtendido = parametros?.obrigaEmpresaAtendido;
  if (obrigaEmpresaAtendido === undefined) {
    obrigaEmpresaAtendido = false;
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
      }}
    >
      <FormGroup style={{ width: "50%" }}>
        <AvGroup>
          <AvField
            data-testid="empresaAnonimoOuNaoCadastrado"
            type="text"
            name="empresaAnonimoOuNaoCadastrado"
            label="Empresa"
            id="empresaAnonimoOuNaoCadastrado"
            disabled
            value={nomeEmpresa || ""}
            required={obrigaEmpresaAtendido}
          />
        </AvGroup>
      </FormGroup>
      {mostraBotao && (
        <BotaoAcoes
          onClick={onBuscar}
          disabled={carregando}
          styleglobal={{ width: "120px", position: "relative", marginLeft: 20 }}
        >
          {tituloBotao}
        </BotaoAcoes>
      )}
    </div>
  );
}
