import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import BotaoAcoes from "../BotaoAcoes";
import Carregando from "../Carregando";
import { MdToll } from "react-icons/md";
import { useMotivos } from "../../api/motivo";

import "./ListaMotivos.css";

export default function ListaMotivos({ titulo, subTitulo, queryNome, queryValor, open, setOpen, onVerMotivo }) {
  const [motivos, carregando] = useMotivos(queryNome, queryValor);

  return (
    <>
      <Modal isOpen={open} toggle={() => setOpen(!open)}>
        <ModalHeader toggle={() => setOpen(!open)}>
          <span className="modalHeader">
            {titulo}
            <span className="secondaryText subTitulo" data-testid="nome-departamento">
              {subTitulo}
            </span>
          </span>
        </ModalHeader>
        <ModalBody>
          <Carregando carregando={carregando}>
            <table className="ui very basic table">
              <thead>
                <tr>
                  <th></th>
                  <th className="secondaryText">Motivos</th>
                </tr>
              </thead>
              <tbody>
                {motivos?.map((motivo, index) => (
                  <tr key={index}>
                    <td>
                      <MdToll />
                    </td>
                    <td>{motivo.descricao}</td>
                  </tr>
                ))}
                {(!motivos || motivos?.length === 0) && (
                  <tr>
                    <td colSpan="7">Não há motivos cadastrados...</td>
                  </tr>
                )}
              </tbody>
              <tfoot>
                <tr>
                  <th colSpan="5">
                    <div className="ui right floated pagination menu">
                      <BotaoAcoes onClick={()=> onVerMotivo(queryValor)} styleglobal={styleGlobal}>
                        Ver motivos
                      </BotaoAcoes>
                    </div>
                  </th>
                </tr>
              </tfoot>
            </table>
          </Carregando>
        </ModalBody>
        <ModalFooter>
          <BotaoAcoes styleglobal={styleGlobal} reversecolor={true} onClick={() => setOpen(false)}>
            Voltar
          </BotaoAcoes>
        </ModalFooter>
      </Modal>
    </>
  );
}

const styleGlobal = { position: "relative", width: "140px" };
