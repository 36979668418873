import React, { useEffect } from "react";

import { Input } from "reactstrap";
import _ from "lodash";
import { useFetchUsuarios } from "../../api/base";

function UsuarioSelect({
  onChange,
  usuarioSelecionado,
  departamento,
  customComponent,
  disabled = false,
  required = true,
  ...props
}) {
  const { usuarios, get, carregando } = useFetchUsuarios(departamento);

  const Component = customComponent || Input;

  const onChangeSelect = (e) => {
    const usuariosEncontrados = usuarios.filter((usuario) => {
      return usuario.id === parseInt(e.target.value);
    });
    onChange(usuariosEncontrados[0] || null);
  };

  useEffect(() => {
    get();
  }, [get]);

  return (
    <Component
      {...props}
      type="select"
      name="responsavel"
      required={required}
      onChange={onChangeSelect}
      value={usuarioSelecionado?.id || ""}
      disabled={carregando || disabled}
    >
      <option></option>
      {_.isEmpty(usuarios)
        ? null
        : usuarios?.map((usuario, index) => {
            return (
              <option key={index} value={usuario.id}>
                {usuario.nome || usuario.email}
              </option>
            );
          })}
    </Component>
  );
}

export default UsuarioSelect;
