import "./Carregando.css";

import LoadingOverlay from "react-loading-overlay";
import PropTypes from "prop-types";
import { Spinner } from "reactstrap";

function Carregando(props) {
  return (
    <LoadingOverlay
      active={props.carregando}
      spinner={<Spinner size="sm" />}
      classNamePrefix="Carregando_"
      text={props.texto}
    >
      {props.children}
    </LoadingOverlay>
  );
}

LoadingOverlay.propTypes = {
  spinner: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
  text: PropTypes.node,
  styles: PropTypes.shape({
    content: PropTypes.func,
    overlay: PropTypes.func,
    spinner: PropTypes.func,
    wrapper: PropTypes.func,
  }),
};

Carregando.defaultProps = {
  carregando: false,
  texto: "carregando...",
};

Carregando.propTypes = {
  carregando: PropTypes.bool,
  texto: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.arrayOf(PropTypes.element)]),
};

export default Carregando;
