import React from "react";
import { useSelector } from "react-redux";
import { Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { toast } from "react-toastify";
import BotaoAcoes from "../../../app/BotaoAcoes";
import Carregando from "../../../app/Carregando";

import atualizaCategoria from "../../../api/categoriaPut";

import "./Categorias.css";

export default function EditarCategoria({ open, setOpen, categoria, reload }) {
  const [nome, setNome] = React.useState(categoria.descricao);
  const [editado, setEditado] = React.useState(false);
  const [atualizaCategoriaCarregando, setAtualizaCategoriaCarregando] = React.useState(false);

  const token = useSelector((state) => state.auth.token);

  const handleCloseModal = () => {
    setNome("");
    setOpen(false);
  };

  const handleEditaCategoria = () => {
    if (!nome) return toast.warning("Por favor preencha o nome da categoria.");
    setAtualizaCategoriaCarregando(true);
    atualizaCategoria
      .fetchAtualizaCategoria(nome, token, categoria.id)
      .then((res) => {
        if (!res.success) return res.error;
        toast.success("Categoria atualizada com sucesso");
        setAtualizaCategoriaCarregando(false);
        setOpen(false);
        reload();
      })
      .finally(() => setAtualizaCategoriaCarregando(false));
  };

  return (
    <>
      <Modal isOpen={open} toggle={() => setOpen(!open)} size="lg">
        <ModalHeader toggle={() => setOpen(!open)}>
          <span className="modalHeader">
            Categorias
            <span className="secondaryText subTitulo" data-testid="nome-departamento">
              {nome}
            </span>
          </span>
        </ModalHeader>
        <ModalBody>
          <Form>
            <Carregando carregando={atualizaCategoriaCarregando}>
              <Row>
                <Col md={12} xs={12}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FormGroup style={{ width: "70%" }}>
                      <Label for="nome">Nome da categoria</Label>
                      <Input
                        id="nome"
                        data-testid="nome"
                        required
                        name="nome"
                        type="text"
                        value={nome}
                        disabled={atualizaCategoriaCarregando}
                        onChange={(e) => {
                          setEditado(true);
                          setNome(e.target.value);
                        }}
                      />
                    </FormGroup>
                    <BotaoAcoes
                      data-testid="button-salvar"
                      disabled={atualizaCategoriaCarregando || !editado}
                      onClick={handleEditaCategoria}
                      styleglobal={{ ...styleGlobalAdicionar, width: "20%" }}
                    >
                      Salvar
                    </BotaoAcoes>
                  </div>
                </Col>
              </Row>
            </Carregando>
          </Form>
        </ModalBody>
        <ModalFooter>
          <BotaoAcoes
            disabled={atualizaCategoriaCarregando}
            styleglobal={styleGlobal}
            reversecolor={true}
            onClick={handleCloseModal}
          >
            Voltar
          </BotaoAcoes>
        </ModalFooter>
      </Modal>
    </>
  );
}

const styleGlobal = { position: "relative", width: "120px" };

const styleGlobalAdicionar = {
  position: "relative",
  width: "100px",
  height: "38px",
  marginLeft: 20,
  marginTop: 10,
};
