import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { MdCircle } from "react-icons/md";
import { Card, CardBody, Col, FormGroup, Input, Label, Row, Container } from "reactstrap";
import { useFetchUsuarioAtivo } from "../../api/usuarioAtivo";
import BotaoAcoes from "../../app/BotaoAcoes";
import Carregando from "../../app/Carregando";
import HeaderCardBody from "../../app/HeaderCardBody";
import useWindowDimensions from "../../Utils/getWindowDimension";
import "./Perfil.css";
import atualizaNomeUsuario from "../../api/usuarioNomePut";

export default function Perfil() {
  const [values, setValues] = useState({
    nome: "",
    email: "",
  });
  const [editado, setEditado] = useState(false);
  const [carregando, setCarregando] = useState(false);

  const history = useHistory();
  const token = useSelector((state) => state.auth.token);

  const [usuarioAtivo, carregandoUsuarioAtivo] = useFetchUsuarioAtivo();

  const { width } = useWindowDimensions();
  const mobile = width > 768 ? false : true;

  useEffect(() => {
    if (usuarioAtivo) {
      setValues({
        nome: usuarioAtivo.nome + "",
        email: usuarioAtivo.email + "",
      });
    }
  }, [usuarioAtivo]);

  const handleAlteraNome = () => {
    setCarregando(true);
    atualizaNomeUsuario
      .atualizaNomeUsuarioPut(values.nome, token)
      .then((res) => {
        if (!res.success) return res.error;
        toast.success("Nome alterado com sucesso");
        setCarregando(false);
      })
      .finally(() => setCarregando(false));
  };

  return (
    <Card>
      <HeaderCardBody titulo="Meu perfil" mostraBotao={false} styleCardHeader={{ marginBottom: "20px" }} />
      <CardBody className="pb-5">
        <Carregando carregando={carregando || carregandoUsuarioAtivo}>
          <Row>
            <Col md={6} xs={12} className={mobile ? "text-center" : ""}>
              <BotaoAcoes
                data-testid="button-voltar"
                onClick={() => history.goBack()}
                styledesktop={{ ...styleDesktop, left: 10 }}
                stylemobile={styleMobile}
                styleglobal={styleGlobal}
                reversecolor={true}
              >
                Voltar
              </BotaoAcoes>
            </Col>
          </Row>
          <Container fluid className="mt-3">
            <Row>
              <Col md={6} sm={12}>
                <FormGroup>
                  <Label for="nome">Nome</Label>
                  <Input
                    type="text"
                    value={values.nome}
                    name="nome"
                    data-testid="nome"
                    onChange={(e) => {
                      setEditado(true);
                      setValues({ ...values, nome: e.target.value });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={6} sm={12}>
                <FormGroup>
                  <Label for="senhaAtual">Email</Label>
                  <Input type="text" disabled={true} value={values.email} />
                </FormGroup>
              </Col>
              <Col md={12} sm={12} className={mobile ? "text-center" : "text-right"}>
                <BotaoAcoes
                  disabled={carregando || !editado}
                  onClick={handleAlteraNome}
                  styledesktop={{ position: "relative", width: "120px" }}
                  data-testid="button-salvar"
                >
                  Salvar
                </BotaoAcoes>
              </Col>
            </Row>
            <hr className="divisor" />
            <Row>
              <h3>Departamentos</h3>
              {Array.isArray(usuarioAtivo.departamentos) &&
                (usuarioAtivo.departamentos.length > 0
                  ? usuarioAtivo.departamentos.map((departamento, index) => {
                      return (
                        <Col md={6} sm={12} className="mt-2 mb-2" key={index}>
                          <MdCircle size={8} color="#abb5ba" />
                          <span className="nome-departamento">{departamento.nome}</span>
                        </Col>
                      );
                    })
                  : "Nenhum departamento encontrado")}
            </Row>
            <hr className="divisor" />
            <Row className="mt-2">
              <h3>Total atendimentos</h3>
              <Col md={3} sm={6} className="text-center mt-2">
                <div className="w-100 p-2 card-estatisticas d-flex" style={{ height: "100px", alignItems: "center" }}>
                  <div className="w-100 h-75 p-2">
                    <div className="w-100 h-100 d-flex">
                      <div className="h-100 separator"></div>
                      <div className="w-100 h-100 d-flex numero-card text-left">
                        <h3 style={{ fontSize: "40px" }}>{usuarioAtivo.totalAtendimentos}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </Carregando>
      </CardBody>
    </Card>
  );
}

const styleDesktop = {
  width: "150px",
  marginTop: "-40px",
  marginRight: "10px",
};
const styleMobile = {
  marginBottom: "40px",
};
const styleGlobal = {
  boxShadow: "0.5px 1px 2px #888888",
};
