import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Card, CardBody, Col, FormGroup, Input, Label, Row } from "reactstrap";
import alteraSenha from "../../api/alteraSenha";
import BotaoAcoes from "../../app/BotaoAcoes";
import Carregando from "../../app/Carregando";
import HeaderCardBody from "../../app/HeaderCardBody";
import useWindowDimensions from "../../Utils/getWindowDimension";

export default function AlterarSenha() {
  const [senhaAtual, setSenhaAtual] = useState("");
  const [senha, setSenha] = useState("");
  const [confirmaSenha, setConfirmaSenha] = useState("");
  const [carregando, setCarregando] = useState(false);

  const history = useHistory();
  const token = useSelector((state) => state.auth.token);

  const { width } = useWindowDimensions();
  const mobile = width > 768 ? false : true;

  const handleAlteraSenha = () => {
    if (senha !== confirmaSenha) return toast.warning("As senhas devem ser iguais");
    setCarregando(true);
    alteraSenha
      .alteraSenhaPost(senhaAtual, confirmaSenha, token)
      .then((res) => {
        if (!res.success) return res.error;
        setSenhaAtual("");
        setSenha("");
        setConfirmaSenha("");
        toast.success("Senha alterada com sucesso");
        setCarregando(false);
      })
      .finally(() => setCarregando(false));
  };

  return (
    <Card>
      <HeaderCardBody titulo="Alterar senha" mostraBotao={false} styleCardHeader={{ marginBottom: "20px" }} />
      <CardBody className="pb-5">
        <Carregando carregando={carregando}>
          <Row>
            <Col md={6} xs={12} className={mobile ? "text-center" : ""}>
              <BotaoAcoes
                data-testid="button-voltar"
                onClick={() => history.goBack()}
                styledesktop={{ ...styleDesktop, left: 10 }}
                stylemobile={styleMobile}
                styleglobal={styleGlobal}
                reversecolor={true}
              >
                Voltar
              </BotaoAcoes>
            </Col>
            <Col md={6} xs={12} className={mobile ? "text-center" : ""}>
              <BotaoAcoes
                data-testid="button-salvar"
                disabled={!senha || !confirmaSenha || !senhaAtual}
                styledesktop={styleDesktop}
                stylemobile={styleMobile}
                styleglobal={styleGlobal}
                onClick={handleAlteraSenha}
              >
                Salvar
              </BotaoAcoes>
            </Col>
          </Row>
          <Row className="mt-3">
            <Row>
              <Col md={6} sm={12}>
                <FormGroup>
                  <Label for="senhaAtual">Senha atual</Label>
                  <Input
                    type="text"
                    value={senhaAtual}
                    name="senhaAtual"
                    data-testid="senhaAtual"
                    onChange={(e) => setSenhaAtual(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={12}>
                <FormGroup>
                  <Label for="senhaNova">Nova senha</Label>
                  <Input
                    type="text"
                    value={senha}
                    name="senhaNova"
                    data-testid="senhaNova"
                    onChange={(e) => setSenha(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col md={6} sm={12}>
                <FormGroup>
                  <Label for="confirmaSenhaNova">Confirmar nova senha</Label>
                  <Input
                    type="text"
                    value={confirmaSenha}
                    name="confirmaSenhaNova"
                    data-testid="confirmaSenhaNova"
                    onChange={(e) => setConfirmaSenha(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Row>
        </Carregando>
      </CardBody>
    </Card>
  );
}

const styleDesktop = {
  width: "150px",
  marginTop: "-40px",
  marginRight: "10px",
};
const styleMobile = {
  marginBottom: "40px",
};
const styleGlobal = {
  boxShadow: "0.5px 1px 2px #888888",
};
