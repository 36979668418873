import React from "react";
import { useSelector } from "react-redux";
import { Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { toast } from "react-toastify";
import BotaoAcoes from "../../../app/BotaoAcoes";
import Carregando from "../../../app/Carregando";

import criaCategoria from "../../../api/criaCategoria";

import "./Categorias.css";

export default function NovaCategoria({ open, setOpen, reload }) {
  const [nome, setNome] = React.useState("");
  const [criaCategoriaCarregando, setCriaCategoriaCarregando] = React.useState(false);

  const token = useSelector((state) => state.auth.token);

  const handleCloseModal = () => {
    setNome("");
    setOpen(false);
  };

  const handleCriaCategoria = () => {
    if (!nome) return toast.warning("Por favor preencha o nome da categoria.");
    setCriaCategoriaCarregando(true);
    criaCategoria
      .fetchCriaCategoria(nome, token)
      .then((res) => {
        if (!res.success) return res.error;
        toast.success("Categoria criada com sucesso");
        setCriaCategoriaCarregando(false);
        setOpen(false);
        reload();
      })
      .finally(() => setCriaCategoriaCarregando(false));
  };

  return (
    <>
      <Modal isOpen={open} toggle={() => setOpen(!open)} size="lg">
        <ModalHeader toggle={() => setOpen(!open)}>
          <span className="modalHeader">Nova Categoria</span>
        </ModalHeader>
        <ModalBody>
          <Form>
            <Carregando carregando={criaCategoriaCarregando}>
              <Row>
                <Col md={12} xs={12}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FormGroup style={{ width: "70%" }}>
                      <Label for="nome">Nome da categoria</Label>
                      <Input
                        id="nome"
                        data-testid="nome"
                        required
                        name="nome"
                        type="text"
                        value={nome}
                        disabled={criaCategoriaCarregando}
                        onChange={(e) => setNome(e.target.value)}
                      />
                    </FormGroup>
                    <BotaoAcoes
                      data-testid="button-criar"
                      disabled={criaCategoriaCarregando}
                      onClick={handleCriaCategoria}
                      styleglobal={{ ...styleGlobalAdicionar, width: "20%" }}
                    >
                      Criar
                    </BotaoAcoes>
                  </div>
                </Col>
              </Row>
            </Carregando>
          </Form>
        </ModalBody>
        <ModalFooter>
          <BotaoAcoes
            disabled={criaCategoriaCarregando}
            styleglobal={styleGlobal}
            reversecolor={true}
            onClick={handleCloseModal}
          >
            Voltar
          </BotaoAcoes>
        </ModalFooter>
      </Modal>
    </>
  );
}

const styleGlobal = { position: "relative", width: "120px" };

const styleGlobalAdicionar = {
  position: "relative",
  width: "100px",
  height: "38px",
  marginLeft: 20,
  marginTop: 10,
};
