import { Button } from "reactstrap";
import PropTypes from "prop-types";
import React from "react";
import useWindowDimensions from "../../Utils/getWindowDimension";

function BotaoAcoes({
  stylemobile,
  styledesktop,
  styleglobal,
  classNameSpan,
  reversecolor,
  onClick,
  children,
  ...props
}) {
  const { width } = useWindowDimensions();
  const mobile = width > 768 ? false : true;
  const styleMobile = {
    width: "80%",
    height: "40px",
    position: "relative",
    borderRadius: "5px",
    margin: "10px 0px",
    boxShadow: "1px 1px 8px #888888",
    fontSize: "16px",
  };
  const styleDesktop = {
    width: "200px",
    height: "40px",
    position: "absolute",
    right: 0,
    borderRadius: "5px",
    boxShadow: "1px 1px 8px #888888",
    fontSize: "14px",
  };
  const styleButton = mobile
    ? stylemobile
      ? Object.assign(styleMobile, stylemobile)
      : styleMobile
    : styledesktop
    ? Object.assign(styleDesktop, styledesktop)
    : styleDesktop;

  styleglobal && Object.assign(styleButton, styleglobal);

  const className = reversecolor ? "reverse-primary-button" : "primary-button";
  return (
    <Button {...props} color="primary" style={styleButton} onClick={onClick} className={`${className}`}>
      <span className={classNameSpan}>{children}</span>
    </Button>
  );
}

BotaoAcoes.defaultProps = {
  reversecolor: false,
};

BotaoAcoes.propTypes = {
  styledesktop: PropTypes.object,
  stylemobile: PropTypes.object,
  styleglobal: PropTypes.object,
  classNameSpan: PropTypes.string,
  reversecolor: PropTypes.bool,
  onClick: PropTypes.func,
};

export default BotaoAcoes;
