import { AvForm } from "availity-reactstrap-validation-safe";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import criaUsuario from "../../api/criaUsuario";
import BotaoAcoes from "../../app/BotaoAcoes";
import Carregando from "../../app/Carregando";
import PerfilSelect from "../../app/PerfilSelect";
import useWindowDimensions from "../../Utils/getWindowDimension";

export default function NovoUsuario({ open, setOpen, reload }) {
  const [formulario, setFormulario] = useState({
    email: "",
    nome: "",
    perfil: "",
    senha: "",
    confirmaSenha: "",
  });
  const [ativo, setAtivo] = useState(true);
  const [carregando, setCarregando] = useState(false);

  const { width } = useWindowDimensions();
  const mobile = width > 768 ? false : true;

  const token = useSelector((state) => state.auth.token);

  const handleCloseModal = () => {
    setFormulario({
      email: "",
      nome: "",
      perfil: "",
      senha: "",
      confirmaSenha: "",
    });
    setOpen(false);
  };

  const handleValidSubmit = () => {
    if (!formulario.nome || !formulario.email || !formulario.senha || !formulario.confirmaSenha)
      return toast.warning("Por favor preencha todos os campos");
    if (formulario.senha !== formulario.confirmaSenha) return toast.warning("As senhas devem ser iguais");
    setCarregando(true);
    let body = formulario;
    delete body.confirmaSenha;
    body.ativo = ativo;
    body.departamentos = [];
    criaUsuario
      .criaUsuarioPost(body, token)
      .then((res) => {
        if (!res.success) return res.error;
        toast.success("Usuário criado com sucesso");
        setCarregando(false);
        setOpen(false);
        reload();
      })
      .finally(() => setCarregando(false));
  };

  const handleInvalidSubmit = (event, values) => {
    toast.warning("Por favor preencha todos os campos");
  };

  return (
    <>
      <Modal isOpen={open} toggle={() => setOpen(!open)} size="lg">
        <ModalHeader toggle={() => setOpen(!open)}>
          <span className="modalHeader">Novo usuário</span>
        </ModalHeader>
        <ModalBody>
          <AvForm onValidSubmit={handleValidSubmit} onInvalidSubmit={handleInvalidSubmit}>
            <Carregando carregando={carregando}>
              <Row>
                <Col md={12} xs={12}>
                  <FormGroup>
                    <Label for="email">Email do usuário</Label>
                    <Input
                      id="email"
                      data-testid="email"
                      required
                      name="email"
                      type="text"
                      value={formulario.email}
                      onChange={(e) => {
                        setFormulario({ ...formulario, email: e.target.value });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={4} xs={12}>
                  <FormGroup>
                    <Label for="nome">Nome do usuário</Label>
                    <Input
                      id="nome"
                      data-testid="nome"
                      required
                      name="nome"
                      type="text"
                      value={formulario.nome}
                      onChange={(e) => {
                        setFormulario({ ...formulario, nome: e.target.value });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={4} xs={12}>
                  <FormGroup>
                    <PerfilSelect
                      helpMessage={false}
                      value={formulario.perfil}
                      onChange={(value) => setFormulario({ ...formulario, perfil: value })}
                    />
                  </FormGroup>
                </Col>
                <Col md={4} xs={12} className="d-flex" style={{ justifyContent: "flex-end", alignItems: "center" }}>
                  <FormGroup switch>
                    <Input type="switch" checked={ativo} onChange={() => setAtivo(!ativo)} />
                    <Label check>Usuário ativo ?</Label>
                  </FormGroup>
                </Col>
                <Col md={6} xs={12}>
                  <FormGroup>
                    <Label for="senha">Senha</Label>
                    <Input
                      id="senha"
                      data-testid="senha"
                      required
                      name="senha"
                      type="text"
                      value={formulario.senha}
                      onChange={(e) => {
                        setFormulario({ ...formulario, senha: e.target.value });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={6} xs={12}>
                  <FormGroup>
                    <Label for="confirmaSenha">Confirmar senha</Label>
                    <Input
                      id="confirmaSenha"
                      data-testid="confirmaSenha"
                      required
                      name="confirmaSenha"
                      type="text"
                      value={formulario.confirmaSenha || ""}
                      onChange={(e) => {
                        setFormulario({ ...formulario, confirmaSenha: e.target.value });
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Col md={12} xs={12} className={mobile ? "text-center" : "text-right"} style={{ marginTop: "10px" }}>
                <BotaoAcoes data-testid="button-criar" type="submit" styleglobal={styleGlobal}>
                  Criar
                </BotaoAcoes>
              </Col>
            </Carregando>
          </AvForm>
        </ModalBody>
        <ModalFooter>
          <BotaoAcoes styleglobal={styleGlobal} reversecolor={true} onClick={handleCloseModal}>
            Voltar
          </BotaoAcoes>
        </ModalFooter>
      </Modal>
    </>
  );
}

const styleGlobal = { position: "relative", width: "120px" };
