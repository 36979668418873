import React, { useState } from "react";
import "../../features/Routes/Routes.css";
import { MdLogout, MdPersonOutline, MdPassword, MdOutlinePersonPin } from "react-icons/md";
import { Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from "reactstrap";
import useWindowDimensions from "../../Utils/getWindowDimension";
import { useDispatch } from "react-redux";
import { setToken, verificaLogin } from "../../Components/Auth/authSlice";
import { useHistory } from "react-router-dom";

function Header() {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const { width } = useWindowDimensions();
  const mobile = width > 768 ? false : true;

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const handleLogout = () => {
    setDropdownOpen((prevState) => !prevState);
    dispatch(verificaLogin(false));
    dispatch(setToken(null));
    return history.push("/login");
  };

  return (
    <div className="header" style={mobile ? { width: "85%" } : {}}>
      <Row className={"header-container"}>
        <Col xs={12} md={12}>
          <div>
            <Dropdown className="perfil" isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle caret>
                <MdPersonOutline className="header-icon" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => history.push("/perfil")}>
                  <MdOutlinePersonPin style={{ marginRight: 5 }} /> Meu perfil
                </DropdownItem>
                <DropdownItem onClick={() => history.push("/usuarios")}>+ Adicionar usuários</DropdownItem>
                <DropdownItem onClick={() => history.push("/alterar-senha")}>
                  <MdPassword style={{ marginRight: 5 }} /> Alterar senha
                </DropdownItem>
                <DropdownItem onClick={() => handleLogout()}>
                  <MdLogout style={{ marginRight: 5 }} /> Logout
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </Col>
      </Row>
      <hr></hr>
    </div>
  );
}

export default Header;
