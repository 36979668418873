import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import _ from "lodash";
import { notificacaoErro } from "../app/notificacao";
import { useTokenValido } from "../app/token";
import { verificaLogin } from "../Components/Auth/authSlice";

const serialize = (obj) => {
  let str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      if (obj[p]) str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
};

export default function useFetchBase(rota) {
  const logged = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [carregando, setCarregando] = useState();
  const [dados, setDados] = useState({});
  const tokenValido = useTokenValido();

  const get = useCallback(
    (pagina = null, query = {}) => {
      setCarregando(true);
      let url = new URL(rota, process.env.REACT_APP_API_URL);
      if (pagina) {
        query["page"] = pagina;
      }
      if (!_.isEmpty(query)) {
        url = new URL(`?${serialize(query)}`, url);
      }
      const init = {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + logged.token,
        },
      };

      fetch(url, init)
        .then((fet) => {
          setCarregando(false);
          switch (fet.status) {
            case 200:
              return fet.json();
            case 401:
              dispatch(verificaLogin(false));
              return notificacaoErro(fet, init, "Ocorreu um erro no atendimento 401");
            case 404:
              return notificacaoErro(fet, init, "Ocorreu um erro no atendimento 404");
            default:
              return notificacaoErro(fet, init, "Ocorreu um erro inesperado.");
          }
        })
        .then((json) => {
          setDados(json);
        })
        .finally(() => setCarregando(false));
    },
    [setCarregando, setDados, logged, dispatch, rota]
  );

  if (!tokenValido) {
    dispatch(verificaLogin(false));
  }

  return { dados, get, carregando };
}

export function useFetchUsuarios(departamento = null) {
  let url = departamento ? `/usuario?departamento=${departamento}` : "/usuario";
  const { dados, get, carregando } = useFetchBase(url);
  return { usuarios: dados, get, carregando };
}

export function useFetchProximosAtendimentos() {
  const { dados, get, carregando } = useFetchBase("/proximos-atendimentos");
  return { atendimentos: dados, get, carregando };
}

export function useFetchAtendimentos() {
  const { dados, get, carregando } = useFetchBase("/atendimentos");
  return { atendimentos: dados, get, carregando };
}

export function useFetchCategoria() {
  const { dados, get, carregando } = useFetchBase("/categorias");
  return { categorias: dados, get, carregando };
}

export function useFetchPerfis() {
  const { dados, get, carregando } = useFetchBase("/perfis");
  return { perfis: dados, get, carregando };
}

export function useFetchDepartamentos() {
  const { dados, get, carregando } = useFetchBase("/departamento");
  return { departamentos: dados, get, carregandoDepartamentos: carregando };
}

export function useFetchMotivos() {
  const { dados, get, carregando } = useFetchBase("/motivos");
  return { motivos: dados, get, carregando };
}

export function useFetchMeioAtendimento() {
  const { dados, get, carregando } = useFetchBase("/meios-atendimento");
  return { meios: dados, get, carregando };
}

export function useFetchStatusAtendimentos() {
  const { dados, get, carregando } = useFetchBase("/atendimentos-por-status");
  return { status: dados, getStatus: get, carregandoStatus: carregando };
}

export function useFetchCategoriasAtendimentos() {
  const { dados, get, carregando } = useFetchBase("/atendimentos-por-categoria");
  return { categorias: dados, getCategorias: get, carregandoCategorias: carregando };
}
