import "./DropFile.css";

import { FaAngleDoubleDown } from "react-icons/fa";
import { MdNote, MdFileUpload } from "react-icons/md";
import React, { useState } from "react";

import Dropzone from "react-dropzone";
import { Spinner } from "reactstrap";
import { notificacaoErro } from "../notificacao";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

function DropFile(props) {
  const [uploading, setUploading] = useState();
  const [dragging, setDragging] = useState();
  const [anexos, setAnexos] = useState([]);
  const [filesList, setFilesList] = useState([]);
  const logged = useSelector((state) => state.auth);

  const onDragEnter = () => {
    setDragging(true);
  };

  const onDragLeave = () => {
    setDragging(false);
  };

  const sendAnexos = (anexo) => {
    props.onChangeDropFile(anexo);
  };

  const onDrop = (a, rejeitado, event) => {
    if (rejeitado.length >= 1) {
      setDragging(false);
      if (rejeitado.length === 1) {
        return toast.error("Arquivo recusado.");
      }
      return toast.error("Envie um arquivo por vez");
    }
    setUploading(true);
    setDragging(false);

    const file = a[0];
    const formData = new FormData();
    formData.append("atendimentoFile", file);
    const url = new URL(process.env.REACT_APP_API_URL + "/atendimento-anexo");
    const init = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + logged.token,
      },
      body: formData,
    };
    fetch(url, init).then(async (fet) => {
      setUploading(false);
      setDragging(false);
      switch (fet.status) {
        case 201:
          const response = await fet.json();
          const novoAnexo = response.anexo.id;
          let novosAnexos = anexos;
          novosAnexos.push(novoAnexo);
          setAnexos(novosAnexos);
          setFilesList([...filesList, file]);
          return sendAnexos(anexos);
        case 401:
          return notificacaoErro(fet, init, "Ocorreu um erro no atendimento 401");
        case 404:
          return notificacaoErro(fet, init, "Ocorreu um erro no atendimento 404");
        default:
          return notificacaoErro(fet, init, "Ocorreu um erro inesperado.");
      }
    });
  };

  return (
    <div>
      <div>
        {filesList ? (
          <div style={{ marginBottom: "1em" }}>
            Anexos
            {filesList.map((a, i) => (
              <div key={i} className="fileList">
                <MdNote />
                <span>{a.name}</span> - <i>{a.size}mb</i>
              </div>
            ))}
          </div>
        ) : null}
      </div>

      <Dropzone
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
        disabled={uploading}
        multiple={false}
        maxSize={2097152}
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()} className="inputFile">
              <input {...getInputProps()} />

              <div className="text">
                {dragging && !uploading ? (
                  <div>
                    <FaAngleDoubleDown />
                    <p>Solte o arquivo para enviar</p>
                  </div>
                ) : null}
                {!uploading && !dragging ? (
                  <div>
                    <MdFileUpload />
                    <p>Solte seus arquivos aqui ou clique para buscar um arquivo em seu computador.</p>
                  </div>
                ) : null}
                {uploading ? (
                  <div>
                    <Spinner /> Carregando arquivo...
                  </div>
                ) : null}
              </div>
            </div>
          </section>
        )}
      </Dropzone>
    </div>
  );
}

export default DropFile;
