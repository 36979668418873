import { notificacaoErro } from "../app/notificacao";

async function fetchComentaAtendimento(comentario, protocolo, token) {
  const url = new URL(process.env.REACT_APP_API_URL + "/atendimento/" + protocolo + "/comentar");
  const init = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify({
      comentario,
    }),
  };

  const fet = await fetch(url, init);

  switch (fet.status) {
    case 201:
      return { success: true, status: fet.status, data: await fet.json() };
    case 404:
      return {
        success: false,
        status: fet.status,
        error: notificacaoErro(fet, init, "Ocorreu um erro ao adicionar comentário."),
      };
    default:
      return {
        success: false,
        status: fet.status,
        error: notificacaoErro(fet, init, "Ocorreu um erro inesperado ao adicionar comentário."),
      };
  }
}

const comentaAtendimento = { fetchComentaAtendimento };

export default comentaAtendimento;
