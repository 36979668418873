import "./Atender.css";

import { Button, Card, CardBody, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import React, { useEffect, useState } from "react";

import Carregando from "../../app/Carregando";
import DataFormatada from "../../app/DataFormatada";
import EditarResponsavel from "../../app/EditarResponsavel";
import IrParaAtendimento from "../../app/IrParaAtendimento";
import IrParaPessoaEmpresa from "../../app/IrParaPessoaEmpresa";
import Pagination from "react-js-pagination";
import Protocolo from "../../app/Protocolo";
import StatusAtendimento from "../../app/StatusAtendimento";
import { useFetchProximosAtendimentos } from "../../api/base";
import HeaderCardBody from "../../app/HeaderCardBody";
import UsuarioSelect from "../../app/UsuarioSelect";
import { useHistory } from "react-router-dom";
import useWindowDimensions from "../../Utils/getWindowDimension";

function ProximosAtendimentos() {
  const { atendimentos, get, carregando } = useFetchProximosAtendimentos();
  const history = useHistory();
  const [responsavel, setResponsavel] = useState(history.location.state?.usuario || null);
  const [filtros, setFiltros] = useState({
    pessoaEmpresa: "",
    responsavel: null,
    status: history.location.state?.status || "",
  });

  const { width } = useWindowDimensions();
  const mobile = width > 768 ? false : true;

  const carregaDados = (novaPagina) => get(novaPagina, filtros);

  const handleChangeInput = (e) => {
    setFiltros({ ...filtros, [e.target.name]: e.target.value });
  };

  const handleNovoAtendimento = () => {
    history.push("/novo-atendimento");
  };

  useEffect(() => {
    if (history.location.state?.status && history.location.state?.usuario) {
      const statusFiltro = history.location.state.status;
      const responsavel = history.location.state.usuario;
      get(1, { status: statusFiltro, responsavel: responsavel.id });
      window.history.replaceState({}, document.title);
    } else get();
  }, [get, history.location.state]);

  return (
    <Card>
      <HeaderCardBody
        titulo="Próximos atendimentos"
        tituloBotao="+ Novo Atendimento"
        onBotaoAcoes={handleNovoAtendimento}
        mostraBotao={true}
      />
      <CardBody>
        <Form>
          <Row className="styled-form-row">
            <Col md={4} xs={12}>
              <FormGroup className="mr-2 mb-0">
                <Label className="mr-1">Pessoa/Empresa</Label>
                <Input
                  type="text"
                  value={filtros.pessoaEmpresa}
                  name="pessoaEmpresa"
                  onChange={(e) => handleChangeInput(e)}
                />
              </FormGroup>
            </Col>
            <Col md={4} xs={12}>
              <FormGroup className="mr-2 mb-0">
                <Label className="mr-1">Responsável</Label>
                <UsuarioSelect
                  usuarioSelecionado={responsavel}
                  onChange={(e) => {
                    setFiltros({ ...filtros, responsavel: e.id });
                    setResponsavel(e);
                  }}
                />
              </FormGroup>
            </Col>
            <Col md={4} xs={12}>
              <FormGroup className="mr-2 mb-0">
                <Label className="mr-1">Status</Label>
                <Input type="select" value={filtros.status} name="status" onChange={(e) => handleChangeInput(e)}>
                  <option>Selecione</option>
                  <option value="1">Aguardando atendimento</option>
                  <option value="2">Em atendimento</option>
                  <option value="4">Atendimento concluído</option>
                  <option value="3">Encerrado</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Col md={12} xs={12} className={mobile ? "text-center" : "text-right"}>
            <Button className="primary-button" type="button" onClick={() => carregaDados(1)}>
              Pesquisar
            </Button>
          </Col>
        </Form>
        <Carregando carregando={carregando}>
          <table className="ui paded table" style={{ marginTop: "20px" }}>
            <thead>
              <tr>
                <th>Protocolo</th>
                <th>Motivo</th>
                <th>Status</th>
                <th>Criado em</th>
                <th>Pessoa/Empresa</th>
                <th>Responsável</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {atendimentos?._embedded?.map((atendimento, index) => {
                return (
                  <tr key={index}>
                    <th scope="row">
                      <IrParaAtendimento protocolo={atendimento.protocolo}>
                        <Protocolo protocolo={atendimento.protocolo} />
                      </IrParaAtendimento>
                    </th>
                    <td>{atendimento.motivo.descricao}</td>
                    <td>
                      <StatusAtendimento status={atendimento.status} />
                    </td>
                    <td>
                      <DataFormatada data={atendimento.dataHoraInclusao} />
                    </td>
                    <td>
                      <IrParaPessoaEmpresa pessoa={atendimento.pessoa} empresa={atendimento.empresa} />
                    </td>
                    <td>
                      <EditarResponsavel atendimento={atendimento} />
                    </td>
                    <td>
                      <Button
                        className="primary-button table-action-button"
                        type="button"
                        tag={IrParaAtendimento}
                        protocolo={atendimento.protocolo}
                      >
                        Visualizar
                      </Button>
                    </td>
                  </tr>
                );
              })}
              {(!atendimentos || atendimentos?._embedded?.length === 0) && !carregando ? (
                <tr>
                  <td colSpan="6">Nenhum atendimento encontrado...</td>
                </tr>
              ) : null}
            </tbody>
            <tfoot>
              <tr>
                <th colSpan="12">
                  <div className="ui right floated pagination menu">
                    {atendimentos && atendimentos?._embedded?.length > 0 ? (
                      <Pagination
                        activePage={atendimentos.page}
                        itemsCountPerPage={10}
                        totalItemsCount={atendimentos.total_items}
                        pageRangeDisplayed={5}
                        onChange={carregaDados}
                        itemClass="page-item item"
                        linkClass="page-link"
                      />
                    ) : null}
                  </div>
                </th>
              </tr>
            </tfoot>
          </table>
        </Carregando>
      </CardBody>
    </Card>
  );
}

export default ProximosAtendimentos;
