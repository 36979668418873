import React from "react";
import { useFetchEntidade } from "../../api/entidade";

export default function NomeNovoAtendimento({ id, tipo, setCarregando }) {
  const [entidade, carregandoEntidade] = useFetchEntidade(id, tipo);
  React.useEffect(() => {
    setCarregando(carregandoEntidade);
  }, [setCarregando, carregandoEntidade]);
  return <>{entidade?.nome || entidade?.razaoSocial}</>;
}
