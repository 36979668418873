import { Card, CardBody } from "reactstrap";
import { useHistory } from "react-router-dom";

import FormBuscaEmpresa from "../../app/FormBuscaEmpresa";
import { Redirect } from "react-router";
import { useTokenValido } from "../../app/token";
import HeaderCardBody from "../../app/HeaderCardBody";

function ListaEmpresas() {
  const history = useHistory();

  const irParaEmpresa = (empresa) => {
    history.push(`/empresas/${empresa.id}`);
  };

  if (!useTokenValido()) {
    return <Redirect to="/login" />;
  } else {
    return (
      <Card>
        <HeaderCardBody titulo="Empresas" mostraBotao={false} />
        <CardBody>
          <FormBuscaEmpresa onSelect={irParaEmpresa} />
        </CardBody>
      </Card>
    );
  }
}

export default ListaEmpresas;
