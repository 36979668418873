import "./Login.css";

import { AvField, AvForm } from "availity-reactstrap-validation-safe";
import { Card, CardBody } from "reactstrap";
import { useState } from "react";
import { setDepartamentos, setToken, verificaLogin } from "../../Components/Auth/authSlice";
import { useDispatch } from "react-redux";

import AutenticaUsuario from "../../api/autenticaUsuario";
import BotaoForm from "../../app/BotaoForm";
import { Redirect } from "react-router";
import logo from "../../images/logo-login.png";
import { toast } from "react-toastify";
import { useTokenValido } from "../../app/token";
import Carregando from "../../app/Carregando";

function LoginForm(props) {
  const dispatch = useDispatch();

  const [carregando, setCarregando] = useState(false);

  const handleValidSubmit = (event, values) => {
    setCarregando(true);

    AutenticaUsuario.fetchAutenticaUsuario(values.idSindicato, values.email, values.senha)
      .then((response) => {
        setCarregando(false);
        if (!response.success) {
          return response.error;
        }
        dispatch(verificaLogin(true));
        dispatch(setToken(response.data["hash-token"]));
        dispatch(setDepartamentos(response.data["departamentos"]));
      })
      .catch((e) => {
        console.log(e);
        toast.error("Não foi possivel completar a solicitação");
      })
      .finally(() => {
        setCarregando(false);
      });
  };

  const handleInvalidSubmit = (event, errors, values) => {
    toast.warning("Por favor, preencha todos os campos");
  };

  if (useTokenValido()) {
    return <Redirect to="/" />;
  } else {
    return (
      <div className="container-login">
        <Card className="login-content">
          <CardBody>
            <AvForm className="loginForm" onValidSubmit={handleValidSubmit} onInvalidSubmit={handleInvalidSubmit}>
              {logo ? <img className="logoLogin" src={logo} alt="Logo" /> : <h1 className="text-center">Login</h1>}
              <div>
                <AvField
                  name="idSindicato"
                  type="number"
                  placeholder="Código do sindicato"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Por favor insira seu código",
                    },
                    pattern: {
                      value: "^[0-9]+$",
                      errorMessage: "Seu código deve conter apenas números",
                    },
                  }}
                />
                <AvField
                  name="email"
                  type="text"
                  placeholder="E-mail"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Por favor insira seu email",
                    },
                    email: true,
                  }}
                />
                <AvField
                  name="senha"
                  type="password"
                  placeholder="Senha"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Por favor insira sua senha",
                    },
                  }}
                />
              </div>
              {carregando ? (
                <div style={{ height: "32px" }}>
                  <Carregando carregando={carregando} />
                </div>
              ) : (
                <BotaoForm type="submit" lg="true" data-testid="Entrar">
                  Login
                </BotaoForm>
              )}
            </AvForm>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default LoginForm;
