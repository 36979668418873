import React from "react";
import { CardHeader, Col, Row } from "reactstrap";
import useWindowDimensions from "../../Utils/getWindowDimension";
import PropTypes from "prop-types";
import BotaoAcoes from "../BotaoAcoes";

export default function HeaderCardBody({ styleCardHeader, titulo, mostraBotao, tituloBotao, onBotaoAcoes }) {
  const { width } = useWindowDimensions();
  const mobile = width > 768 ? false : true;
  return (
    <CardHeader style={styleCardHeader}>
      <Row>
        <Col md={6} xs={12}>
          <h1>{titulo}</h1>
        </Col>
        {mostraBotao && (
          <Col md={6} xs={12} style={mobile ? { display: "flex", justifyContent: "center" } : {}}>
            <BotaoAcoes onClick={onBotaoAcoes}>{tituloBotao}</BotaoAcoes>
          </Col>
        )}
      </Row>
    </CardHeader>
  );
}

HeaderCardBody.defaultProps = {
  mostraBotao: true,
};

HeaderCardBody.propTypes = {
  titulo: PropTypes.string,
  mostraBotao: PropTypes.bool,
  tituloBotao: PropTypes.string,
  onBotaoAcoes: PropTypes.func,
};
