import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Card } from "reactstrap";
import HeaderCardBody from "../../app/HeaderCardBody";
import Departamentos from "./Departamentos/Departamentos";

import "./Classificacoes.css";
import { useFetchUsuarioAtivo } from "../../api/usuarioAtivo";
import Carregando from "../../app/Carregando";
import Categorias from "./Categorias/Categorias";
import Motivos from "./Motivos/Motivos";

export default function Classificacoes() {
  const ref = React.useRef(null);
  const location = useLocation();

  const [usuarioAtivo, carregandoUsuarioAtivo] = useFetchUsuarioAtivo();

  const handleBotaoAcao = () => {
    ref.current.setOpenModal();
  };

  const classificacoes = [
    {
      titulo: "Departamentos",
      tituloBotao: "+ Novo Departamento",
      component: <Departamentos referencia={ref} />,
      mostraBotao: () => {
        if (usuarioAtivo.perfil && !carregandoUsuarioAtivo) {
          if (usuarioAtivo.perfil.id === 1) return true;
          else return false;
        }
      },
    },
    {
      titulo: "Categorias",
      tituloBotao: "+ Nova Categoria",
      component: <Categorias referencia={ref} />,
      mostraBotao: () => {
        if (usuarioAtivo.perfil && !carregandoUsuarioAtivo) {
          if (usuarioAtivo.perfil.id === 1) return true;
          else return false;
        }
      },
    },
    {
      titulo: "Motivos",
      tituloBotao: "+ Novo Motivo",
      component: <Motivos referencia={ref} />,
      mostraBotao: () => {
        if (usuarioAtivo.perfil && !carregandoUsuarioAtivo) {
          if (usuarioAtivo.perfil.id === 1) return true;
          else return false;
        }
      },
    },
  ];

  const classificacaoAtiva = classificacoes.find(
    (classificacao) => classificacao.titulo.toLocaleLowerCase() === location.pathname.slice(1)
  );

  return (
    <Card>
      <Carregando carregando={carregandoUsuarioAtivo}>
        <HeaderCardBody
          onBotaoAcoes={handleBotaoAcao}
          titulo={classificacaoAtiva.titulo}
          tituloBotao={classificacaoAtiva.tituloBotao}
          mostraBotao={classificacaoAtiva.mostraBotao()}
        />
      </Carregando>
      <div className="ui top attached tabular menu">
        {classificacoes.map((classificacao, index) => {
          let classificacaoAtivaClass =
            classificacao.titulo.toLocaleLowerCase() === location.pathname.slice(1) ? "active item" : "item";
          return (
            <Link
              key={index}
              className={`tab ${classificacaoAtivaClass}`}
              data-tab={classificacao.titulo.toLocaleLowerCase()}
              to={`/${classificacao.titulo.toLocaleLowerCase()}`}
            >
              {classificacao.titulo}
            </Link>
          );
        })}
      </div>
      <div className="ui bottom attached active tab segment" data-tab={classificacaoAtiva.titulo.toLocaleLowerCase()}>
        {classificacaoAtiva.component}
      </div>
    </Card>
  );
}
