import React from "react";

import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";

import Carregando from "../../app/Carregando";
import { MdSearch } from "react-icons/md";
import listaEmpresas from "../../api/empresa";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import TabelaListaEmpresa from "./TabelaListaEmpresa";
import formataCnpj from "../../Utils/formataCnpj";

export default function FormBuscaPessoa({ onSelect, novoAtendimento }) {
  const [filtros, setFiltros] = React.useState({});
  const [carregando, setCarregando] = React.useState();
  const [empresas, setEmpresas] = React.useState();
  const logged = useSelector((state) => state.auth);

  const onChangeFiltros = (campo) => (event) => {
    const valor = event.target.value;
    if (!valor) {
      delete filtros[campo];
      setFiltros({ ...filtros });
      return;
    }
    setFiltros({ ...filtros, [campo]: event.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setCarregando(true);
    setEmpresas(null);

    let cnpjFormatado;
    if (filtros?.cnpj) {
      cnpjFormatado = filtros.cnpj.replace(/[^\d]+/g, "");
      if (cnpjFormatado.length !== 14) {
        return toast.error("CNPJ inválido");
      }
    }

    listaEmpresas.useFetchListaEmpresas(logged.token, filtros).then((response) => {
      if (!response.success) {
        setCarregando(false);
        return response.error;
      }
      setCarregando(false);
      setEmpresas(response.data);
    });
  };

  const limpar = () => {
    setFiltros({});
  };

  return (
    <div>
      <Form onSubmit={onSubmit}>
        <Row>
          <Col md={6} sm={12}>
            <FormGroup>
              <Label for="razaoSocial">Razão Social</Label>
              <Input
                type="text"
                value={filtros.razaoSocial || ""}
                data-testid="razaoSocial"
                name="razao-social"
                onChange={onChangeFiltros("razaoSocial")}
              />
            </FormGroup>
          </Col>
          <Col md={6} sm={12}>
            <FormGroup required>
              <Label for="id">Código</Label>
              <Input
                type="number"
                data-testid="id"
                value={filtros.id || ""}
                name="id"
                onChange={onChangeFiltros("id")}
              />
            </FormGroup>
          </Col>
          <Col md={6} sm={12}>
            <FormGroup>
              <Label for="cpf">CNPJ</Label>
              <Input
                type="text"
                name="cnpj"
                data-testid="cnpj"
                value={formataCnpj(filtros?.cnpj) || ""}
                onChange={onChangeFiltros("cnpj")}
                maxLength="18"
              />
            </FormGroup>
          </Col>
          <Col md={6} sm={12}>
            <FormGroup>
              <Label for="sindical">Relação Sindicato</Label>
              <Input
                type="text"
                value={filtros.sindical || ""}
                data-testid="sindical"
                name="sindical"
                onChange={onChangeFiltros("sindical")}
              />
            </FormGroup>
          </Col>
          <Col md={6} xs={7} className="text-left align-items-end mb-3 mt-3">
            <Button className="primary-button" type="button" onClick={limpar}>
              Limpar dados
            </Button>
          </Col>
          <Col md={6} xs={5} className="text-right align-items-end mb-3 mt-3">
            <Button className="primary-button" type="submit">
              <MdSearch /> Buscar
            </Button>
          </Col>
        </Row>
      </Form>
      <Carregando carregando={carregando}>
        {empresas && <TabelaListaEmpresa novoAtendimento={novoAtendimento} onSelect={onSelect} empresas={empresas} />}
      </Carregando>
    </div>
  );
}
