import PropTypes from 'prop-types';
import React from 'react';

function Protocolo(props) {
    if (!props.protocolo) return <>-</>
    let protocolo = props.protocolo;
    return <span title={protocolo}>{protocolo.substr(0, 8)}</span>;
}

Protocolo.propTypes = {
    protocolo: PropTypes.string
}

export default Protocolo;