import { notificacaoErro } from "../app/notificacao";

async function fetchListaPessoas(token, values) {
  const url = new URL(process.env.REACT_APP_API_URL + "/pessoas-fisicas");
  Object.keys(values).forEach((key) => {
    if (!values[key]) {
      return;
    }
    url.searchParams.append(key, values[key]);
  });

  const init = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  const fet = await fetch(url, init);

  switch (fet.status) {
    case 200:
      return { success: true, status: fet.status, data: await fet.json() };
    case 404:
      return {
        success: false,
        status: fet.status,
        error: notificacaoErro(fet, init, "Não foi possível encontrar pessoas"),
      };
    default:
      return {
        success: false,
        status: fet.status,
        error: notificacaoErro(fet, init, "Ocorreu um erro inesperado na listagem da pessoa"),
      };
  }
}

const listaPessoas = { fetchListaPessoas };

export default listaPessoas;
