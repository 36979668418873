import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { notificacaoErro } from "../app/notificacao";
import { useTokenValido } from "../app/token";
import { verificaLogin } from "../Components/Auth/authSlice";

export default function useApiAtendimento(rota, metodo) {
  const logged = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [carregando, setCarregando] = useState(true);
  const [dados, setDados] = useState({});
  const tokenValido = useTokenValido();

  useEffect(() => {
    const url = new URL(process.env.REACT_APP_API_URL + rota);
    const init = {
      method: metodo,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + logged.token,
      },
    };

    fetch(url, init)
      .then((fet) => {
        setCarregando(false);
        switch (fet.status) {
          case 200:
            return fet.json();
          case 401:
            dispatch(verificaLogin(false));
            return notificacaoErro(fet, init, "Ocorreu um erro no atendimento 401");
          case 404:
            return notificacaoErro(fet, init, "Ocorreu um erro no atendimento 404");
          default:
            return notificacaoErro(fet, init, "Ocorreu um erro inesperado.");
        }
      })
      .then((json) => {
        setDados(json);
      })
      .finally(() => setCarregando(false));
  }, [setCarregando, setDados, dispatch, logged, metodo, rota]);

  if (!tokenValido) {
    dispatch(verificaLogin(false));
  }

  return [dados, carregando, logged, dispatch];
}

export function useFetchAtendimentoPorProtocolo() {
  const logged = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [carregando, setCarregando] = useState();
  const [dados, setDados] = useState({});
  const tokenValido = useTokenValido();

  const get = useCallback(
    (protocolo) => {
      setCarregando(true);
      const url = new URL(process.env.REACT_APP_API_URL + "/atendimento/" + protocolo);
      const init = {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + logged.token,
        },
      };

      fetch(url, init)
        .then((fet) => {
          setCarregando(false);
          switch (fet.status) {
            case 200:
              return fet.json();
            case 401:
              dispatch(verificaLogin(false));
              return notificacaoErro(fet, init, "Ocorreu um erro no atendimento 401");
            case 404:
              return notificacaoErro(fet, init, "Ocorreu um erro no atendimento 404");
            default:
              return notificacaoErro(fet, init, "Ocorreu um erro inesperado.");
          }
        })
        .then((json) => {
          setDados(json);
        });
    },
    [setCarregando, setDados, logged, dispatch]
  );

  if (!tokenValido) {
    dispatch(verificaLogin(false));
  }

  return { atendimento: dados, get, carregando };
}

export function useUpdate() {
  const dispatch = useDispatch();
  const logged = useSelector((state) => state.auth);
  const [estado, setEstado] = useState(false);

  const save = useCallback(
    (atendimento) => {
      setEstado({
        carregando: true,
        salvo: false,
      });
      const url = new URL(process.env.REACT_APP_API_URL + "/atendimento/" + atendimento.protocolo);

      const init = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + logged.token,
        },
        mode: "cors",
        body: JSON.stringify(atendimento),
      };
      fetch(url, init)
        .then((fet) => {
          switch (fet.status) {
            case 200:
              toast.success("Atendimento alterado com sucesso!");
              return setEstado({
                carregando: false,
                salvo: true,
              });
            case 401:
              dispatch(verificaLogin(false));
              setEstado({
                carregando: false,
                salvo: false,
              });
              return notificacaoErro(fet, init, "Ocorreu um erro no atendimento 401");
            case 404:
              setEstado({
                carregando: false,
                salvo: false,
              });
              return notificacaoErro(fet, init, "Ocorreu um erro no atendimento 404");
            default:
              setEstado({
                carregando: false,
                salvo: false,
              });
              return notificacaoErro(fet, init, "Ocorreu um erro inesperado.");
          }
        })
        .catch(() => {
          setEstado({
            carregando: false,
            salvo: false,
          });
          return notificacaoErro(null, init, "Ocorreu um erro inesperado.");
        });
    },
    [dispatch, logged]
  );

  return { save, estado };
}
