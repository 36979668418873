import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import BotaoAcoes from "../BotaoAcoes";
import { MdToll } from "react-icons/md";

export default function ListaDepartamentos({ titulo, subTitulo = "", departamentos, open, setOpen }) {
  return (
    <>
      <Modal isOpen={open} toggle={() => setOpen(!open)}>
        <ModalHeader toggle={() => setOpen(!open)}>
          <span className="modalHeader">
            {titulo}
            <span className="secondaryText subTitulo" data-testid="nome-departamento">
              {subTitulo}
            </span>
          </span>
        </ModalHeader>
        <ModalBody>
          <table className="ui very basic table">
            <thead>
              <tr>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {departamentos?.map((departamento, index) => (
                <tr key={index}>
                  <td>
                    <MdToll />
                  </td>
                  <td>{departamento.nome}</td>
                </tr>
              ))}
              {(!departamentos || departamentos?.length === 0) && (
                <tr>
                  <td colSpan="7">Não há departamentos cadastrados...</td>
                </tr>
              )}
            </tbody>
          </table>
        </ModalBody>
        <ModalFooter>
          <BotaoAcoes styleglobal={styleGlobal} reversecolor={true} onClick={() => setOpen(false)}>
            Voltar
          </BotaoAcoes>
        </ModalFooter>
      </Modal>
    </>
  );
}

const styleGlobal = { position: "relative", width: "140px" };
