import "./AtendimentoDetalhe.css";

import { Badge, Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { Button as ButtonUi } from "semantic-ui-react";
import { MdModeEdit } from "react-icons/md";
import { useHistory, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";

import Avaliacao from "../../app/Avaliacao";
import { CampoTipoPessoa } from "../../app/CampoPessoa";
import Carregando from "../../app/Carregando";
import { GrupoBotoes } from "../../app/BotoesAcoesAtendimento";
import Historico from "./Historico";
import IrParaPessoaEmpresa from "../../app/IrParaPessoaEmpresa";
import StatusAtendimento from "../../app/StatusAtendimento";
import { useFetchAtendimentoPorProtocolo, useUpdate } from "../../api/atendimento";
import comentaAtendimento from "../../api/comentaAtendimento";
import MeioAtendimentoSelect from "../../app/MeioAtendimentoSelect";
import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation-safe";
import CategoriaSelect from "../../app/CategoriaSelect";
import MotivoSelect from "../../app/MotivoSelect";
import UsuarioSelect from "../../app/UsuarioSelect";
import { toast } from "react-toastify";
import BotaoAcoes from "../../app/BotaoAcoes";
import useWindowDimensions from "../../Utils/getWindowDimension";
import BuscaEmpresaPessoaAnonimaOuNaoCadastrada from "../../app/BuscaEmpresaPessoaAnonimaOuNaoCadastrada";
import BuscaEmpresaNovoAtendimento from "../NovoAtendimento/BuscaEmpresaNovoAtendimento";
import Dropzone from "react-dropzone";
import Comentarios from "./Comentarios";
import { useSelector } from "react-redux";
import adicionaAnexoAtendimento from "../../api/adicionaAnexoAtendimento";
import BaixaArquivo from "./BaixaArquivo";

export default function AtendimentoDetalhe() {
  const [estadoBotoes, setEstadoBotoes] = useState({});
  const [buscaEmpresa, setBuscaEmpresa] = React.useState(false);
  const [formEditado, setFormEditado] = React.useState(false);
  const [atendimentoConcluido, setAtendimentoConcluido] = React.useState(false);
  const [atividade, setAtividade] = React.useState("comentario");
  const [comentario, setComentario] = React.useState("");
  const [carregandoComponente, setCarregandoComponente] = React.useState(false);
  const [valuesForm, setValuesForm] = React.useState({
    descricao: "",
    meioAtendimento: "",
    categoria: null,
    motivo: "",
    usuarioCriacao: "",
    responsavel: "",
    departamento: "",
    dataCriacao: "",
    dataConclusao: "",
  });

  const token = useSelector((state) => state.auth.token);

  const { protocolo } = useParams();
  const history = useHistory();

  const { width } = useWindowDimensions();
  const mobile = width > 768 ? false : true;

  const { atendimento, get, carregando } = useFetchAtendimentoPorProtocolo();
  const { save, estado } = useUpdate();

  useEffect(() => {
    if (estadoBotoes.dados) return get(protocolo);
    get(protocolo);
  }, [get, protocolo, estadoBotoes]);

  useEffect(() => {
    if (atendimento) {
      setValuesForm({
        descricao: atendimento.descricao,
        meioAtendimento: atendimento.meioAtendimento?.id,
        categoria: atendimento.motivo?.categoria.id,
        motivo: atendimento.motivo,
        usuarioCriacao: atendimento.usuarioCriacao,
        responsavel: atendimento.responsavel,
        departamento: atendimento.motivo?.departamento.id,
        dataCriacao: formataData(atendimento.dataHoraInclusao?.date),
        dataConclusao: formataData(atendimento.dataHoraConclusao?.date),
      });
      if (atendimento.status?.id === 4) {
        setAtendimentoConcluido(true);
      }
    }
  }, [atendimento]);

  const formataAtendimento = (atendimento) => {
    let atendimentoAlterado = JSON.parse(JSON.stringify(atendimento));
    atendimentoAlterado.meioAtendimento = valuesForm.meioAtendimento;
    atendimentoAlterado.motivo = valuesForm.motivo.id;
    atendimentoAlterado.status = atendimento.status.id;
    atendimentoAlterado.opcaoComunicacao = valuesForm.opcaoComunicacao === "" ? null : valuesForm.opcaoComunicacao;
    atendimentoAlterado.descricao = valuesForm.descricao;
    atendimentoAlterado.pessoa = atendimento.pessoa?.id;
    atendimentoAlterado.dataHoraInclusao = atendimento.dataHoraInclusao.date;
    atendimentoAlterado.responsavel = valuesForm.responsavel?.id;
    return atendimentoAlterado;
  };

  const handleValidSubmit = (event, values) => {
    let atendimentoAlterado = formataAtendimento(atendimento);
    save(atendimentoAlterado);
  };

  const handleAdicionarComentario = () => {
    if (!comentario) return toast.warning("Não é possivel adicionar comentário em branco");
    setCarregandoComponente(true);
    comentaAtendimento
      .fetchComentaAtendimento(comentario, protocolo, token)
      .then((res) => {
        if (!res.success) return res.error;
        toast.success("Comentário adicionado");
        setCarregandoComponente(false);
        setComentario("");
        get(protocolo);
      })
      .finally(() => setCarregandoComponente(false));
  };

  const changeCategoria = (e) => {
    setFormEditado(true);
    setValuesForm({ ...valuesForm, motivo: "", responsavel: "", categoria: parseInt(e.target.value) });
  };

  const changeMotivo = (e, motivoSelecionado) => {
    setFormEditado(true);
    setValuesForm({
      ...valuesForm,
      motivo: motivoSelecionado,
      departamento: motivoSelecionado.departamento,
      responsavel: "",
    });
  };

  const onChangeAtendimento = (estado) => {
    setEstadoBotoes(estado);
  };

  const handleSelectEmpresa = (empresa) => {
    let atendimentoAlterado = formataAtendimento(atendimento);
    atendimentoAlterado.empresaAtendidoId = empresa.id;

    setBuscaEmpresa(!buscaEmpresa);
    save(atendimentoAlterado);
  };
  const handleEmpresaNaoCadastrada = (empresaNome) => {
    let atendimentoAlterado = formataAtendimento(atendimento);
    atendimentoAlterado.empresaAtendidoNome = empresaNome;

    setBuscaEmpresa(!buscaEmpresa);
    save(atendimentoAlterado);
  };

  const handleDrop = (a, rejeitado, event) => {
    if (rejeitado.length >= 1) {
      if (rejeitado.length === 1) {
        return toast.error("Arquivo recusado.");
      }
      return toast.error("Envie um arquivo por vez");
    }
    setCarregandoComponente(true);
    const file = a[0];
    const formData = new FormData();
    formData.append("atendimentoFile", file);
    adicionaAnexoAtendimento
      .fetchAdicionaAnexo(formData, protocolo, token)
      .then((res) => {
        if (!res.success) return res.error;
        toast.success("Anexo adicionado com sucesso");
        setCarregandoComponente(false);
        get(protocolo);
      })
      .finally(() => setCarregandoComponente(false));
  };

  const irParaAlterarPessoa = () => {
    history.push(`/atendimentos/${atendimento?.protocolo}/alterar-pessoa`);
  };

  const handleInvalidSubmit = (event, values) => {
    toast.warning("Por favor preencha todos os campos");
  };

  const handleBack = () => {
    history.push("/proximos-atendimentos");
  };
  return (
    <>
      {buscaEmpresa ? (
        <BuscaEmpresaNovoAtendimento
          tituloCard="Pesquise a empresa que deseja vincular ao atendimento"
          onSelectEmpresaNaoCadastrada={handleEmpresaNaoCadastrada}
          onSelectEmpresa={handleSelectEmpresa}
          onBackEmpresa={() => setBuscaEmpresa(!buscaEmpresa)}
        />
      ) : (
        <Carregando carregando={carregando || estadoBotoes.carregando || estado.carregando || carregandoComponente}>
          <Card>
            <CardHeader className="mb-5">
              <h1>{atendimento?.motivo?.descricao}</h1>
              <div className="modalAtendimentoHeader">
                <div className="clearfix">
                  <StatusAtendimento status={atendimento?.status} />
                  <Badge className="ml-2" title="Meio de atendimento">
                    {atendimento?.meioAtendimento?.descricao}
                  </Badge>
                </div>
              </div>
            </CardHeader>
            <CardBody>
              <AvForm onValidSubmit={handleValidSubmit} onInvalidSubmit={handleInvalidSubmit}>
                <Row>
                  <Col md={6} xs={12} className={mobile ? "text-center" : ""}>
                    <BotaoAcoes
                      type="button"
                      onClick={handleBack}
                      styledesktop={{ ...styleDesktop, left: 10 }}
                      stylemobile={styleMobile}
                      styleglobal={styleGlobal}
                      reversecolor={true}
                    >
                      Voltar
                    </BotaoAcoes>
                  </Col>
                  <Col md={6} xs={12} className={mobile ? "text-center" : ""}>
                    <BotaoAcoes
                      type="submit"
                      disabled={!formEditado}
                      styledesktop={styleDesktop}
                      stylemobile={styleMobile}
                      styleglobal={styleGlobal}
                    >
                      Salvar
                    </BotaoAcoes>
                  </Col>
                </Row>
                <Row className="mt-4 mb-2">
                  <Col md={6} xs={12}>
                    <div className="clearfix">
                      <div className="float-left">
                        {atendimento?.pessoa || atendimento?.empresa ? (
                          <div title="Nome da pessoa">
                            <IrParaPessoaEmpresa
                              className="nomePessoa"
                              pessoa={atendimento?.pessoa}
                              empresa={atendimento?.empresa}
                            />
                            {atendimento?.pessoa && (
                              <Button
                                onClick={irParaAlterarPessoa}
                                style={{ padding: ".5rem 1.0rem" }}
                                className="ml-3"
                                color="light"
                                size="md"
                                data-testid="edita-pessoa"
                              >
                                <MdModeEdit size={18} />
                              </Button>
                            )}
                            {!atendimento.pessoa?.id && atendimento.pessoa?.nome ? (
                              <small className="d-block mt-1" style={{ color: "#F2BDBD" }}>
                                pessoa não cadastrada
                              </small>
                            ) : (
                              <small className="text-uppercase d-block">
                                <CampoTipoPessoa texto={atendimento?.pessoa?.tipoPessoa} />
                                {" : : "}
                                {atendimento?.pessoa?.situacao_descricao || "N/D"}
                              </small>
                            )}
                          </div>
                        ) : (
                          <div>
                            <span className="nomePessoa">- Pessoa anônima -</span>
                            <Button
                              onClick={irParaAlterarPessoa}
                              style={{ padding: ".5rem 1.0rem" }}
                              className="ml-3"
                              color="light"
                              size="md"
                              data-testid="edita-pessoa"
                            >
                              <MdModeEdit size={18} />
                            </Button>
                          </div>
                        )}
                      </div>
                      <div className="float-right">
                        <Avaliacao nota={atendimento.avaliacao} />
                      </div>
                    </div>
                  </Col>
                  <Col md={6} xs={12}>
                    <div className="mb-3">
                      <div className="grupo-btn">
                        <GrupoBotoes onChangeAtendimento={onChangeAtendimento} atendimento={atendimento} />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row style={{ marginTop: "10px", marginBottom: "20px" }}>
                  <Col md={12} xs={12} className="d-flex">
                    <div style={{ width: "60px" }}>
                      <Dropzone onDrop={handleDrop} multiple={false} maxSize={2097152}>
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <ButtonUi type="button" size="big" icon="attach" />
                          </div>
                        )}
                      </Dropzone>
                    </div>
                    <ButtonUi
                      onClick={() => {
                        navigator.clipboard.writeText(window.location.href);
                        toast.success("URL copiada para a área de transferência");
                      }}
                      type="button"
                      size="big"
                      icon="share square"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={8} xs={12}>
                    <BuscaEmpresaPessoaAnonimaOuNaoCadastrada
                      nomeEmpresa={
                        atendimento.pessoa?.empresa_razaoSocial ||
                        atendimento.empresaAtendido?.razaoSocial ||
                        "- Não informado -"
                      }
                      onBuscar={() => setBuscaEmpresa(!buscaEmpresa)}
                      tituloBotao={
                        atendimento.pessoa?.empresa_razaoSocial || atendimento.empresaAtendido ? "Alterar" : "Adicionar"
                      }
                      mostraBotao={!atendimento.pessoa?.id}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12} xs={12}>
                    <FormGroup>
                      <AvGroup>
                        <AvField
                          rows="3"
                          data-testid="descricao"
                          onChange={(e) => {
                            setFormEditado(true);
                            setValuesForm({ ...valuesForm, descricao: e.target.value });
                          }}
                          value={valuesForm.descricao}
                          type="textarea"
                          name="descricao"
                          label="Descrição"
                          id="descricao"
                          disabled={atendimentoConcluido}
                          required
                        />
                      </AvGroup>
                    </FormGroup>
                  </Col>
                  <Col md={3} xs={12}>
                    <FormGroup>
                      <MeioAtendimentoSelect
                        disabled={atendimentoConcluido}
                        onChange={(e) => {
                          setFormEditado(true);
                          setValuesForm({ ...valuesForm, meioAtendimento: parseInt(e.target.value) });
                        }}
                        value={valuesForm.meioAtendimento || ""}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3} xs={6}>
                    <FormGroup>
                      <CategoriaSelect
                        disabled={atendimentoConcluido}
                        value={valuesForm.categoria || ""}
                        onChange={changeCategoria}
                        helpMessage={false}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3} xs={6}>
                    <FormGroup>
                      <MotivoSelect
                        disabled={atendimentoConcluido}
                        value={valuesForm.motivo?.id || ""}
                        categoria={valuesForm.categoria}
                        onChange={changeMotivo}
                        helpMessage={false}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3} xs={12}>
                    <FormGroup>
                      <Label className="mr-1">Responsável</Label>
                      <UsuarioSelect
                        onChange={(e) => setValuesForm({ ...valuesForm, responsavel: e })}
                        departamento={valuesForm.departamento}
                        customComponent={AvField}
                        disabled={!valuesForm.departamento || atendimentoConcluido}
                        usuarioSelecionado={valuesForm.responsavel}
                        required={false}
                        data-testid="responsavel"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3} xs={12}>
                    <FormGroup>
                      <Label className="mr-1">Usuário de criação</Label>
                      <UsuarioSelect
                        customComponent={AvField}
                        disabled={true}
                        usuarioSelecionado={valuesForm.usuarioCriacao}
                        required={false}
                        data-testid="usuarioCriacao"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3} xs={12}>
                    <FormGroup>
                      <Label for="dataCriacao">Data de criação</Label>
                      <Input
                        id="dataCriacao"
                        value={valuesForm.dataCriacao}
                        disabled={true}
                        name="dataCriacao"
                        type="date"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3} xs={12}>
                    <FormGroup>
                      <Label for="dataConclusao">Data de conclusão</Label>
                      <Input
                        id="dataConclusao"
                        value={valuesForm.dataConclusao}
                        disabled={true}
                        name="dataConclusao"
                        type="date"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </AvForm>
              {atendimento.anexos?.length > 0 && (
                <>
                  <hr className="divisor" />
                  <Row>
                    <Col md={12} xs={12}>
                      <h3>Anexos</h3>
                      <div>
                        <BaixaArquivo atendimento={atendimento} />
                      </div>
                    </Col>
                  </Row>
                </>
              )}
              <hr className="divisor" />
              <Row className="mt-2">
                <Col md={12} xs={12}>
                  <h3>Atividade</h3>
                  <Col md={4} xs={12}>
                    <FormGroup row>
                      <Label for="mostrar" sm={4}>
                        Mostrar:
                      </Label>
                      <Col sm={8}>
                        <Input id="mostrar" name="select" type="select" onChange={(e) => setAtividade(e.target.value)}>
                          <option value="comentarios">Comentários</option>
                          <option value="atualizacoes">Atualizações</option>
                        </Input>
                      </Col>
                    </FormGroup>
                  </Col>
                  {atividade === "atualizacoes" ? (
                    <Historico historicos={atendimento?.historicos} />
                  ) : (
                    <>
                      <Row className="mt-4 mb-5">
                        <Col md={8} xs={12} className="d-flex" style={{ alignItems: "center" }}>
                          <Input
                            type="text"
                            value={comentario}
                            placeholder="Adicionar comentário..."
                            onChange={(e) => setComentario(e.target.value)}
                            data-testid="comentario"
                          />
                          <BotaoAcoes
                            data-testid="comentar-button"
                            onClick={handleAdicionarComentario}
                            disabled={carregandoComponente}
                            styleglobal={{ width: "120px", position: "relative", marginLeft: 20 }}
                          >
                            Adicionar
                          </BotaoAcoes>
                        </Col>
                      </Row>
                      <Comentarios historicos={atendimento?.historicos} />
                    </>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Carregando>
      )}
    </>
  );
}

function formataData(data) {
  if (data) {
    let dataFormatada = data.split(" ");
    return dataFormatada[0];
  }
  return "";
}

const styleDesktop = {
  width: "130px",
  marginTop: "-40px",
  marginRight: "10px",
};
const styleGlobal = {
  boxShadow: "0.5px 1px 2px #888888",
};
const styleMobile = {
  marginBottom: "40px",
};
