import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    isLogged: false,
    token: null,
    departamentos: [],
  },
  reducers: {
    verificaLogin(state, action) {
      state.isLogged = action.payload;
    },
    setToken(state, action) {
      state.token = action.payload;
    },
    setDepartamentos(state, action) {
      state.departamentos = action.payload;
    },
  },
});

export const { verificaLogin, setToken, setDepartamentos } = authSlice.actions;
export default authSlice.reducer;
