import './CampoPessoa.css'

import PropTypes from 'prop-types';
import _ from 'lodash';

export function CampoTipoPessoa({ texto }) {
    if (!texto) return "N/D"
    const tipos = {
        S: "Sócio",
        U: "Sócio usuário",
        B: "Base do sindicato",
        D: "Demais pessoas"
    }

    return <span>{_.get(tipos, texto, 'Não definido')}</span>
}

CampoTipoPessoa.propTypes = {
    texto: PropTypes.string
}