import "./IrParaAtendimento.css";

import { Link } from "react-router-dom";
import PropTypes from "prop-types";

export default function IrParaAtendimento({ protocolo, children, className }) {
  if (!protocolo) return <></>;
  return (
    <Link className={className} to={"/atendimentos/" + protocolo}>
      {children}
    </Link>
  );
}

IrParaAtendimento.defaultProps = {
  children: <>Exibir</>,
};

IrParaAtendimento.propTypes = {
  protocolo: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  className: PropTypes.string,
};
