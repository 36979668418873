import "./TabelaListaPessoa.css";

import { Button } from "reactstrap";

import DataFormatada from "../../DataFormatada";
import { MdOutlineArrowForward } from "react-icons/md";
import React from "react";
import useWindowDimensions from "../../../Utils/getWindowDimension";

function formataCPF(cpf) {
  cpf = cpf.replace(/[^\d]/g, "");
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}

function TabelaListaPessoa(props) {
  const { width } = useWindowDimensions();
  const mobile = width > 768 ? false : true;

  return (
    <table className="ui paded table" style={{ marginTop: "20px" }}>
      <thead>
        <tr>
          <th>Código</th>
          <th>Mat. sindical</th>
          <th>Nome</th>
          <th>CPF</th>
          <th>Dt. nascimento</th>
          <th>Nome empresa</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {!props.dados
          ? null
          : props.dados.map((dado, index) => {
              return (
                <tr key={index}>
                  <th scope="row">{dado.id}</th>
                  <td>{dado.sindical ? dado.sindical : "-"}</td>
                  <td>{dado.nome}</td>
                  <td>{dado.cpf ? formataCPF(dado.cpf) : "-"}</td>
                  <td>{<DataFormatada data={dado.dataNascimento} formato="DD/MM/YYYY" />}</td>
                  <td>{dado.empresa_razaoSocial ? dado.empresa_razaoSocial : "-"}</td>
                  <td>
                    <Button
                      size="sm"
                      type="button"
                      data-testid={`botao-acao-${index}`}
                      style={mobile ? { width: "50%" } : {}}
                      className={mobile || props.novoAtendimento ? "primary-button table-action-button" : ""}
                      color={!mobile || !props.novoAtendimento ? "link" : null}
                      onClick={() => props.onSelect(dado.id)}
                    >
                      {props.novoAtendimento ? (
                        <>Atender</>
                      ) : mobile ? (
                        <>
                          Ir para pessoa <MdOutlineArrowForward style={{ marginLeft: 5 }} />
                        </>
                      ) : (
                        <MdOutlineArrowForward />
                      )}
                    </Button>
                  </td>
                </tr>
              );
            })}
        {!props.dados || props.dados?.length === 0 ? (
          <tr>
            <td colSpan="7">Nada para exibir</td>
          </tr>
        ) : null}
      </tbody>
    </table>
  );
}

export default TabelaListaPessoa;
