import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import { useFetchCategoriasAtendimentos, useFetchStatusAtendimentos } from "../../api/base";
import { useFetchUsuarioAtivo } from "../../api/usuarioAtivo";
import Carregando from "../../app/Carregando";
import HeaderCardBody from "../../app/HeaderCardBody";
import Donut from "./ApexChart";
import "./Home.css";

export default function Home() {
  const { status, getStatus, carregandoStatus } = useFetchStatusAtendimentos();
  const { categorias, getCategorias, carregandoCategorias } = useFetchCategoriasAtendimentos();
  const [usuarioAtivo, carregandoUsuarioAtivo] = useFetchUsuarioAtivo();

  const history = useHistory();

  let atendimentosAbertos = [];
  let totalAtendimentos = 0;
  let totalEmAtendimento = 0;
  let totalAguardandoAtendimento = 0;
  let categoriaMaisAtendimentos = "";

  useEffect(() => {
    getStatus();
    getCategorias();
  }, [getStatus, getCategorias]);

  const handleRedirectAtendimento = (status, usuario) => {
    return history.push({
      pathname: "/proximos-atendimentos",
      state: { status, usuario: { ...usuario } },
    });
  };

  const handleNovoAtendimento = () => {
    history.push("/novo-atendimento");
  };

  for (let i = 0; i < categorias.length; i++) {
    if (categorias[i]?.qtd > categorias[i + 1]?.qtd) categoriaMaisAtendimentos = categorias[i]?.descricao;
  }

  for (let i = 0; i < status.length; i++) {
    totalAtendimentos += status[i].qtd;
    if (status[i].descricao === "Em atendimento") totalEmAtendimento = status[i].qtd;
    if (status[i].descricao === "Aguardando atendimento") totalAguardandoAtendimento = status[i].qtd;
    if (status[i].descricao !== "Atendimento concluído" && status[i].descricao !== "Encerrado") {
      atendimentosAbertos.push(status[i]);
    }
  }

  return (
    <Card>
      <HeaderCardBody
        titulo="Home"
        tituloBotao="+ Novo Atendimento"
        onBotaoAcoes={handleNovoAtendimento}
        mostraBotao={true}
      />
      <CardBody className="pb-5">
        <Carregando carregando={carregandoStatus || carregandoCategorias || carregandoUsuarioAtivo}>
          <Row>
            <Col md={6} sm={12}>
              <div className="titulo-secao">
                <h3>Meus atendimentos em aberto</h3>
              </div>
              <div className="w-75 h-100 mx-auto mt-5">
                {categorias && status ? (
                  <Donut
                    dados={atendimentosAbertos}
                    goToAtendimentos={handleRedirectAtendimento}
                    usuarioAtivo={usuarioAtivo}
                  />
                ) : (
                  "Não foi possível buscar os atendimentos..."
                )}
              </div>
            </Col>
            <Col md={6} sm={12}>
              <div className="titulo-secao">
                <h3>Estatísticas gerais</h3>
              </div>
              <Row className="g-3 container mt-3">
                {categorias && status ? (
                  <>
                    <CardEstatisticasGerais titulo="Atendimentos iniciados" texto={totalEmAtendimento} />
                    <CardEstatisticasGerais titulo="Aguardando atendimento" texto={totalAguardandoAtendimento} />
                    <CardEstatisticasGerais titulo="Total de atendimentos" texto={totalAtendimentos} />
                    <CardEstatisticasGerais
                      titulo="Categoria com mais atendimentos"
                      mostraSeparador={false}
                      texto={categoriaMaisAtendimentos}
                    />
                  </>
                ) : (
                  "Não foi possível buscar as estatísticas gerais..."
                )}
              </Row>
            </Col>
          </Row>
        </Carregando>
      </CardBody>
    </Card>
  );
}

function CardEstatisticasGerais({ titulo, mostraSeparador = true, texto }) {
  return (
    <Col md={6} sm={6} className="text-center">
      <div className="w-100 p-2 card-estatisticas">
        <div className="w-100 h-50 p-2 text-left">
          <span>{titulo}</span>
        </div>
        <div className="w-100 h-50 p-2">
          <div className="w-100 h-100 d-flex">
            {mostraSeparador && <div className="h-50 separator"></div>}
            <div className="w-100 h-100 d-flex numero-card text-left">
              <h3 style={texto.length > 3 ? { fontSize: "30px" } : { fontSize: "40px" }}>{texto}</h3>
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
}
