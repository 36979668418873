import { ListGroup, ListGroupItem, ListGroupItemHeading } from "reactstrap";

import DataFormatada from "../../app/DataFormatada";
import { MdChatBubble } from "react-icons/md";
import React from "react";
import _ from "lodash";
import styled from "styled-components";

const BadgeComentario = styled.div`
  background: #263a8a;
  border-radius: 100%;
  color: #fdfdfd;
  font-size: 1.2rem;
  width: 40px;
  height: 40px;
  text-align: center;
`;

export default function Comentarios({ historicos }) {
  return _.isEmpty(historicos) ? (
    <div className="wrap">
      <div className="links">
        <small>nenhum comentário</small>
      </div>
    </div>
  ) : (
    <div>
      <ListGroup className="historicoAtendimento">
        {historicos?.map((historico, i) =>
          historico.comentario ? (
            <ListGroupItem key={i}>
              <ListGroupItemHeading tag="div">
                <div className="clearfix">
                  <div className="float-left mr-2">
                    <BadgeComentario>
                      <MdChatBubble className="mt-2" />
                    </BadgeComentario>
                  </div>
                  <div className="float-left" style={{ lineHeight: "1" }}>
                    <span style={{ fontSize: "0.9em" }}>
                      Comentado por
                      <strong> {historico.usuarioAlteracao?.nome || historico.usuarioAlteracao?.email}</strong>
                    </span>
                    <br />
                    <small>
                      <DataFormatada relativo data={historico.dataHoraInclusao} />
                    </small>
                  </div>
                </div>
              </ListGroupItemHeading>
              <div className="pl-5">
                {historico.comentario ? (
                  <i style={{ fontSize: "0.9em", whiteSpace: "break-spaces" }}>❝{historico.comentario}❞</i>
                ) : null}
              </div>
            </ListGroupItem>
          ) : null
        )}
      </ListGroup>
    </div>
  );
}
