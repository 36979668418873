import React, { useEffect } from "react";

import Carregando from "../../app/Carregando";
import DataFormatada from "../../app/DataFormatada";
import EditarResponsavel from "../../app/EditarResponsavel";
import IrParaAtendimento from "../../app/IrParaAtendimento";
import Pagination from "react-js-pagination";
import Protocolo from "../../app/Protocolo";
import StatusAtendimento from "../../app/StatusAtendimento";
import { Button, Table } from "reactstrap";
import { useFetchAtendimentos } from "../../api/base";
import { useLocation } from "react-router-dom";

export default function ListaAtendimentos({ pessoaId, empresaId }) {
  const { atendimentos, get, carregando } = useFetchAtendimentos();
  const location = useLocation();

  const carregaDados = (novaPagina) => get(novaPagina, { idPessoa: pessoaId, idEmpresa: empresaId });

  useEffect(() => get(1, { idPessoa: pessoaId, idEmpresa: empresaId }), [get, pessoaId, empresaId]);

  return (
    <Carregando carregando={carregando}>
      <Table responsive hover>
        <thead>
          <tr>
            <th>Protocolo</th>
            <th>Motivo</th>
            <th>Status</th>
            <th>Criado em</th>
            <th>Concluído em</th>
            <th>Responsável</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {atendimentos?._embedded?.map((atendimento, index) => {
            return (
              <tr key={index}>
                <th scope="row">
                  <IrParaAtendimento protocolo={atendimento.protocolo}>
                    <Protocolo protocolo={atendimento.protocolo} />
                  </IrParaAtendimento>
                </th>
                <td>{atendimento.motivo.descricao}</td>
                <td>
                  <StatusAtendimento status={atendimento.status} />
                </td>
                <td>
                  <DataFormatada data={atendimento.dataHoraInclusao} />
                </td>
                <td>
                  <DataFormatada data={atendimento.dataHoraConclusao} />
                </td>
                <td>
                  <EditarResponsavel atendimento={atendimento} />
                </td>
                <td>
                  <Button
                    className="primary-button"
                    type="button"
                    style={{ width: "10px" }}
                    tag={IrParaAtendimento}
                    protocolo={atendimento.protocolo}
                    origem={location.pathname}
                  >
                    Visualizar
                  </Button>{" "}
                </td>
              </tr>
            );
          })}
          {(!atendimentos || atendimentos?._embedded?.length === 0) && !carregando ? (
            <tr>
              <td colSpan="7">Nenhum atendimento encontrado para essa {empresaId ? "empresa." : "pessoa."}</td>
            </tr>
          ) : null}
        </tbody>
      </Table>
      {atendimentos && atendimentos?._embedded?.length > 0 ? (
        <Pagination
          activePage={atendimentos.page}
          itemsCountPerPage={10}
          totalItemsCount={atendimentos.total_items}
          pageRangeDisplayed={5}
          onChange={carregaDados}
          itemClass="page-item"
          linkClass="page-link"
        />
      ) : null}
    </Carregando>
  );
}
