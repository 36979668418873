import { notificacaoErro } from "../app/notificacao";

async function useFetchNovoAtendimento(token, body) {
  const url = new URL(process.env.REACT_APP_API_URL + "/atendimentos");
  const init = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(body),
  };

  const fet = await fetch(url, init);

  switch (fet.status) {
    case 201:
      return { success: true, status: fet.status, data: await fet.json() };
    case 404:
      return {
        success: false,
        status: fet.status,
        error: notificacaoErro(fet, init, "Não foi possível criar o atendimento."),
      };
    default:
      return {
        success: false,
        status: fet.status,
        error: notificacaoErro(fet, init, "Ocorreu um erro inesperado na criação do atendimento"),
      };
  }
}

const novoAtendimento = { useFetchNovoAtendimento };

export default novoAtendimento;
