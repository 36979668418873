import React, { useEffect, useImperativeHandle } from "react";
import { useHistory } from "react-router-dom";
import { useFetchCategoria } from "../../../api/base";
import BotaoAcoes from "../../../app/BotaoAcoes";
import Carregando from "../../../app/Carregando";
import ListaMotivos from "../../../app/ListaMotivos";
import useWindowDimensions from "../../../Utils/getWindowDimension";
import "./Categorias.css";
import EditarCategoria from "./EditarCategoria";
import NovaCategoria from "./NovaCategoria";

export default function Categorias({ referencia }) {
  const [openMotivos, setOpenMotivos] = React.useState(false);
  const [dadosModalMotivos, setDadosModalMotivos] = React.useState({ subTitulo: "", queryValor: null });
  const [openNovaCategoria, setOpenNovaCategoria] = React.useState(false);
  const [openEditaCategoria, setOpenEditaCategoria] = React.useState(false);
  const [dadosModalEditaCategoria, setDadosEditaCategoria] = React.useState({});

  const history = useHistory();

  const { width } = useWindowDimensions();
  const mobile = width > 768 ? false : true;

  const { categorias, get, carregando } = useFetchCategoria();

  useEffect(() => {
    get();
  }, [get]);

  const handleVerMotivo = (categoriaId) => {
    return history.push({
      pathname: "/motivos",
      state: {
        filtro: {
          categoria: categoriaId,
        },
      },
    });
  };

  const handleOpenModalMotivos = (categoria) => {
    setDadosModalMotivos({ subTitulo: categoria.descricao, queryValor: categoria.id });
    setOpenMotivos(true);
  };
  const handleOpenModalEditaCategoria = (categoria) => {
    setDadosEditaCategoria(categoria);
    setOpenEditaCategoria(true);
  };

  useImperativeHandle(referencia, () => {
    return {
      setOpenModal: () => setOpenNovaCategoria(true),
    };
  });

  return (
    <>
      <Carregando carregando={carregando}>
        <table className="ui very basic table" style={{ marginTop: "20px" }}>
          <thead>
            <tr>
              <th className={`tableHead ${mobile ? "" : "center aligned"}`}>Descrição</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(categorias) &&
              categorias?.map((categoria) => {
                return (
                  <tr key={categoria.id}>
                    <th scope="row" className={mobile ? "" : "center aligned"}>
                      {categoria.descricao}
                    </th>
                    <td className={mobile ? "" : "center aligned"}>
                      <BotaoAcoes
                        classNameSpan="textoBotao"
                        styledesktop={styleDesktopButtonTable}
                        styleglobal={styleGlobal}
                        onClick={() => handleOpenModalEditaCategoria(categoria)}
                      >
                        Editar Categoria
                      </BotaoAcoes>
                    </td>
                    <td className={mobile ? "" : "center aligned"}>
                      <BotaoAcoes
                        data-testid={`ver-motivos-${categoria.id}`}
                        classNameSpan="textoBotao"
                        styleglobal={styleGlobal}
                        styledesktop={styleDesktopButtonTable}
                        onClick={() => handleOpenModalMotivos(categoria)}
                      >
                        Ver motivos
                      </BotaoAcoes>
                    </td>
                  </tr>
                );
              })}
            {(!categorias || categorias?.length === 0) && (
              <tr>
                <td colSpan="7">Nada para exibir</td>
              </tr>
            )}
          </tbody>
        </table>
      </Carregando>
      {openMotivos && (
        <ListaMotivos
          open={openMotivos}
          setOpen={setOpenMotivos}
          titulo={"Categoria:"}
          subTitulo={dadosModalMotivos.subTitulo}
          queryNome="categoria"
          queryValor={dadosModalMotivos.queryValor}
          onVerMotivo={handleVerMotivo}
        />
      )}
      {openEditaCategoria && (
        <EditarCategoria
          open={openEditaCategoria}
          setOpen={setOpenEditaCategoria}
          categoria={dadosModalEditaCategoria}
          reload={get}
        />
      )}
      <NovaCategoria open={openNovaCategoria} setOpen={setOpenNovaCategoria} reload={get} />
    </>
  );
}

const styleGlobal = {
  position: "relative",
};
const styleDesktopButtonTable = {
  width: "150px",
};
