import { Button, Collapse, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import { FaDoorClosed, FaDoorOpen } from "react-icons/fa";
import { MdPlayCircleFilled, MdStopCircle } from "react-icons/md";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import PropTypes from "prop-types";
import { notificacaoErro } from "../../app/notificacao";
import { verificaLogin } from "../../Components/Auth/authSlice";

function useAtualizaStatus(acao) {
  const logged = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [estado, setEstado] = useState(false);

  const alterar = useCallback(
    (protocolo, comentario) => {
      setEstado({
        carregando: true,
        salvo: false,
      });
      const url = new URL(process.env.REACT_APP_API_URL + "/atendimento/" + protocolo + "/" + acao);

      const init = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + logged.token,
        },
        body: JSON.stringify({ comentario }),
      };
      fetch(url, init)
        .then((fet) => {
          switch (fet.status) {
            case 201:
              return fet.json();
            case 401:
              dispatch(verificaLogin(false));
              setEstado({
                carregando: false,
                salvo: false,
              });
              return notificacaoErro(fet, init, "Ocorreu um erro no atendimento 401");
            case 404:
              setEstado({
                carregando: false,
                salvo: false,
              });
              return notificacaoErro(fet, init, "Ocorreu um erro no atendimento 404");
            default:
              setEstado({
                carregando: false,
                salvo: false,
              });
              return notificacaoErro(fet, init, "Ocorreu um erro inesperado.");
          }
        })
        .then((json) => {
          setEstado({
            carregando: false,
            salvo: true,
            dados: json,
          });
        });
    },
    [dispatch, logged, acao]
  );

  return { alterar, estado };
}

function CollapseComentario({ obrigatorio, onChange }) {
  const [isOpen, setIsOpen] = useState(false || obrigatorio);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div className="clearfix mt-2">
      {obrigatorio ? null : (
        <Button className="float-right" size="sm" color="link" onClick={toggle}>
          adicionar comentário
        </Button>
      )}
      <Collapse isOpen={isOpen}>
        <Label for="comentario">Comentário</Label>
        <Input
          onChange={(e) => onChange(e.target.value)}
          required={true}
          type="textarea"
          id="comentario"
          name="comentario"
        />
      </Collapse>
    </div>
  );
}

const ModalComentario = ({ acao, toggle, isOpen, obrigaComentario, onClickConfirmar, onChangeComentario }) => {
  const clickConfirmar = () => {
    onClickConfirmar();
    toggle();
  };
  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Alterar status do atendimento</ModalHeader>
        <ModalBody>
          Tem certeza que deseja {acao} atendimento?
          <CollapseComentario onChange={onChangeComentario} obrigatorio={obrigaComentario} />
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={toggle}>
            Cancelar
          </Button>{" "}
          <Button color="success" onClick={clickConfirmar}>
            Confirmar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

ModalComentario.defaultProps = {
  obrigaComentario: false,
};

export function BotaoDefault({
  atendimento,
  acaoApi,
  acaoTexto,
  cor,
  textoBotao,
  icone,
  obrigaComentario,
  onChangeAtendimento,
}) {
  const { alterar, estado } = useAtualizaStatus(acaoApi);
  const [comentario, setComentario] = useState();
  const confirmaAcao = () => alterar(atendimento.protocolo, comentario);
  const changeComentario = (novoComentario) => setComentario(novoComentario);

  const [openModal, setOpenModal] = useState(false);
  const toggle = () => setOpenModal(!openModal);
  const Icon = icone;

  if (estado) {
    onChangeAtendimento(estado);
  }

  return (
    <div>
      <ModalComentario
        obrigaComentario={obrigaComentario}
        onChangeComentario={changeComentario}
        onClickConfirmar={confirmaAcao}
        acao={acaoTexto}
        toggle={toggle}
        isOpen={openModal}
      />
      <Button disabled={estado.carregando} className="mt-3 d-block" size="md" color={cor} onClick={toggle}>
        {estado.carregando ? <Spinner size="sm" /> : <Icon />} {textoBotao}
      </Button>
    </div>
  );
}

BotaoDefault.defaultProps = {
  obrigaComentario: false,
};

BotaoDefault.propTypes = {
  atendimento: PropTypes.object.isRequired,
  acaoApi: PropTypes.string.isRequired,
  acaoTexto: PropTypes.string.isRequired,
  cor: PropTypes.string.isRequired,
  textoBotao: PropTypes.string.isRequired,
  icone: PropTypes.func.isRequired,
  obrigaComentario: PropTypes.bool,
  onChangeAtendimento: PropTypes.func,
};

export function BotaoIniciar({ atendimento, onChangeAtendimento }) {
  return atendimento?.status?.id === 1 ? (
    <BotaoDefault
      atendimento={atendimento}
      acaoApi="iniciar"
      acaoTexto="iniciar"
      cor="success"
      textoBotao="Iniciar atendimento"
      icone={MdPlayCircleFilled}
      onChangeAtendimento={onChangeAtendimento}
    />
  ) : (
    <></>
  );
}

export function BotaoParar({ atendimento, onChangeAtendimento }) {
  return atendimento?.status?.id === 2 ? (
    <BotaoDefault
      atendimento={atendimento}
      acaoApi="parar"
      acaoTexto="parar"
      cor="warning"
      textoBotao="Parar atendimento"
      icone={MdStopCircle}
      onChangeAtendimento={onChangeAtendimento}
    />
  ) : (
    <></>
  );
}

export function BotaoConcluir({ atendimento, onChangeAtendimento }) {
  return [1, 2].includes(atendimento?.status?.id) ? (
    <BotaoDefault
      atendimento={atendimento}
      acaoApi="concluir"
      acaoTexto="concluir"
      cor="dark"
      textoBotao="Concluir atendimento"
      icone={FaDoorClosed}
      obrigaComentario={true}
      onChangeAtendimento={onChangeAtendimento}
    />
  ) : (
    <></>
  );
}

export function BotaoReabrir({ atendimento, onChangeAtendimento }) {
  return atendimento?.status?.id === 4 ? (
    <BotaoDefault
      atendimento={atendimento}
      acaoApi="reabrir"
      acaoTexto="reabrir"
      cor="primary"
      textoBotao="Reabrir atendimento"
      icone={FaDoorOpen}
      obrigaComentario={true}
      onChangeAtendimento={onChangeAtendimento}
    />
  ) : (
    <></>
  );
}

export function GrupoBotoes({ atendimento, onChangeAtendimento }) {
  return (
    <div>
      <BotaoIniciar atendimento={atendimento} onChangeAtendimento={onChangeAtendimento} />
      <BotaoParar atendimento={atendimento} onChangeAtendimento={onChangeAtendimento} />
      <BotaoConcluir atendimento={atendimento} onChangeAtendimento={onChangeAtendimento} />
      <BotaoReabrir atendimento={atendimento} onChangeAtendimento={onChangeAtendimento} />
    </div>
  );
}
