import { Button, Collapse } from 'reactstrap';
import React, { useState } from 'react';

import _ from 'lodash';
import { MdArrowDropDownCircle } from 'react-icons/md';

const PegaTexto = (value) => {
    if (!value) return "N/D"
    if (typeof (value) !== 'object') return value
    if ('nome' in value) {
        if (_.isEmpty(value['nome']) && 'email' in value) return value['email']
        else return value['nome']
    }
    else if ('descricao' in value) return value['descricao']
    else return "N/D"
}

export default function DeParaCampos({ camposAlterados }) {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <div>
            {_.isEmpty(camposAlterados)
                ? null
                : <div className="clearfix">
                    <Button size="sm" color="link" className="float-right btn-coment" onClick={toggle}>Ver alterações<MdArrowDropDownCircle className='ml-1' style={{fontSize: '1.2rem'}}/></Button>
                </div>}
            <Collapse isOpen={isOpen}>
                <ul>
                    {camposAlterados?.map((obj, i) => (
                        <li key={i}>
                            <strong>{obj.campo}</strong> alterado{" "}
                            <strong>de</strong> {PegaTexto(obj.de)}{" "}
                            <strong>para</strong> {PegaTexto(obj.para)}
                        </li>
                    ))}
                </ul>
            </Collapse>
        </div>
    );
}
