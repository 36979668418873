import { Button, Input, InputGroup, InputGroupText, Spinner } from "reactstrap";
import { MdBlock, MdCheckCircleOutline, MdEdit } from "react-icons/md";
import { useEffect, useState } from "react";

import _ from "lodash";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useFetchUsuarios } from "../../api/base";
import { useUpdate } from "../../api/atendimento";

const ButtonResponsavelEditavel = styled(Button)`
  border-bottom: 1px dashed #c3950b;
  display: inline-flex;
  padding: 0 !important;
  color: inherit;
  &:hover {
    border-bottom-color: #8c6d12;
    text-decoration: none;
  }
  &:hover svg {
    color: #8c6d12;
  }
`;

const FaPencilAltEdit = styled(MdEdit)`
  color: #c3950b;
  margin-left: 5px;
`;

const SelectResponsavel = ({ toggle, atendimento }) => {
  const [responsavel, setResponsavel] = useState(atendimento?.responsavel?.id);
  const { save, estado } = useUpdate();
  const { usuarios, get, carregando } = useFetchUsuarios(atendimento.motivo.departamento);

  useEffect(() => {
    get();
  }, [get]);

  const onSubmit = () => {
    if (_.isEmpty(responsavel)) {
      return toggle();
    }
    let atendimentoAlterado = JSON.parse(JSON.stringify(atendimento));
    atendimentoAlterado.meioAtendimento = atendimentoAlterado.meioAtendimento.id;
    atendimentoAlterado.motivo = atendimentoAlterado.motivo.id;
    atendimentoAlterado.status = atendimentoAlterado.status.id;
    atendimentoAlterado.pessoa = atendimentoAlterado.pessoa?.id;
    atendimentoAlterado.empresa = atendimentoAlterado.empresa?.id;
    atendimentoAlterado.dataHoraInclusao = atendimentoAlterado.dataHoraInclusao.date;
    atendimentoAlterado.responsavel = responsavel;

    save(atendimentoAlterado);
  };

  if (estado.salvo) {
    toggle();
    atendimento.responsavel = usuarios.filter((r) => r.id === parseInt(responsavel))[0];
    toast.success("Responsável alterado com sucesso");
  }

  return (
    <InputGroup size="sm">
      {carregando || estado.carregando ? (
        <InputGroup addonType="prepend">
          <InputGroupText>
            <Spinner size="sm" />
          </InputGroupText>
        </InputGroup>
      ) : null}
      <Input
        disabled={carregando || estado.carregando}
        type="select"
        value={responsavel}
        onChange={(e) => setResponsavel(e.target.value)}
      >
        <option></option>
        {_.isEmpty(usuarios)
          ? null
          : usuarios?.map((usuario, i) => (
              <option key={i} value={usuario.id}>
                {usuario.nome || usuario.email}
              </option>
            ))}
      </Input>
      <InputGroup addonType="append">
        <Button disabled={carregando || estado.carregando} color="danger" onClick={toggle}>
          <MdBlock />
        </Button>
        <Button disabled={carregando || estado.carregando} color="success" onClick={onSubmit}>
          <MdCheckCircleOutline />
        </Button>
      </InputGroup>
    </InputGroup>
  );
};

export default function EditarResponsavel({ atendimento, departamento }) {
  const [editando, setEditando] = useState(false);
  const nomeExibicao = atendimento?.responsavel?.nome || atendimento?.responsavel?.email || "- não atribuído -";

  if ([3, 4].includes(atendimento?.status?.id)) {
    return <span>{nomeExibicao}</span>;
  }

  return (
    <div>
      {editando ? (
        <SelectResponsavel atendimento={atendimento} toggle={() => setEditando(false)} />
      ) : (
        <ButtonResponsavelEditavel onClick={() => setEditando(true)} size="sm" color="link">
          {nomeExibicao} <FaPencilAltEdit />
        </ButtonResponsavelEditavel>
      )}
    </div>
  );
}
