import React from "react";
import { useSelector } from "react-redux";
import { Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { toast } from "react-toastify";
import BotaoAcoes from "../../../app/BotaoAcoes";
import UsuarioSelect from "../../../app/UsuarioSelect";
import useWindowDimensions from "../../../Utils/getWindowDimension";
import Carregando from "../../../app/Carregando";

import criaDepartamento from "../../../api/criaDepartamento";
import atualizaUsuario from "../../../api/usuarioPut";

import "./Departamentos.css";

export default function NovoDepartamento({ open, setOpen, reload }) {
  const [nome, setNome] = React.useState("");
  const [responsavel, setResponsavel] = React.useState({});
  const [usuariosDepartamento, setUsuariosDepartamento] = React.useState([]);
  const [dadosDepartamentoCriado, setDadosDepartamentoCriado] = React.useState({});

  const [departamentoCriado, setDepartamentoCriado] = React.useState(false);
  const [criaDepartamentoCarregando, setCriaDepartamentoCarregando] = React.useState(false);
  const [atualizaUsuarioCarregando, setAtualizaUsuarioCarregando] = React.useState(false);

  const token = useSelector((state) => state.auth.token);

  const { width } = useWindowDimensions();
  const mobile = width > 768 ? false : true;

  const handleCloseModal = () => {
    setNome("");
    setResponsavel({});
    setUsuariosDepartamento([]);
    setDepartamentoCriado(false);
    setOpen(false);
    reload()
  };

  const handleCriaDepartamento = () => {
    if (!nome) return toast.warning("Por favor preencha o nome do departamento.");
    setCriaDepartamentoCarregando(true);
    criaDepartamento
      .fetchCriaDepartamento(nome, token)
      .then((res) => {
        if (!res.success) return res.error;
        toast.success("Departamento criado com sucesso");
        setDepartamentoCriado(true);
        setCriaDepartamentoCarregando(false);
        setDadosDepartamentoCriado(res.data);
      })
      .finally(() => setCriaDepartamentoCarregando(false));
  };

  const handleRemoveUsuario = (usuario) => {
    setAtualizaUsuarioCarregando(true);
    let body = usuario;
    const departamentoFiltrado = usuario.departamentos.filter(
      (departamento) => departamento.id !== dadosDepartamentoCriado.id
    );
    body.departamentos = departamentoFiltrado;
    atualizaUsuario.fetchAtualizaUsuario(body, token, body.id).then((res) => {
      if (!res.success) return res.error;

      const usuariosFiltrado = usuariosDepartamento.filter((usuarioFiltrer) => usuarioFiltrer.id !== usuario.id);
      setUsuariosDepartamento(usuariosFiltrado);
      setResponsavel({});
      setAtualizaUsuarioCarregando(false);
      return;
    });
  };

  const handleAdicionar = () => {
    if (!responsavel.id) return;
    const usuarioRepetido = usuariosDepartamento.filter((usuario) => {
      return usuario.id === responsavel.id;
    });
    if (usuarioRepetido.length > 0) {
      return toast.warning("Não é possível adicionar o mesmo usuário");
    }
    setAtualizaUsuarioCarregando(true);
    let body = responsavel;
    body.departamentos.push(dadosDepartamentoCriado);
    atualizaUsuario
      .fetchAtualizaUsuario(body, token, responsavel.id)
      .then((res) => {
        if (!res.success) return res.error;
        setAtualizaUsuarioCarregando(false);
        setUsuariosDepartamento([...usuariosDepartamento, responsavel]);
      })
      .finally(() => {
        setAtualizaUsuarioCarregando(false);
      });
  };

  return (
    <>
      <Modal isOpen={open} toggle={() => setOpen(!open)} size="lg">
        <ModalHeader toggle={() => setOpen(!open)}>
          <span className="modalHeader">Novo Departamento</span>
        </ModalHeader>
        <ModalBody>
          <Form>
            <Carregando carregando={criaDepartamentoCarregando}>
              <Row>
                <div data-testid="secao-desativa-1" className={departamentoCriado ? "secao-desativa" : ""}>
                  <Col md={12} xs={12}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FormGroup style={{ width: "70%" }}>
                        <Label for="nome">Nome do departamento</Label>
                        <Input
                          id="nome"
                          data-testid="nome"
                          required
                          name="nome"
                          type="text"
                          value={nome}
                          disabled={departamentoCriado}
                          onChange={(e) => setNome(e.target.value)}
                        />
                      </FormGroup>
                      <BotaoAcoes
                        data-testid="button-criar"
                        disabled={departamentoCriado}
                        onClick={handleCriaDepartamento}
                        styleglobal={{ ...styleGlobalAdicionar, width: "20%" }}
                      >
                        Criar
                      </BotaoAcoes>
                    </div>
                  </Col>
                </div>
              </Row>
            </Carregando>
            <hr className="divisor-cria-departamento" />
            <div data-testid="secao-desativa-2" className={!departamentoCriado ? "secao-desativa" : ""}>
              <Row>
                <span className="adiciona-usuario">
                  {departamentoCriado && `Adicione usuários ao departamento: ${nome}`}
                </span>
                <Col md={6} xs={12}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FormGroup style={mobile ? { width: "50%" } : {}}>
                      <Label for="usuario">Novo usuário</Label>
                      <UsuarioSelect
                        data-testid="select-usuario"
                        disabled={!departamentoCriado}
                        onChange={(e) => setResponsavel(e)}
                        value={responsavel}
                      />
                    </FormGroup>
                    <BotaoAcoes
                      data-testid="button-adicionar"
                      disabled={!departamentoCriado}
                      onClick={handleAdicionar}
                      styleglobal={styleGlobalAdicionar}
                    >
                      Adicionar
                    </BotaoAcoes>
                  </div>
                </Col>
              </Row>
              <Carregando carregando={atualizaUsuarioCarregando}>
                <table className="ui very basic table">
                  {usuariosDepartamento.length > 0 && (
                    <thead>
                      <tr>
                        <th className="secondaryText">Usuário</th>
                        <th className="secondaryText">Nome</th>
                        <th className="secondaryText"></th>
                      </tr>
                    </thead>
                  )}
                  <tbody>
                    {usuariosDepartamento.length > 0 &&
                      usuariosDepartamento.map((usuario, index) => {
                        return (
                          <tr key={index}>
                            <td>{usuario.email}</td>
                            <td>{usuario.nome}</td>
                            <td>
                              <div
                                data-testid={"button-remove-" + usuario.id}
                                className="remove-usuario"
                                onClick={() => handleRemoveUsuario(usuario)}
                              >
                                Remover
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </Carregando>
            </div>
          </Form>
        </ModalBody>
        <ModalFooter>
          <BotaoAcoes styleglobal={styleGlobal} reversecolor={true} onClick={handleCloseModal}>
            {departamentoCriado ? "Concluir" : "Voltar"}
          </BotaoAcoes>
        </ModalFooter>
      </Modal>
    </>
  );
}

const styleGlobal = { position: "relative", width: "120px" };

const styleGlobalAdicionar = {
  position: "relative",
  width: "100px",
  height: "38px",
  marginLeft: 20,
  marginTop: 10,
};
