export default function preparaAtendimento(id, tipo, nome, bodyInicial, empresa) {
  let body = {};
  if (tipo === "pessoa-anonima" || tipo === "pessoa-nao-cadastrada") {
    body.tipo = "pessoa";
    if (empresa.id) body.empresaAtendidoId = empresa.id;
    else body.empresaAtendidoNome = empresa.razaoSocial;
  }
  if (nome) {
    if (tipo === "pessoa-nao-cadastrada") {
      body.tipo = "pessoa";
      body.nomeAtendido = nome;
    } else {
      body.tipo = "empresa";
      body.nomeAtendido = nome;
    }
  } else if (tipo === "pessoa-fisica") {
    body.pessoaId = id;
    body.tipo = "pessoa";
  } else if (tipo === "empresa") {
    body.empresaId = id;
    body.tipo = "empresa";
  }
  return Object.assign(body, bodyInicial);
}
