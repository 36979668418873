import React from "react";
import { useSelector } from "react-redux";
import { Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { toast } from "react-toastify";
import BotaoAcoes from "../../../app/BotaoAcoes";
import UsuarioSelect from "../../../app/UsuarioSelect";
import useWindowDimensions from "../../../Utils/getWindowDimension";
import Carregando from "../../../app/Carregando";
import { MdModeEdit, MdClose } from "react-icons/md";

import atualizaUsuario from "../../../api/usuarioPut";
import { useFetchUsuarios } from "../../../api/base";
import atualizaDepartamento from "../../../api/departamentoPut";

import "./Departamentos.css";

export default function EditarDepartamento({ open, setOpen, departamento, reload }) {
  const [nome, setNome] = React.useState(departamento.nome);
  const [responsavel, setResponsavel] = React.useState({});
  const [editando, setEditando] = React.useState(false);
  const [departamentoEditado, setDepartamentoEditado] = React.useState(null);
  const [editaDepartamentoCarregando, setEditaDepartamentoCarregando] = React.useState(false);
  const [atualizaUsuarioCarregando, setAtualizaUsuarioCarregando] = React.useState(false);

  const { usuarios, get, carregandoUsuarios } = useFetchUsuarios(departamento.id);

  React.useEffect(() => {
    get();
  }, [get]);

  const token = useSelector((state) => state.auth.token);

  const { width } = useWindowDimensions();
  const mobile = width > 768 ? false : true;

  const handleCloseModal = () => {
    setNome("");
    setResponsavel({});
    setOpen(false);
    reload();
  };

  const handleEditaDepartamento = () => {
    setEditaDepartamentoCarregando(true);
    atualizaDepartamento
      .fetchAtualizaDepartamento(nome, token, departamento.id)
      .then((res) => {
        if (!res.success) return res.error;
        setDepartamentoEditado(res.data.nome);
        toast.success("Departamento atualizado com sucesso!");
        setEditaDepartamentoCarregando(false);
        setEditando(!editando);
      })
      .finally(() => {
        setEditaDepartamentoCarregando(false);
        setEditando(!editando);
      });
  };

  const handleRemoveUsuario = (usuario) => {
    setAtualizaUsuarioCarregando(true);
    let body = usuario;
    const departamentoFiltrado = usuario.departamentos.filter(
      (departamentoFilter) => departamentoFilter.id !== departamento.id
    );
    body.departamentos = departamentoFiltrado;
    atualizaUsuario.fetchAtualizaUsuario(body, token, body.id).then((res) => {
      if (!res.success) return res.error;
      get();
      setResponsavel({});
      setAtualizaUsuarioCarregando(false);
      return;
    });
  };

  const handleAdicionar = () => {
    if (!responsavel.id) return;
    const usuarioRepetido = usuarios.filter((usuario) => {
      return usuario.id === responsavel.id;
    });
    if (usuarioRepetido.length > 0) {
      return toast.warning("Não é possível adicionar o mesmo usuário");
    }
    setAtualizaUsuarioCarregando(true);
    let body = responsavel;
    body.departamentos.push(departamento);
    atualizaUsuario
      .fetchAtualizaUsuario(body, token, responsavel.id)
      .then((res) => {
        if (!res.success) return res.error;
        get();
        setAtualizaUsuarioCarregando(false);
      })
      .finally(() => {
        setAtualizaUsuarioCarregando(false);
      });
  };

  return (
    <>
      <Modal isOpen={open} toggle={() => setOpen(!open)} size="lg">
        <ModalHeader toggle={() => setOpen(!open)}>
          <span className="modalHeader">
            Departamentos
            <span className="secondaryText subTitulo" data-testid="nome-departamento">
              {nome}
            </span>
          </span>
        </ModalHeader>
        <ModalBody>
          <Form>
            <Carregando carregando={editaDepartamentoCarregando}>
              <Row>
                <Col md={12} xs={12}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FormGroup style={{ width: "70%" }}>
                      <Label for="nome">Nome do departamento</Label>
                      <Input
                        disabled={!editando}
                        id="nome"
                        data-testid="nome"
                        required
                        name="nome"
                        type="text"
                        value={nome}
                        onChange={(e) => setNome(e.target.value)}
                      />
                    </FormGroup>
                    <BotaoAcoes
                      data-testid="button-editar"
                      onClick={() => {
                        if (editando && !departamentoEditado) {
                          setNome(departamento.nome);
                        }
                        setEditando(!editando);
                      }}
                      reversecolor={true}
                      styleglobal={styleGlobalEditar}
                    >
                      {editando ? <MdClose size={18} /> : <MdModeEdit size={18} />}
                    </BotaoAcoes>
                    <BotaoAcoes
                      disabled={!editando}
                      data-testid="button-salvar"
                      onClick={handleEditaDepartamento}
                      styleglobal={{ ...styleGlobalAdicionar, width: "15%", marginLeft: 10 }}
                    >
                      Salvar
                    </BotaoAcoes>
                  </div>
                </Col>
              </Row>
              <hr className="divisor-cria-departamento" />
              <Row>
                <span className="adiciona-usuario">{`Adicione usuários ao departamento: ${nome}`}</span>
                <Col md={6} xs={12}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FormGroup style={mobile ? { width: "50%" } : {}}>
                      <Label for="usuario">Novo usuário</Label>
                      <UsuarioSelect
                        data-testid="select-usuario"
                        onChange={setResponsavel}
                        usuarioSelecionado={responsavel}
                      />
                    </FormGroup>
                    <BotaoAcoes
                      data-testid="button-adicionar"
                      onClick={handleAdicionar}
                      styleglobal={styleGlobalAdicionar}
                    >
                      Adicionar
                    </BotaoAcoes>
                  </div>
                </Col>
              </Row>
              <Carregando carregando={atualizaUsuarioCarregando || carregandoUsuarios}>
                <table className="ui very basic table">
                  {usuarios.length > 0 && (
                    <thead>
                      <tr>
                        <th className="secondaryText">Usuário</th>
                        <th className="secondaryText">Nome</th>
                        <th className="secondaryText"></th>
                      </tr>
                    </thead>
                  )}
                  <tbody>
                    {usuarios?.length > 0 &&
                      usuarios.map((usuario, index) => {
                        return (
                          <tr key={index}>
                            <td>{usuario.email}</td>
                            <td>{usuario.nome}</td>
                            <td>
                              <div
                                data-testid={"button-remove-" + usuario.id}
                                className="remove-usuario"
                                onClick={() => handleRemoveUsuario(usuario)}
                              >
                                Remover
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    {(!usuarios || usuarios?.length === 0) && (
                      <tr>
                        <td colSpan="7">Não há usuários cadastrados...</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </Carregando>
            </Carregando>
          </Form>
        </ModalBody>
        <ModalFooter>
          <BotaoAcoes styleglobal={styleGlobal} reversecolor={true} onClick={handleCloseModal}>
            Concluir
          </BotaoAcoes>
        </ModalFooter>
      </Modal>
    </>
  );
}

const styleGlobal = { position: "relative", width: "120px" };

const styleGlobalAdicionar = {
  position: "relative",
  width: "100px",
  height: "38px",
  marginLeft: 15,
  marginTop: 10,
};
const styleGlobalEditar = {
  position: "relative",
  width: "5%",
  height: "38px",
  marginLeft: 10,
  marginTop: 10,
};
