import { AvField, AvForm } from "availity-reactstrap-validation-safe";
import React, { useEffect, useImperativeHandle } from "react";
import { useLocation } from "react-router-dom";
import { Col, FormGroup, Label, Row } from "reactstrap";
import { useFetchMotivos } from "../../../api/base";
import BotaoAcoes from "../../../app/BotaoAcoes";
import Carregando from "../../../app/Carregando";
import CategoriaSelect from "../../../app/CategoriaSelect";
import DepartamentoSelect from "../../../app/DepartamentoSelect";
import useWindowDimensions from "../../../Utils/getWindowDimension";
import EditarMotivo from "./EditarMotivo";
import "./Motivos.css";
import NovoMotivo from "./NovoMotivo";

export default function Motivos({ referencia }) {
  const [openNovoMotivo, setOpenNovoMotivo] = React.useState(false);
  const [openEditaMotivo, setOpenEditaMotivo] = React.useState(false);
  const [dadosModalEditaMotivo, setDadosEditaMotivo] = React.useState({});
  const [filtros, setFiltros] = React.useState({});

  const location = useLocation();

  const { width } = useWindowDimensions();
  const mobile = width > 768 ? false : true;

  const { motivos, get, carregando } = useFetchMotivos();

  useEffect(() => {
    if (location.state?.filtro) {
      const filtro = location.state.filtro;
      get(null, filtro);
      window.history.replaceState({}, document.title);
    } else {
      get();
    }
  }, [get, location.state?.filtro]);

  const handleOpenModalEditaDepartamento = (departamento) => {
    setDadosEditaMotivo(departamento);
    setOpenEditaMotivo(true);
  };

  useImperativeHandle(referencia, () => {
    return {
      setOpenModal: () => setOpenNovoMotivo(true),
    };
  });

  const changeCategoria = (e) => {
    setFiltros({
      ...filtros,
      categoria: parseInt(e.target.value),
    });
  };
  const changeDepartamento = (e) => {
    setFiltros({
      ...filtros,
      departamento: parseInt(e.target.value),
    });
  };
  const handleValidSubmit = () => {
    get(null, filtros);
  };

  return (
    <>
      <Carregando carregando={carregando}>
        <AvForm onValidSubmit={handleValidSubmit}>
          <Row>
            <Col md={12} xs={12}>
              <FormGroup>
                <Label for="nome">Nome do motivo</Label>
                <AvField
                  data-testid="nome"
                  onChange={(e) => {
                    setFiltros({ ...filtros, descricao: e.target.value });
                  }}
                  value={filtros.descricao}
                  type="text"
                  name="descricao"
                  id="descricao"
                  disabled={carregando}
                />
              </FormGroup>
            </Col>
            <Col md={4} xs={4}>
              <FormGroup>
                <CategoriaSelect required={false} onChange={changeCategoria} helpMessage={false} />
              </FormGroup>
            </Col>
            <Col md={4} xs={4}>
              <FormGroup>
                <DepartamentoSelect required={false} onChange={changeDepartamento} helpMessage={false} />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12} xs={12} className={mobile ? "text-center" : "text-right"} style={{ marginTop: "10px" }}>
              <BotaoAcoes
                data-testid="button-pesquisar"
                type="submit"
                disabled={carregando}
                styleglobal={{ ...styleGlobal, width: "120px" }}
              >
                Pesquisar
              </BotaoAcoes>
            </Col>
          </Row>
        </AvForm>
        <table className="ui celled table" style={{ marginTop: "20px" }}>
          <thead>
            <tr>
              <th className={`tableHead ${mobile ? "" : "center aligned"}`}>Nome</th>
              <th className={`tableHead ${mobile ? "" : "center aligned"}`}>Categoria</th>
              <th className={`tableHead ${mobile ? "" : "center aligned"}`}>Departamento</th>
              <th className={`tableHead ${mobile ? "" : "center aligned"}`}>Pode reabrir ?</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(motivos) &&
              motivos?.map((motivo) => {
                return (
                  <tr key={motivo.id}>
                    <th scope="row" className={mobile ? "" : "center aligned"}>
                      {motivo.descricao}
                    </th>
                    <td className={`tableHead ${mobile ? "" : "center aligned"}`}>{motivo.categoria.descricao}</td>
                    <td className={`tableHead ${mobile ? "" : "center aligned"}`}>{motivo.departamento.nome}</td>
                    <td className={`tableHead ${mobile ? "" : "center aligned"}`}>
                      {motivo.podeReabrir ? "Sim" : "Não"}
                    </td>
                    <td className={`tableHead ${mobile ? "" : "center aligned"}`}>
                      <BotaoAcoes
                        classNameSpan="textoBotao"
                        styledesktop={styleDesktopButtonTable}
                        styleglobal={styleGlobal}
                        onClick={() => handleOpenModalEditaDepartamento(motivo)}
                      >
                        Editar Motivo
                      </BotaoAcoes>
                    </td>
                  </tr>
                );
              })}
            {(!motivos || motivos?.length === 0) && (
              <tr>
                <td colSpan="7">Nada para exibir</td>
              </tr>
            )}
          </tbody>
        </table>
      </Carregando>
      {openEditaMotivo && (
        <EditarMotivo open={openEditaMotivo} setOpen={setOpenEditaMotivo} motivo={dadosModalEditaMotivo} reload={get} />
      )}
      <NovoMotivo open={openNovoMotivo} setOpen={setOpenNovoMotivo} reload={get} />
    </>
  );
}

const styleGlobal = {
  position: "relative",
};
const styleDesktopButtonTable = {
  width: "150px",
};
