import DataFormatada from '../../app/DataFormatada';
import { MdCheckCircleOutline } from 'react-icons/md';
import React from 'react';

const CampoIconeAtualizado = ({ texto, atualizado }) => {
    return <span title={atualizado ? "Atualizado" : null}>
        <span>{texto || "não informado"}</span>
        {" "}
        <span>{atualizado ? <MdCheckCircleOutline /> : null}</span>
    </span>

}

function formataCPF(cpf) {
    if (!cpf) return "-";
    cpf = cpf.replace(/[^\d]/g, "");
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
}

export default function DadosPessoa({ pessoa }) {
    return (
        <div>
            <dl className="row">
                <dt className="col-lg-3 text-right">Situação</dt>
                <dd className="col-lg-9">
                    {pessoa.situacao_descricao} desde <DataFormatada formato='DD/MM/YYYY' data={pessoa.dtsitu} />
                </dd>
            </dl>
            <h3>Dados pessoais</h3>
            <dl className="row">
                <dt className="col-lg-3 text-right">CPF</dt>
                <dd className="col-lg-9">{formataCPF(pessoa.cpf)}</dd>
                <dt className="col-lg-3 text-right">Dt. nascimento</dt>
                <dd className="col-lg-9"><DataFormatada formato='DD/MM/YYYY' data={pessoa.dataNascimento} /></dd>
            </dl>
            <h3>Dados de contato</h3>
            <dl className="row">
                <dt className="col-lg-3 text-right">E-mail pessoal</dt>
                <dd className="col-lg-9">
                    <CampoIconeAtualizado texto={pessoa?.emailpess} atualizado={pessoa?.emailpessatual} />
                </dd>
                <dt className="col-lg-3 text-right">E-mail institucional</dt>
                <dd className="col-lg-9">
                    <CampoIconeAtualizado texto={pessoa?.emailinst} atualizado={pessoa?.emailinstatual} />
                </dd>
                <dt className="col-lg-3 text-right">Telefone pessoal</dt>
                <dd className="col-lg-9">
                    {pessoa?.telpes || 'não informado'}
                </dd>
                <dt className="col-lg-3 text-right">Telefone profissional</dt>
                <dd className="col-lg-9">
                    {pessoa?.telprof || 'não informado'}
                </dd>
                <dt className="col-lg-3 text-right">Celular</dt>
                <dd className="col-lg-9">
                    <CampoIconeAtualizado
                        texto={pessoa?.celular ? "(" + pessoa?.celular_ddd + ") " + pessoa?.celular : "não informado"}
                        atualizado={pessoa?.celular_atua} />
                </dd>
            </dl>
            <h3>Dados profissionais</h3>
            <dl className="row">
                <dt className="col-lg-3 text-right">Empresa</dt>
                <dd className="col-lg-9">{pessoa.empresa_razaoSocial}</dd>
                <dt className="col-lg-3 text-right">Unidade</dt>
                <dd className="col-lg-9">{pessoa.unidade_razaoSocial}</dd>
                <dt className="col-lg-3 text-right">Dt. admissão</dt>
                <dd className="col-lg-9"><DataFormatada seNulo="-" formato='DD/MM/YYYY' data={pessoa.dtempr} /></dd>
                <dt className="col-lg-3 text-right">Função</dt>
                <dd className="col-lg-9">{pessoa.funcao?.descricao || '-'}</dd>
            </dl>
            {['S', 'U'].includes(pessoa.tipoPessoa) ? (
                <div>
                    <h3>Dados de sócio</h3>
                    <dl className="row">
                        <dt className="col-lg-3 text-right">Mat. sindical</dt>
                        <dd className="col-lg-9">{pessoa.sindical}</dd>
                        <dt className="col-lg-3 text-right">Sócio desde</dt>
                        <dd className="col-lg-9"><DataFormatada formato='DD/MM/YYYY' data={pessoa.dtadmi} /></dd>
                    </dl>
                </div>
            ) : null}
        </div>
    );
}
