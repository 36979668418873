import { notificacaoErro } from "../app/notificacao";

async function fetchCriaDepartamento(nome, token) {
  const url = new URL(process.env.REACT_APP_API_URL + "/departamento");
  const init = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify({
      nome: nome,
    }),
  };

  const fet = await fetch(url, init);

  switch (fet.status) {
    case 201:
      return { success: true, status: fet.status, data: await fet.json() };
    case 404:
      return {
        success: false,
        status: fet.status,
        error: notificacaoErro(fet, init, "Ocorreu um erro ao criar departamento."),
      };
    default:
      return {
        success: false,
        status: fet.status,
        error: notificacaoErro(fet, init, "Ocorreu um erro inesperado ao criar departamento."),
      };
  }
}

const criaDepartamento = { fetchCriaDepartamento };

export default criaDepartamento;
