import React, { useEffect, useImperativeHandle } from "react";
import { useHistory } from "react-router-dom";
import { useFetchDepartamentos } from "../../../api/base";
import BotaoAcoes from "../../../app/BotaoAcoes";
import Carregando from "../../../app/Carregando";
import ListaMotivos from "../../../app/ListaMotivos";
import useWindowDimensions from "../../../Utils/getWindowDimension";
import "./Departamentos.css";
import EditarDepartamento from "./EditarDepartamento";
import NovoDepartamento from "./NovoDepartamento";

export default function Departamentos({ referencia }) {
  const [openMotivos, setOpenMotivos] = React.useState(false);
  const [dadosModalMotivos, setDadosModalMotivos] = React.useState({ subTitulo: "", queryValor: null });
  const [openNovoDepartamento, setOpenNovoDepartamento] = React.useState(false);
  const [openEditaDepartamento, setOpenEditaDepartamento] = React.useState(false);
  const [dadosModalEditaDepartamento, setDadosEditaDepartamento] = React.useState({});

  const history = useHistory();

  const { width } = useWindowDimensions();
  const mobile = width > 768 ? false : true;

  const { departamentos, get, carregandoDepartamentos } = useFetchDepartamentos();

  useEffect(() => {
    get();
  }, [get]);

  const handleVerMotivo = (departamentoId) => {
    return history.push({
      pathname: "/motivos",
      state: {
        filtro: {
          departamento: departamentoId,
        },
      },
    });
  };

  const handleOpenModalMotivos = (departamento) => {
    setDadosModalMotivos({ subTitulo: departamento.nome, queryValor: departamento.id });
    setOpenMotivos(true);
  };
  const handleOpenModalEditaDepartamento = (departamento) => {
    setDadosEditaDepartamento(departamento);
    setOpenEditaDepartamento(true);
  };

  useImperativeHandle(referencia, () => {
    return {
      setOpenModal: () => setOpenNovoDepartamento(true),
    };
  });

  return (
    <>
      <Carregando carregando={carregandoDepartamentos}>
        <table className="ui very basic table" style={{ marginTop: "20px" }}>
          <thead>
            <tr>
              <th className={`tableHead ${mobile ? "" : "center aligned"}`}>Descrição</th>
              <th className={`tableHead ${mobile ? "" : "center aligned"}`}>Usuários</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(departamentos) &&
              departamentos?.map((departamento) => {
                return (
                  <tr key={departamento.id}>
                    <th scope="row" className={mobile ? "" : "center aligned"}>
                      {departamento.nome}
                    </th>
                    <td className={mobile ? "" : "center aligned"}>{departamento.qtdUsuarios}</td>
                    <td className={mobile ? "" : "center aligned"}>
                      <BotaoAcoes
                        classNameSpan="textoBotao"
                        styledesktop={styleDesktopButtonTable}
                        styleglobal={styleGlobal}
                        onClick={() => handleOpenModalEditaDepartamento(departamento)}
                      >
                        Editar Departamento
                      </BotaoAcoes>
                    </td>
                    <td className={mobile ? "" : "center aligned"}>
                      <BotaoAcoes
                        data-testid={`ver-motivos-${departamento.id}`}
                        classNameSpan="textoBotao"
                        styleglobal={styleGlobal}
                        styledesktop={styleDesktopButtonTable}
                        onClick={() => handleOpenModalMotivos(departamento)}
                      >
                        Ver motivos
                      </BotaoAcoes>
                    </td>
                  </tr>
                );
              })}
            {(!departamentos || departamentos?.length === 0) && (
              <tr>
                <td colSpan="7">Nada para exibir</td>
              </tr>
            )}
          </tbody>
        </table>
      </Carregando>
      {openMotivos && (
        <ListaMotivos
          open={openMotivos}
          setOpen={setOpenMotivos}
          titulo={"Departamento:"}
          subTitulo={dadosModalMotivos.subTitulo}
          queryNome="departamento"
          queryValor={dadosModalMotivos.queryValor}
          onVerMotivo={handleVerMotivo}
        />
      )}
      {openEditaDepartamento && (
        <EditarDepartamento
          open={openEditaDepartamento}
          setOpen={setOpenEditaDepartamento}
          departamento={dadosModalEditaDepartamento}
          reload={get}
        />
      )}
      <NovoDepartamento open={openNovoDepartamento} setOpen={setOpenNovoDepartamento} reload={get} />
    </>
  );
}

const styleGlobal = {
  position: "relative",
};
const styleDesktopButtonTable = {
  width: "150px",
};
