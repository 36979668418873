import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

export function getDataFormatada(dataInicial, relativo, formato = 'DD/MM/YYYY HH:mm:ss') {
    let dataFormatada;
    if (dataInicial && dataInicial.date) {
        dataInicial = dataInicial.date
    }

    if (dataInicial) {
        dataFormatada = moment(dataInicial);
        if (relativo) {
            dataFormatada = dataFormatada.fromNow();
        } else {
            dataFormatada = dataFormatada.format(formato)
        }
    }

    return dataFormatada;

}

function DataFormatada(props) {
    return (
        <span title={props.relativo ? getDataFormatada(props.data) : null}>
            {getDataFormatada(props.data, props.relativo, props.formato) || props.seNulo}
        </span>
    )
}

DataFormatada.propTypes = {
    data: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    seNulo: PropTypes.string,
    formato: PropTypes.string,
    relativo: PropTypes.bool
}

export default DataFormatada;
