import React, { useEffect } from "react";
import _ from "lodash";
import { useFetchCategoria } from "../../api/base";
import { AvField } from "availity-reactstrap-validation-safe";

function CategoriaSelect({ helpMessage = true, onChange, disabled, required = true, ...props }) {
  const { categorias, get, carregando } = useFetchCategoria();

  useEffect(() => {
    get();
  }, [get]);

  return (
    <AvField
      {...props}
      data-testid="categoria"
      type="select"
      name="categoria"
      label="Categoria"
      helpMessage={helpMessage ? "Selecione a categoria do atendimento" : ""}
      required={required}
      onChange={onChange}
      disabled={carregando || disabled}
    >
      <option>Selecione</option>
      {_.isEmpty(categorias)
        ? null
        : categorias?.map((categoria, index) => {
            return (
              <option key={index} value={categoria.id}>
                {categoria.descricao}
              </option>
            );
          })}
    </AvField>
  );
}

export default CategoriaSelect;
