import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";

import Carregando from "../../app/Carregando";
import { MdSearch } from "react-icons/md";
import TabelaListaPessa from "./TabelaListaPessoa";
import listaPessoas from "../../api/pessoas-fisicas";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import React, { useState } from "react";

export default function FormBuscaPessoa({ onSelect, novoAtendimento }) {
  const [carregando, setCarregando] = useState();
  const [dados, setDados] = useState();
  const [filtros, setFiltros] = useState({});
  const logged = useSelector((state) => state.auth);

  const onChangeFiltros = (campo) => (event) => {
    const valor = event.target.value;
    if (!valor) {
      delete filtros[campo];
      setFiltros({ ...filtros });
      return;
    }
    setFiltros({ ...filtros, [campo]: event.target.value });
  };

  const limpar = () => {
    setFiltros({});
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setCarregando(true);
    setDados(null);

    let cpfFormatado;
    if (filtros?.cpf) {
      cpfFormatado = filtros.cpf.replace(/[^\d]+/g, "");
      if (cpfFormatado.length !== 11) {
        return toast.error("CPF inválido");
      }
    }

    listaPessoas.fetchListaPessoas(logged.token, filtros).then((response) => {
      if (!response.success) {
        setCarregando(false);
        return response.error;
      }
      setCarregando(false);
      setDados(response.data);
    });
  };

  return (
    <div>
      <Form onSubmit={onSubmit}>
        <Row>
          <Col md={8} sm={12}>
            <FormGroup>
              <Label for="nome">Pessoa</Label>
              <Input
                type="text"
                value={filtros.nome || ""}
                name="nome"
                data-testid="nome"
                onChange={onChangeFiltros("nome")}
              />
            </FormGroup>
          </Col>
          <Col md={4} sm={12}>
            <FormGroup required>
              <Label for="id">Código</Label>
              <Input
                type="number"
                name="id"
                value={filtros.id || ""}
                data-testid="id"
                onChange={onChangeFiltros("id")}
              />
            </FormGroup>
          </Col>
          <Col md={4} sm={12}>
            <FormGroup>
              <Label for="cpf">CPF</Label>
              <Input
                type="text"
                name="cpf"
                value={filtros.cpf || ""}
                data-testid="cpf"
                onChange={onChangeFiltros("cpf")}
                pattern="[0-9]{11}"
                maxLength="14"
              />
            </FormGroup>
          </Col>
          <Col md={4} sm={12}>
            <FormGroup>
              <Label for="sindical">Mat. sindical</Label>
              <Input
                type="number"
                name="sindical"
                value={filtros.sindical || ""}
                data-testid="sindical"
                onChange={onChangeFiltros("sindical")}
              />
            </FormGroup>
          </Col>
          <Col md={4} sm={12}>
            <FormGroup>
              <Label for="tipoPessoa">Tipo Pessoa</Label>
              <Input
                type="select"
                name="tipoPessoa"
                value={filtros.tipoPessoa || ""}
                data-testid="tipoPessoa"
                onChange={onChangeFiltros("tipoPessoa")}
              >
                <option value="">Selecione</option>
                <option value="S">Sócio</option>
                <option value="U">Sócio Usuário</option>
                <option value="B">Base do Sindicato</option>
                <option value="D">Demais pessoas</option>
              </Input>
            </FormGroup>
          </Col>
          <Col md={6} xs={7} className="text-left align-items-end mb-3 mt-3">
            <Button className="primary-button" type="button" onClick={limpar}>
              Limpar dados
            </Button>
          </Col>
          <Col md={6} xs={5} className="text-right align-items-end mb-3 mt-3">
            <Button className="primary-button" type="submit">
              <MdSearch /> Buscar
            </Button>
          </Col>
        </Row>
      </Form>
      <Carregando carregando={carregando}>
        {dados ? <TabelaListaPessa novoAtendimento={novoAtendimento} onSelect={onSelect} dados={dados} /> : null}
      </Carregando>
    </div>
  );
}
