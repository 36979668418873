import React from "react";
import PropTypes from "prop-types";
import { AvField } from "availity-reactstrap-validation-safe";
import { useMotivos } from "../../api/motivo";

function MotivoSelect({ categoria, helpMessage = true, onChange, disabled, ...props }) {
  const [motivos, carregando] = useMotivos("categoria", categoria);

  const onChangeLocal = (e) => {
    const motivosEncontrados = motivos.filter((motivo) => motivo.id === parseInt(e.target.value));
    const motivoSelecionado = motivosEncontrados.pop();
    onChange(e, motivoSelecionado);
  };

  return (
    <AvField
      {...props}
      data-testid="motivo"
      type="select"
      name="motivo"
      label="Motivo"
      helpMessage={helpMessage ? "Selecione o motivo" : ""}
      required
      onChange={onChangeLocal}
      disabled={carregando || !categoria || disabled}
    >
      <option>Selecione</option>
      {motivos?.map((motivo, index) => {
        return (
          <option key={index} value={motivo.id}>
            {motivo.descricao}
          </option>
        );
      })}
    </AvField>
  );
}

MotivoSelect.propTypes = {
  categoria: PropTypes.number,
  onChange: PropTypes.func,
};

export default MotivoSelect;
