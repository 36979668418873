import React, { useEffect } from "react";
import { Card, CardBody, Badge } from "reactstrap";
import { useFetchUsuarios } from "../../api/base";
import { useFetchUsuarioAtivo } from "../../api/usuarioAtivo";
import BotaoAcoes from "../../app/BotaoAcoes";
import Carregando from "../../app/Carregando";
import HeaderCardBody from "../../app/HeaderCardBody";
import ListaDepartamentos from "../../app/ListaDepartamentos";
import useWindowDimensions from "../../Utils/getWindowDimension";
import EditaUsuario from "./EditarUsuario";
import NovoUsuario from "./NovoUsuario";

export default function Usuarios() {
  const [openDepartamentos, setOpenDepartamentos] = React.useState(false);
  const [dadosModalDepartamentos, setDadosModalDepartamentos] = React.useState([]);
  const [mostraBotaoNovoUsuario, setMostraBotaoNovoUsuario] = React.useState(false);
  const [openNovoUsuario, setOpenNovoUsuario] = React.useState(false);
  const [openEditaUsuario, setOpenEditaUsuario] = React.useState(false);
  const [dadosEditaUsuario, setDadosEditaUsuario] = React.useState({});

  const { usuarios, get, carregando } = useFetchUsuarios();
  const [usuarioAtivo, carregandoUsuarioAtivo] = useFetchUsuarioAtivo();

  const { width } = useWindowDimensions();
  const mobile = width > 768 ? false : true;

  useEffect(() => {
    if (usuarioAtivo.perfil) {
      if (usuarioAtivo.perfil.id === 1) return setMostraBotaoNovoUsuario(true);
      else return setMostraBotaoNovoUsuario(false);
    }
  }, [usuarioAtivo]);
  useEffect(() => {
    get();
  }, [get]);

  const handleOpenModalDepartamentos = (departamento) => {
    setDadosModalDepartamentos(departamento);
    setOpenDepartamentos(true);
  };
  const handleOpenEditaUsuario = (usuario) => {
    setDadosEditaUsuario(usuario);
    setOpenEditaUsuario(true);
  };

  return (
    <Card>
      <Carregando carregando={carregando || carregandoUsuarioAtivo}>
        <HeaderCardBody
          onBotaoAcoes={() => setOpenNovoUsuario(true)}
          titulo="Usuários"
          mostraBotao={mostraBotaoNovoUsuario}
          tituloBotao="+ Novo Usuário"
        />
        <CardBody>
          <table className="ui very basic table" style={{ marginTop: "20px" }}>
            <thead>
              <tr>
                <th className={`tableHead ${mobile ? "" : "center aligned"}`}>Usuário</th>
                <th className={`tableHead ${mobile ? "" : "center aligned"}`}>Nome</th>
                <th className={`tableHead ${mobile ? "" : "center aligned"}`}>Permissão</th>
                <th className={`tableHead ${mobile ? "" : "center aligned"}`}>Status</th>
                <th className={`tableHead ${mobile ? "" : "center aligned"}`}>Departamentos</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(usuarios) &&
                usuarios?.map((usuario, index) => {
                  return (
                    <tr key={index}>
                      <td className={mobile ? "" : "center aligned fw-bold"}>{usuario.email}</td>
                      <td className={mobile ? "" : "center aligned fw-bold"}>{usuario.nome}</td>
                      <td className={mobile ? "" : "center aligned fw-bold"}>{usuario.perfil.descricao}</td>
                      <td className={mobile ? "" : "center aligned"}>
                        {usuario.ativo ? (
                          <Badge color="success" pill>
                            Ativo
                          </Badge>
                        ) : (
                          <Badge color="danger" pill>
                            Inativo
                          </Badge>
                        )}
                      </td>
                      <td className={mobile ? "" : "center aligned"}>
                        <BotaoAcoes
                          classNameSpan="textoBotao"
                          styledesktop={styleDesktopButtonTable}
                          styleglobal={styleGlobal}
                          onClick={() => handleOpenModalDepartamentos(usuario.departamentos)}
                        >
                          Ver Departamentos
                        </BotaoAcoes>
                      </td>
                      <td className={mobile ? "" : "center aligned"}>
                        <BotaoAcoes
                          data-testid={""}
                          classNameSpan="textoBotao"
                          styleglobal={styleGlobal}
                          styledesktop={styleDesktopButtonTable}
                          onClick={() => handleOpenEditaUsuario(usuario)}
                        >
                          Editar
                        </BotaoAcoes>
                      </td>
                    </tr>
                  );
                })}
              {(!usuarios || usuarios?.length === 0) && (
                <tr>
                  <td colSpan="7">Nada para exibir</td>
                </tr>
              )}
            </tbody>
          </table>
        </CardBody>
      </Carregando>
      {openDepartamentos && (
        <ListaDepartamentos
          departamentos={dadosModalDepartamentos}
          open={openDepartamentos}
          setOpen={setOpenDepartamentos}
          titulo={"Departamentos"}
          queryNome="departamento"
        />
      )}
      {openEditaUsuario && (
        <EditaUsuario open={openEditaUsuario} setOpen={setOpenEditaUsuario} usuario={dadosEditaUsuario} reload={get} />
      )}
      <NovoUsuario open={openNovoUsuario} setOpen={setOpenNovoUsuario} reload={get} />
    </Card>
  );
}

const styleGlobal = {
  position: "relative",
};
const styleDesktopButtonTable = {
  width: "150px",
};
