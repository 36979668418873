import "./Menu.css";

import { Collapse, Nav, Navbar, NavbarBrand, NavbarToggler } from "reactstrap";
import { useSelector } from "react-redux";
import MenuItemPrivado from "./MenuItemPrivado";
import logo from "../../images/logo.png";
import { useState } from "react";
import { MdHome, MdHeadsetMic, MdChat, MdPeople, MdWork, MdOutlineTableChart } from "react-icons/md";
import useWindowDimensions from "../../Utils/getWindowDimension";

const MenuContent = ({ title, children }) => {
  return (
    <div className="menuContent">
      {children}
      <span className="textMenuContent">{title}</span>
    </div>
  );
};

function Menu({ setMenuAtivo }) {
  const logged = useSelector((state) => state.auth);
  const [isOpen, setIsOpen] = useState(false);
  const { width } = useWindowDimensions();
  const mobile = width > 768 ? false : true;
  const toggle = () => {
    if (setMenuAtivo) {
      setMenuAtivo(!isOpen);
      setIsOpen(!isOpen);
    }
  };

  return logged.isLogged ? (
    <div className={mobile ? "menu-container-fixed" : "menu-container"}>
      <Navbar className="side-nav" light expand="md">
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar vertical container="fluid">
            <NavbarBrand className="logo" href="/">
              <img src={logo} alt="Logo" />
            </NavbarBrand>
            <MenuItemPrivado para="/" setMenu={toggle}>
              <MenuContent title="Home">
                <MdHome className="logoMenu" />
              </MenuContent>
            </MenuItemPrivado>
            <MenuItemPrivado para="/proximos-atendimentos" setMenu={toggle}>
              <MenuContent title="Próximos atendimentos">
                <MdHeadsetMic className="logoMenu" />
              </MenuContent>
            </MenuItemPrivado>
            <MenuItemPrivado para="/todos-atendimentos" setMenu={toggle}>
              <MenuContent title="Todos atendimentos">
                <MdChat className="logoMenu" />
              </MenuContent>
            </MenuItemPrivado>
            <MenuItemPrivado para="/pessoas" setMenu={toggle}>
              <MenuContent title="Pessoas">
                <MdPeople className="logoMenu" />
              </MenuContent>
            </MenuItemPrivado>
            <MenuItemPrivado para="/empresas" setMenu={toggle}>
              <MenuContent title="Empresas">
                <MdWork className="logoMenu" />
              </MenuContent>
            </MenuItemPrivado>
            <MenuItemPrivado para="/departamentos" setMenu={toggle}>
              <MenuContent title="Classificações">
                <MdOutlineTableChart className="logoMenu" />
              </MenuContent>
            </MenuItemPrivado>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  ) : null;
}

export default Menu;
