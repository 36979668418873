import { AvForm } from "availity-reactstrap-validation-safe";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import atualizaUsuario from "../../api/usuarioPut";
import BotaoAcoes from "../../app/BotaoAcoes";
import Carregando from "../../app/Carregando";
import PerfilSelect from "../../app/PerfilSelect";
import useWindowDimensions from "../../Utils/getWindowDimension";

export default function EditaUsuario({ open, setOpen, usuario, reload }) {
  const [formulario, setFormulario] = useState({
    email: usuario.email,
    nome: usuario.nome,
    perfil: usuario.perfil.id,
  });
  const [ativo, setAtivo] = useState(usuario.ativo);
  const [editado, setEditado] = useState(false);
  const [carregando, setCarregando] = useState(false);

  const { width } = useWindowDimensions();
  const mobile = width > 768 ? false : true;

  const token = useSelector((state) => state.auth.token);

  const handleCloseModal = () => {
    setFormulario({
      email: "",
      nome: "",
      perfil: "",
      senha: "",
    });
    setOpen(false);
  };

  const handleValidSubmit = () => {
    if (!formulario.nome || !formulario.perfil) return toast.warning("Por favor preencha todos os campos");
    setCarregando(true);
    let body = { ...usuario, ...formulario };
    body.ativo = ativo;
    atualizaUsuario
      .fetchAtualizaUsuario(body, token, usuario.id)
      .then((res) => {
        if (!res.success) return res.error;
        toast.success("Usuário Atualizado com sucesso");
        setCarregando(false);
        setOpen(false);
        reload();
      })
      .finally(() => setCarregando(false));
  };

  const handleInvalidSubmit = (event, values) => {
    toast.warning("Por favor preencha todos os campos");
  };

  return (
    <>
      <Modal isOpen={open} toggle={() => setOpen(!open)} size="lg">
        <ModalHeader toggle={() => setOpen(!open)}>
          <span className="modalHeader">Editar usuário</span>
          <span className="secondaryText subTitulo" data-testid="nome-departamento">
            {formulario.nome}
          </span>
        </ModalHeader>
        <ModalBody>
          <AvForm onValidSubmit={handleValidSubmit} onInvalidSubmit={handleInvalidSubmit}>
            <Carregando carregando={carregando}>
              <Row>
                <Col md={12} xs={12}>
                  <FormGroup>
                    <Label for="email">Email do usuário</Label>
                    <Input
                      id="email"
                      data-testid="email"
                      required
                      name="email"
                      type="text"
                      disabled={true}
                      value={formulario.email || ""}
                      onChange={(e) => {
                        setEditado(true);
                        setFormulario({ ...formulario, email: e.target.value });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={4} xs={12}>
                  <FormGroup>
                    <Label for="nome">Nome do usuário</Label>
                    <Input
                      id="nome"
                      data-testid="nome"
                      required
                      name="nome"
                      type="text"
                      value={formulario.nome || ""}
                      onChange={(e) => {
                        setEditado(true);
                        setFormulario({ ...formulario, nome: e.target.value });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={4} xs={12}>
                  <FormGroup>
                    <PerfilSelect
                      helpMessage={false}
                      value={formulario.perfil || ""}
                      onChange={(value) => {
                        setEditado(true);
                        setFormulario({ ...formulario, perfil: value });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={4} xs={12} className="d-flex" style={{ justifyContent: "flex-end", alignItems: "center" }}>
                  <FormGroup switch>
                    <Input
                      type="switch"
                      checked={ativo}
                      onChange={() => {
                        setEditado(true);
                        setAtivo(!ativo);
                      }}
                    />
                    <Label check>Usuário ativo ?</Label>
                  </FormGroup>
                </Col>
              </Row>
              <Col md={12} xs={12} className={mobile ? "text-center" : "text-right"} style={{ marginTop: "10px" }}>
                <BotaoAcoes disabled={!editado} data-testid="button-criar" type="submit" styleglobal={styleGlobal}>
                  Salvar
                </BotaoAcoes>
              </Col>
            </Carregando>
          </AvForm>
        </ModalBody>
        <ModalFooter>
          <BotaoAcoes disabled={carregando} styleglobal={styleGlobal} reversecolor={true} onClick={handleCloseModal}>
            Voltar
          </BotaoAcoes>
        </ModalFooter>
      </Modal>
    </>
  );
}

const styleGlobal = { position: "relative", width: "120px" };
