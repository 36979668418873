import React from "react";
import { MdKeyboardBackspace } from "react-icons/md";
import { Redirect, useHistory } from "react-router-dom";
import { Card, CardBody, Col, FormGroup, Input, Label, Row } from "reactstrap";
import BotaoAcoes from "../../app/BotaoAcoes";
import FormBuscaEmpresa from "../../app/FormBuscaEmpresa";
import HeaderCardBody from "../../app/HeaderCardBody";
import { useTokenValido } from "../../app/token";
import useWindowDimensions from "../../Utils/getWindowDimension";
import "./NovoAtendimento.css";

export default function BuscaEmpresaNovoAtendimento({
  tituloCard = "Pesquise a empresa que deseja atender",
  onSelectEmpresa,
  onSelectEmpresaNaoCadastrada,
  onBackEmpresa,
}) {
  const [empresaNaoCadastrada, setEmpresaNaoCadastrada] = React.useState(false);

  const history = useHistory();

  const { width } = useWindowDimensions();
  const mobile = width > 768 ? false : true;

  const handleSelectEmpresa = (empresa) => {
    onSelectEmpresa ? onSelectEmpresa(empresa) : history.push(`/novo-atendimento/empresa/${empresa.id}`);
  };

  const handleSelectEmpresaNaoCadastrada = (nome) => {
    onSelectEmpresaNaoCadastrada
      ? onSelectEmpresaNaoCadastrada(nome)
      : history.push({
          pathname: "/novo-atendimento/empresa-nao-cadastrada",
          state: { nome },
        });
  };

  if (!useTokenValido()) {
    return <Redirect to="/login" />;
  } else {
    return (
      <Card>
        <HeaderCardBody titulo="Novo Atendimento" mostraBotao={false} />
        <CardBody>
          {empresaNaoCadastrada ? (
            <EmpresaNaoCadastrada
              onBack={() => setEmpresaNaoCadastrada(!empresaNaoCadastrada)}
              onBotaoAcao={handleSelectEmpresaNaoCadastrada}
            />
          ) : (
            <>
              <Row>
                <Col md={12} className={mobile ? "text-center" : ""}>
                  <BotaoAcoes
                    styledesktop={styleDesktop}
                    stylemobile={styleMobile}
                    styleglobal={styleglobal}
                    reversecolor={true}
                    onClick={() => setEmpresaNaoCadastrada(!empresaNaoCadastrada)}
                  >
                    Empresa não cadastrada
                  </BotaoAcoes>
                </Col>
              </Row>
              <div className="w-100 mb-4 d-flex">
                <MdKeyboardBackspace
                  className="voltar-icone"
                  onClick={() => (onBackEmpresa ? onBackEmpresa() : history.goBack())}
                />
                <div className="w-100 h-100 text-center">
                  <span className="span-card-novo-atendimento">{tituloCard}</span>
                </div>
              </div>
              <FormBuscaEmpresa novoAtendimento={true} onSelect={handleSelectEmpresa} />
            </>
          )}
        </CardBody>
      </Card>
    );
  }
}

function EmpresaNaoCadastrada({ onBack, onBotaoAcao }) {
  const [nome, setNome] = React.useState("");
  const styleDesktop = {
    position: "absolute",
    right: 10,
    bottom: 15,
  };
  return (
    <>
      <div className="w-100 mb-4 d-flex">
        <MdKeyboardBackspace className="voltar-icone" onClick={onBack} />
        <div className="w-100 h-100 text-center">
          <span className="span-card-novo-atendimento">Digite o nome da empresa que deseja atender</span>
        </div>
      </div>
      <Row>
        <Col md={9} sm={12}>
          <FormGroup>
            <Label for="nome">Nome</Label>
            <Input type="text" data-testid="nomeEmpresa" name="nome" onChange={(e) => setNome(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={3} sm={12} className="text-center">
          <BotaoAcoes styledesktop={styleDesktop} onClick={() => onBotaoAcao(nome)}>
            Prosseguir
          </BotaoAcoes>
        </Col>
      </Row>
    </>
  );
}

const styleDesktop = {
  width: "220px",
  marginTop: "-40px",
  marginRight: "10px",
};
const styleglobal = {
  boxShadow: "0.5px 1px 2px #888888",
};
const styleMobile = {
  marginBottom: "40px",
};
