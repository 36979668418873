import { notificacaoErro } from "../app/notificacao";

async function useFetchBaixaArquivo(token, anexoId) {
    const url = new URL(process.env.REACT_APP_API_URL + '/atendimento-anexo/' + anexoId + '/baixar');

    const init = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }

    const fet = await fetch(url, init);

    switch (fet.status) {
        case 200:
            return { success: true, status: fet.status, data: await fet.arrayBuffer() };
        case 401:
            return { success: false, status: fet.status, error: notificacaoErro(fet, init, 'Ocorreu um erro ao baixar o arquivo 401') };
        case 404:
            return { success: false, status: fet.status, error: notificacaoErro(fet, init, 'Ocorreu um erro ao baixar o arquivo 404') };
        default:
            return { success: false, status: fet.status, error: notificacaoErro(fet, init, 'Ocorreu um erro inesperado ao baixar o arquivo') };
    }

}

const baixaArquivo = { useFetchBaixaArquivo };

export default baixaArquivo;
