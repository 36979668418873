import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import useWindowDimensions from "../../Utils/getWindowDimension";

export default function Donut({ dados, goToAtendimentos, usuarioAtivo }) {
  const [options, setOptions] = useState({});
  const { width } = useWindowDimensions();
  const mobile = width > 768 ? false : true;
  let quantidade = [];
  let descricao = [];
  for (let i = 0; i < dados.length; i++) {
    quantidade.push(dados[i].qtdUsuario);
    descricao.push(dados[i].descricao);
  }
  const series = quantidade;

  useEffect(() => {
    dados &&
      setOptions({
        chart: {
          events: {
            dataPointSelection: (event, chartContext, config) => {
              let status;
              config.dataPointIndex === 0 ? (status = 2) : (status = 1); //Status 2 = Em atendimento, status 1 = Aguardando atendimento
              goToAtendimentos(status, usuarioAtivo);
            },
          },
        },
        labels: descricao,
        colors: ["#0062F5", "#CB2240"],
        legend: {
          show: !mobile,
          position: "bottom",
          fontSize: "16px",
          fontFamily: "Poppins",
        },
        plotOptions: {
          pie: {
            dataLabels: {
              offset: 0,
              minAngleToShowLabel: 10,
            },
            startAngle: 0,
            endAngle: 360,
            expandOnClick: true,
            offsetX: 0,
            offsetY: 0,
            customScale: 1.0,
            donut: {
              size: "70%",
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: "22px",
                  fontFamily: "Poppins",
                  fontWeight: 700,
                  offsetY: -5,
                },
                value: {
                  show: true,
                  fontSize: "22px",
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  offsetY: 5,
                },
                total: {
                  show: true,
                  fontSize: "14px",
                  label: "Total atendimentos",
                  fontFamily: "Poppins",
                },
              },
            },
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "14px",
            fontFamily: "Poppins",
          },
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dados, goToAtendimentos, mobile, usuarioAtivo]);
  return dados ? <Chart options={options} series={series} type="donut" /> : null;
}
