import React from "react";
import "./NovoAtendimento.css";

import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation-safe";
import { Card, CardBody, Col, FormGroup, Label, Row } from "reactstrap";
import { useHistory, useParams, useLocation } from "react-router-dom";

import Carregando from "../../app/Carregando";
import CategoriaSelect from "../../app/CategoriaSelect";
import DropFile from "../../app/DropFile";
import MotivoSelect from "../../app/MotivoSelect";
import { Redirect } from "react-router";
import fetchNovoAtendimento from "../../api/novoAtendimento";
import { notificacaoSuccess } from "../../app/notificacao";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useTokenValido } from "../../app/token";
import MeioAtendimentoSelect from "../../app/MeioAtendimentoSelect";
import HeaderCardBody from "../../app/HeaderCardBody";
import BotaoAcoes from "../../app/BotaoAcoes";
import useWindowDimensions from "../../Utils/getWindowDimension";
import NomeNovoAtendimento from "../../app/NomeNovoAtendimento";
import preparaAtendimento from "../../Utils/preparaAtendimento";
import BuscaEmpresaNovoAtendimento from "./BuscaEmpresaNovoAtendimento";
import UsuarioSelect from "../../app/UsuarioSelect";
import BuscaEmpresaPessoaAnonimaOuNaoCadastrada from "../../app/BuscaEmpresaPessoaAnonimaOuNaoCadastrada";

function NovoAtendimento() {
  const logged = useSelector((state) => state.auth);

  const { id, tipo } = useParams();
  const history = useHistory();
  const location = useLocation();

  const [buscaEmpresa, setBuscaEmpresa] = React.useState(false);
  const [empresaPessoaAnonimaOuNaoCadastrada, setEmpresaPessoaAnonimaOuNaoCadastrada] = React.useState({});
  const [carregando, setCarregando] = React.useState(false);
  const [carregandoEntidade, setCarregandoEntidade] = React.useState(false);
  const [anexos, setAnexos] = React.useState([]);
  const [formulario, setFormulario] = React.useState({
    statusAtendimento: "1",
  });

  const { width } = useWindowDimensions();
  const mobile = width > 768 ? false : true;

  let nomeNaoCadastrado = "";
  if (tipo === "pessoa-nao-cadastrada" || tipo === "empresa-nao-cadastrada") {
    nomeNaoCadastrado = location.state?.nome;
  }

  const handleValidSubmit = (event, values) => {
    setCarregando(true);
    let responsavel = null;
    if (values.responsavel) {
      responsavel = values.responsavel;
    }
    const bodyInicial = {
      motivo: parseInt(values.motivo),
      meioAtendimento: parseInt(values.meioAtendimento),
      descricao: values.descricao,
      status: parseInt(values.statusAtendimento),
      responsavel: parseInt(responsavel),
      anexos: anexos,
    };
    let body = preparaAtendimento(id, tipo, nomeNaoCadastrado, bodyInicial, empresaPessoaAnonimaOuNaoCadastrada);
    fetchNovoAtendimento
      .useFetchNovoAtendimento(logged.token, body)
      .then((response) => {
        if (!response.success) {
          setCarregando(false);
          return response.error;
        }
        setCarregando(false);
        history.push(`/atendimentos/${response.data["hash-atendimento"]}`);
        return notificacaoSuccess("success", "Atendimento criado com sucesso!");
      })
      .finally(() => setCarregando(false));
  };

  const handleInvalidSubmit = (event, values) => {
    toast.warning("Por favor preencha todos os campos");
  };

  const handleSelectEmpresa = (empresa) => {
    setEmpresaPessoaAnonimaOuNaoCadastrada(empresa);
    setBuscaEmpresa(!buscaEmpresa);
  };
  const handleEmpresaNaoCadastrada = (empresaNome) => {
    setEmpresaPessoaAnonimaOuNaoCadastrada({ razaoSocial: empresaNome });
    setBuscaEmpresa(!buscaEmpresa);
  };

  const changeCategoria = (e) => {
    setFormulario({
      ...formulario,
      motivo: null,
      responsavel: null,
      categoria: parseInt(e.target.value),
    });
  };

  const changeMotivo = (e, motivoSelecionado) => {
    setFormulario({
      ...formulario,
      responsavel: null,
      motivo: motivoSelecionado,
    });
  };

  const changeResponsavel = (responsavel) => {
    setFormulario({
      ...formulario,
      responsavel,
    });
  };

  const onChangeDrop = (anexosDrop) => {
    setAnexos(anexosDrop);
  };

  if (!useTokenValido()) {
    <Redirect to="/login" />;
  } else if ((tipo === "pessoa-nao-cadastrada" || tipo === "empresa-nao-cadastrada") && !nomeNaoCadastrado) {
    return <Redirect to="/novo-atendimento" />;
  } else {
    return (
      <>
        {buscaEmpresa ? (
          <BuscaEmpresaNovoAtendimento
            tituloCard="Pesquise a empresa que deseja vincular ao atendimento"
            onSelectEmpresaNaoCadastrada={handleEmpresaNaoCadastrada}
            onSelectEmpresa={handleSelectEmpresa}
            onBackEmpresa={() => setBuscaEmpresa(!buscaEmpresa)}
          />
        ) : (
          <Card>
            <HeaderCardBody titulo="Novo atendimento" styleCardHeader={{ marginBottom: "20px" }} mostraBotao={false} />
            <CardBody>
              <Carregando carregando={carregandoEntidade || carregando}>
                <AvForm onValidSubmit={handleValidSubmit} onInvalidSubmit={handleInvalidSubmit} model={formulario}>
                  <Row>
                    <Col md={6} xs={12} className={mobile ? "text-center" : ""}>
                      <BotaoAcoes
                        type="button"
                        disabled={carregandoEntidade || carregando}
                        onClick={() => history.goBack()}
                        styledesktop={Object.assign(styleDesktopBotaoCancelar, styleDesktop)}
                        stylemobile={styleMobile}
                        styleglobal={Object.assign(styleGlobalBotaoCancelar, styleGlobal)}
                      >
                        Cancelar
                      </BotaoAcoes>
                    </Col>
                    <Col md={6} xs={12} className={mobile ? "text-center" : ""}>
                      <BotaoAcoes
                        type="submit"
                        disabled={carregandoEntidade || carregando}
                        styledesktop={styleDesktop}
                        stylemobile={styleMobile}
                        styleglobal={styleGlobal}
                      >
                        Salvar
                      </BotaoAcoes>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "40px" }}>
                    <Col md={8} xs={12}>
                      <h2>
                        {tipo === "pessoa-anonima" ? (
                          "Pessoa anônima"
                        ) : nomeNaoCadastrado ? (
                          nomeNaoCadastrado
                        ) : (
                          <NomeNovoAtendimento id={id} tipo={tipo} setCarregando={setCarregandoEntidade} />
                        )}
                      </h2>
                      {tipo === "pessoa-anonima" || (nomeNaoCadastrado && tipo === "pessoa-nao-cadastrada") ? (
                        <BuscaEmpresaPessoaAnonimaOuNaoCadastrada
                          nomeEmpresa={empresaPessoaAnonimaOuNaoCadastrada?.razaoSocial}
                          onBuscar={() => setBuscaEmpresa(!buscaEmpresa)}
                        />
                      ) : null}
                    </Col>
                    <Col md={4} xs={12}>
                      <AvField
                        type="select"
                        name="statusAtendimento"
                        label="Status atendimento"
                        data-testid="statusAtendimento"
                        required
                      >
                        <option>Selecione</option>
                        <option value="1">Aguardando atendimento</option>
                        <option value="3">Encerrado</option>
                      </AvField>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "20px" }}>
                    <Col md={12} xs={12}>
                      <FormGroup>
                        <AvGroup>
                          <AvField
                            rows="3"
                            data-testid="descricao"
                            type="textarea"
                            name="descricao"
                            label="Descrição"
                            id="descricao"
                            onChange={(e) => setFormulario({ ...formulario, descricao: e.target.value })}
                            required
                          />
                        </AvGroup>
                      </FormGroup>
                    </Col>
                    <Col md={3} xs={12}>
                      <FormGroup>
                        <MeioAtendimentoSelect
                          onChange={(e) => setFormulario({ ...formulario, meioAtendimento: parseInt(e.target.value) })}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={3} xs={6}>
                      <FormGroup>
                        <CategoriaSelect onChange={changeCategoria} helpMessage={false} />
                      </FormGroup>
                    </Col>
                    <Col md={3} xs={6}>
                      <FormGroup>
                        <MotivoSelect
                          categoria={formulario.categoria}
                          onChange={changeMotivo}
                          helpMessage={false}
                          value={formulario.motivo?.id || ""}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={3} xs={12}>
                      <FormGroup>
                        <Label className="mr-1">Responsável</Label>
                        <UsuarioSelect
                          departamento={formulario.motivo?.departamento.id}
                          customComponent={AvField}
                          onChange={changeResponsavel}
                          disabled={!formulario.motivo?.departamento}
                          usuarioSelecionado={formulario.responsavel}
                          required={false}
                          data-testid={"responsavel"}
                        />
                      </FormGroup>
                    </Col>
                    <FormGroup>
                      <DropFile onChangeDropFile={onChangeDrop} />
                    </FormGroup>
                  </Row>
                </AvForm>
              </Carregando>
            </CardBody>
          </Card>
        )}
      </>
    );
  }
}

const styleDesktop = {
  width: "150px",
  marginTop: "-40px",
  marginRight: "10px",
};
const styleDesktopBotaoCancelar = {
  left: 10,
};
const styleGlobalBotaoCancelar = {
  backgroundColor: "#BE3F3F",
  borderColor: "#BE3F3F",
  color: "#FFF",
};
const styleGlobal = {
  boxShadow: "0.5px 1px 2px #888888",
};
const styleMobile = {
  marginBottom: "40px",
};

export default NovoAtendimento;
