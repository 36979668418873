import React from "react";
import { MdKeyboardBackspace } from "react-icons/md";
import { Redirect, useHistory } from "react-router-dom";
import { Card, CardBody, Col, FormGroup, Input, Label, Row } from "reactstrap";
import BotaoAcoes from "../../app/BotaoAcoes";
import FormBuscaPessoa from "../../app/FormBuscaPessoa";
import HeaderCardBody from "../../app/HeaderCardBody";
import { useTokenValido } from "../../app/token";
import useWindowDimensions from "../../Utils/getWindowDimension";
import "./NovoAtendimento.css";

export default function BuscaPessoaNovoAtendimento() {
  const [pessoaNaoCadastrada, setPessoaNaoCadastrada] = React.useState(false);
  const history = useHistory();
  const { width } = useWindowDimensions();
  const mobile = width > 768 ? false : true;
  const styledesktop = {
    width: "220px",
    marginTop: "-40px",
    marginRight: "10px",
  };
  const styleglobal = {
    boxShadow: "0.5px 1px 2px #888888",
  };
  const styleMobile = {
    marginBottom: "40px",
  };

  const handleSelectPessoa = (pessoaId) => {
    history.push(`/novo-atendimento/pessoa-fisica/${pessoaId}`);
  };

  const handlePessoaNaoCadastrada = (nome) => {
    history.push({
      pathname: "/novo-atendimento/pessoa-nao-cadastrada",
      state: { nome },
    });
  };

  if (!useTokenValido()) {
    return <Redirect to="/login" />;
  } else {
    return (
      <Card>
        <HeaderCardBody titulo="Novo Atendimento" mostraBotao={false} />
        <CardBody>
          {pessoaNaoCadastrada ? (
            <PessoaNaoCadastrada
              onBack={() => setPessoaNaoCadastrada(!pessoaNaoCadastrada)}
              onBotaoAcao={handlePessoaNaoCadastrada}
            />
          ) : (
            <>
              <Row>
                <Col md={12} className={mobile ? "text-center" : ""}>
                  <BotaoAcoes
                    styledesktop={styledesktop}
                    stylemobile={styleMobile}
                    styleglobal={styleglobal}
                    reversecolor={true}
                    onClick={() => setPessoaNaoCadastrada(!pessoaNaoCadastrada)}
                  >
                    Pessoa não cadastrada
                  </BotaoAcoes>
                </Col>
              </Row>
              <div className="w-100 mb-4 d-flex">
                <MdKeyboardBackspace className="voltar-icone" onClick={() => history.goBack()} />
                <div className="w-100 h-100 text-center">
                  <span className="span-card-novo-atendimento">Pesquise a pessoa que deseja atender</span>
                </div>
              </div>
              <FormBuscaPessoa novoAtendimento={true} onSelect={handleSelectPessoa} />
            </>
          )}
        </CardBody>
      </Card>
    );
  }
}

function PessoaNaoCadastrada({ onBack, onBotaoAcao }) {
  const [nome, setNome] = React.useState("");
  const styledesktop = {
    position: "absolute",
    right: 10,
    bottom: 15,
  };
  return (
    <>
      <div className="w-100 mb-4 d-flex">
        <MdKeyboardBackspace className="voltar-icone" onClick={onBack} />
        <div className="w-100 h-100 text-center">
          <span className="span-card-novo-atendimento">Digite o nome da pessoa que deseja atender</span>
        </div>
      </div>
      <Row>
        <Col md={9} sm={12}>
          <FormGroup>
            <Label for="nome">Nome</Label>
            <Input
              type="text"
              name="nome"
              value={nome}
              data-testid="nomePessoa"
              onChange={(e) => setNome(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col md={3} sm={12} className="text-center">
          <BotaoAcoes styledesktop={styledesktop} onClick={() => onBotaoAcao(nome)}>
            Prosseguir
          </BotaoAcoes>
        </Col>
      </Row>
    </>
  );
}
