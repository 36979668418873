import { notificacaoErro } from "../app/notificacao";

async function fetchAdicionaAnexo(formData, protocolo, token) {
  const url = new URL(process.env.REACT_APP_API_URL + "/atendimento/" + protocolo + "/anexo");
  const init = {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    body: formData,
  };

  const fet = await fetch(url, init);

  switch (fet.status) {
    case 201:
      return { success: true, status: fet.status, data: await fet.json() };
    case 404:
      return {
        success: false,
        status: fet.status,
        error: notificacaoErro(fet, init, "Ocorreu um erro ao adicionar anexo ao atendimento."),
      };
    default:
      return {
        success: false,
        status: fet.status,
        error: notificacaoErro(fet, init, "Ocorreu um erro inesperado ao adicionar anexo ao atendimento."),
      };
  }
}

const adicionaAnexoAtendimento = { fetchAdicionaAnexo };

export default adicionaAnexoAtendimento;
